import { Container, Graphics, utils } from 'pixi.js';

const DEFAULT_COLOR = '#FFFFFF';

class Cursor {
  color: string;
  text?: string;
  textColor?: string;

  private container: Container;
  private graphic: Graphics;

  constructor(
    container: Container,
    {
      color,
      text,
      textColor,
    }: {
      color?: string;
      text?: string;
      textColor?: string;
    },
  ) {
    this.container = container;
    this.color = color || DEFAULT_COLOR;
    this.text = text;
    this.textColor = textColor;

    this.graphic = this.createCursor();
  }

  get position(): [number, number] {
    return [this.x, this.y];
  }

  get x(): number {
    return this.graphic.x;
  }

  get y(): number {
    return this.graphic.y;
  }

  set position([x, y]: [number, number]) {
    this.x = x;
    this.y = y;
  }

  set x(value: number) {
    this.graphic.x = value;
  }

  set y(value: number) {
    this.graphic.y = value;
  }

  destroy(): void {
    // Destrua o gráfico para liberar memória
    if (!this.graphic.destroyed) { this.graphic.destroy(); }

    // Remove o cursor do stage do Pixi.js
    this.container.removeChild(this.graphic);
  }

  draw(): void {
    this.container.addChild(this.graphic);
  }

  private createCursor(): Graphics {
    const cursor = new Graphics();

    // Definir a cor de preenchimento
    cursor.beginFill(utils.string2hex(this.color));

    // M13.64,21.97: Move o cursor para o ponto (13.64, 21.97)
    cursor.moveTo(13.64, 21.97);

    // C13.14,22.21 12.54,22 12.31,21.5: Desenha uma curva cúbica de Bézier
    cursor.bezierCurveTo(13.14, 22.21, 12.54, 22, 12.31, 21.5);

    // L10.13,16.76: Linha reta para o ponto (10.13, 16.76)
    cursor.lineTo(10.13, 16.76);

    // L7.62,18.78: Linha reta para o ponto (7.62, 18.78)
    cursor.lineTo(7.62, 18.78);

    // C7.45,18.92 7.24,19 7,19: Outra curva cúbica de Bézier
    cursor.bezierCurveTo(7.45, 18.92, 7.24, 19, 7, 19);

    // A1,1 0 0,1 6,18: Desenha um arco elíptico
    cursor.arcTo(7, 19, 6, 18, 1);

    // V3: Linha vertical para o ponto de y=3
    cursor.lineTo(6, 3);

    // A1,1 0 0,1 7,2: Outro arco elíptico
    cursor.arcTo(6, 3, 7, 2, 1);

    // C7.24,2 7.47,2.09 7.64,2.23: Mais uma curva cúbica de Bézier
    cursor.bezierCurveTo(7.24, 2, 7.47, 2.09, 7.64, 2.23);

    // L7.65,2.22: Linha reta para o ponto (7.65, 2.22)
    cursor.lineTo(7.65, 2.22);

    // L19.14,11.86: Linha reta para o ponto (19.14, 11.86)
    cursor.lineTo(19.14, 11.86);

    // C19.57,12.22 19.62,12.85 19.27,13.27: Desenha uma curva cúbica de Bézier
    cursor.bezierCurveTo(19.57, 12.22, 19.62, 12.85, 19.27, 13.27);

    // C19.12,13.45 18.91,13.57 18.7,13.61: Outra curva cúbica de Bézier
    cursor.bezierCurveTo(19.12, 13.45, 18.91, 13.57, 18.7, 13.61);

    // L15.54,14.23: Desenha uma linha reta para o ponto (15.54, 14.23)
    cursor.lineTo(15.54, 14.23);

    // L17.74,18.96: Desenha outra linha reta para o ponto (17.74, 18.96)
    cursor.lineTo(17.74, 18.96);

    // C18,19.46 17.76,20.05 17.26,20.28: Uma curva cúbica de Bézier adicional
    cursor.bezierCurveTo(18, 19.46, 17.76, 20.05, 17.26, 20.28);

    // L13.64,21.97: Por fim, desenha uma linha reta para o ponto (13.64, 21.97)
    cursor.lineTo(13.64, 21.97);

    // Encerra o preenchimento
    cursor.endFill();

    // Posição inicial do cursor
    cursor.x = 10;
    cursor.y = 10;

    return cursor;
  }
}

export default Cursor;
