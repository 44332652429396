import { db } from '../config/firebaseConfig';
import { debugMode } from '../config/config';
import { difference } from '../utils/arrayFunctions';
import { PeerData } from './multiplayerRoom/SignalingService';
import Canvas, { GridType } from './Canvas';
import MultiplayerRoom from './multiplayerRoom/MultiplayerRoom';
import RealtimeDatabaseSignalingService from './multiplayerRoom/RealtimeDatabaseSignalingService';
import RemotePlayer from './multiplayerRoom/RemotePlayer';
import throttle from '../utils/throttle';
import Token from './Token';

// #region typings
export enum CharacterAlignment {
  Aggressive = 'aggressive',
  Friendly = 'friendly',
}

export interface Character {
  alignment: CharacterAlignment;
  avatarImagePath?: string;
  canvasPosition?: [number, number];
  combatAttributes?: CharacterCombatAttribute[];
  direction?: number;
  gridPosition?: number[];
  hidden?: boolean;
  id: string;
  name: string;
  playersIds?: string[];
  position: number;
}

export interface CharacterCombatAttribute {
  currentValue: number;
  id: string;
  maxValue: number;
}

export interface CombatAttribute {
  id: string;
  name: string;
}

export interface Player {
  color?: string;
  isCampaignOwner: boolean;
  isGameMaster: boolean;
  nickname: string;
  sessionId: string;
  uid?: string;
}

export interface RpgSystem {
  systemName: string;

  abilitiesSkillsResources?: {
    ablities?: string;
    cooldownAndRestrictons?: string;
    movement?: string;
    range?: string;
    resourceManagement?: string;
  };

  combatAttributes?: Array<{
    depletionImpact?: string;
    id: string;
    name: string;
    recoveryMechanisms?: string;
  }>;

  combatMechanics?: {
    actionSequencing?: string;
    additionalCombatMechanics?: string;
    damageEffectDetermination?: string;
    hitSuccessCalculation?: string;
    roundsTurnsDuration?: string;
  };

  enemiesThreatAssessment?: {
    abilitiesAndTactics?: string;
    categoriesAndTiers?: string;
    threatLevelMechanics?: string;
  };

  positioningTerrainStategy?: {
    distanceMeasurement?: string;
    environmentalFeatures?: string;
    influenceOnCombat?: string;
    positioningAndMovement?: string;
  }
}

export interface BattleAssistantOptions {
  authToken?: string;
  backgroundImagePath?: string | null;
  backgroundImageSize?: [number, number] | null;
  backgroundImageUrl?: string | null;
  backgroundThumbPath?: string | null;
  campaignId: string;
  characters?: Record<string, Character> | null;
  combatAttributes?: CombatAttribute[] | null;
  gridOffset?: [number, number] | null;
  grid?: {
    cols?: number | null;
    rows?: number | null;
    tileSize?: number | null;
    type?: GridType | null;
  } | null;
  localPlayer: Player;
  mapSize?: [number, number] | null;
  name?: string;
  onChangeSelectedCharacter?: (character: Character | null) => unknown;
  onCharacterClick?: (character: Character) => unknown;
  onCharacterDirectionChange?: (direction: number, character: Character) => unknown;
  onCharacterMove?: (position: [number, number], character: Character) => unknown;
  onCharacterMoveEnd?: (position: [number, number], character: Character) => unknown;
  onPlayerConnect?: (player: Player) => unknown;
  onPlayerDataUpdate?: (player: Player) => unknown;
  onPlayerDisconnect?: (player: Player | undefined) => unknown;
  mapId: string;
  rpgSystem?: RpgSystem;
  rpgSystemId?: string;
}

export type BattleAssistantJSON = Required<
  Omit<
    BattleAssistantOptions,
    'authToken' | 'backgroundImageUrl' | 'campaignId' | 'localPlayer' |
    'multiplayer' | 'onCharacterClick' | 'onCharacterMove' |
    'onCharacterMoveEnd' | 'onCharacterDirectionChange' |
    'onChangeSelectedCharacter' | 'onPlayerConnect' |
    'onPlayerDisconnect' | 'onPlayerDataUpdate' | 'rpgSystem' | 'rpgSystemId'
  >
>;
// #endregion

const DEFAULT_MAP_SIZE: [number, number] = [1920, 1080];
export const PLAYER_COLORS = ['#FAD02E', '#F28D35', '#D83367', '#635DFF', '#508BF9', '#2EC4B6',
  '#2CA58D', '#F0E6EF', '#FF9B71', '#E0BBE4', '#957DAD', '#D3E0EA', '#F4D35E', '#EE964B', '#6F9A8D',
  '#F25F5C', '#70C1B3', '#B2DBBF', '#FF6B6B', '#FFE156']
export const TOKEN_COLOR = '#BDBDBD';
export const TOKEN_LINE_AGGRESSIVE_COLOR = '#FF1744';
export const TOKEN_LINE_FRIENDLY_COLOR = '#00E676';
export const TOKEN_TEXT_COLOR = '#FFFFFF';

class BattleAssistant {
  authToken?: string;
  backgroundImagePath?: string | null;
  backgroundThumbPath?: string | null;
  campaignId: string;
  charactersTokens: Record<string, Token> = {};
  combatAttributes: CombatAttribute[] = [];
  localPlayer: Player;
  mapSize: [number, number];
  multiplayerRoom?: MultiplayerRoom;
  name?: string;
  onChangeSelectedCharacter?: (character: Character | null) => unknown;
  onCharacterClick?: (character: Character) => unknown;
  onCharacterDirectionChange?: (direction: number, character: Character) => unknown;
  onCharacterMove?: (position: [number, number], character: Character) => unknown;
  onCharacterMoveEnd?: (position: [number, number], character: Character) => unknown;
  onPlayerConnect?: (player: Player) => unknown;
  onPlayerDataUpdate?: (player: Player) => unknown;
  onPlayerDisconnect?: (player: Player | undefined) => unknown;
  mapId: string;
  rpgSystem?: RpgSystem;
  rpgSystemId?: string;
  selectedCharacter: Character | null = null;

  private _characters: Record<string, Character> = {};
  private canvas: Canvas;
  private draggingObjects: Record<string, [number, number]> = {};
  private remotePlayers: Map<string, Player> = new Map();

  constructor(
    rootElement: HTMLElement,
    options: BattleAssistantOptions,
  ) {
    this.authToken = options.authToken;
    this.backgroundImagePath = options.backgroundImagePath;
    this.campaignId = options.campaignId;
    this.combatAttributes = options.combatAttributes || [];
    this.localPlayer = options.localPlayer;
    this.mapSize = options.mapSize || DEFAULT_MAP_SIZE;
    this.name = options.name;
    this.onChangeSelectedCharacter = options.onChangeSelectedCharacter;
    this.onCharacterClick = options.onCharacterClick;
    this.onCharacterDirectionChange = options.onCharacterDirectionChange;
    this.onCharacterMove = options.onCharacterMove;
    this.onCharacterMoveEnd = options.onCharacterMoveEnd;
    this.onPlayerConnect = options.onPlayerConnect;
    this.onPlayerDataUpdate = options.onPlayerDataUpdate;
    this.onPlayerDisconnect = options.onPlayerDisconnect;
    this.mapId = options.mapId;
    this.rpgSystem = options.rpgSystem;
    this.rpgSystemId = options.rpgSystemId;

    this.canvas = new Canvas(
      rootElement,
      { ...options, mapSize: this.mapSize },
    );
    this.canvas.onChangeSelectedToken = (token) => {
      const character = token && this.characters[token.id];
      this.onChangeSelectedCharacter && this.onChangeSelectedCharacter(character);
    }
    this.canvas.onTokenChangeDirection = this.handleOnTokenChangeDirection;
    this.canvas.onTokenMove = this.handleOnTokenMove;
    this.canvas.onTokenMoveEnd = this.handleOnTokenMoveEnd;
    this.canvas.onClick = this.handleOnCanvasClick;
    this.canvas.draw();

    Object.values(options.characters || {}).forEach((character) => {
      this.addCharacter(character);
    });
  }

  // #region handlers
  private handleOnCanvasClick = (): void => {
    this.deselectCharacter();
  }

  private handleOnTokenChangeDirection = (direction: number, token: Token): void => {
    const character = this.characters[token.id];
    if (!character) {
      console.warn('Character not found for token:', token)
      return;
    }

    this.onCharacterDirectionChange && this.onCharacterDirectionChange(direction, character);
  }

  private handleOnTokenMove = (position: [number, number], token: Token): void => {
    this.draggingObjects[token.id] = position;

    const character = this.characters[token.id];
    if (!character) {
      console.warn('Character not found for token:', token)
      return;
    }

    this.onCharacterMove && this.onCharacterMove(position, character);
  }

  private handleOnTokenMoveEnd = (position: [number, number], token: Token) => {
    delete this.draggingObjects[token.id];

    const character = this.characters[token.id];
    if (!character) {
      console.warn('Character not found for token:', token)
      return;
    }
    this.onCharacterMoveEnd && this.onCharacterMoveEnd(position, character);
  }
  // #endregion

  // #region getters and setters
  get gridOffset(): [number, number] | undefined {
    return this.canvas.gridOffset;
  }

  get backgroundImageUrl(): string | null {
    return this.canvas.backgroundImageUrl;
  }

  get backgroundImageSize(): [number, number] | undefined {
    return this.canvas.backgroundImageSize;
  }

  get characters(): Record<string, Character> {
    Object.values(this._characters).forEach((character) => {
      const token = this.charactersTokens[character.id];
      character.canvasPosition = token.position;
      character.direction = token.direction;
      character.gridPosition = this.canvas.getTilePositionFromPoint(token.position);
    });

    return this._characters;
  }

  get charactersList(): Character[] {
    return Array.from(Object.values(this.characters));
  }

  get gridType(): GridType {
    return this.canvas.gridType;
  }

  get interactive(): boolean {
    return this.canvas.interactive;
  }

  get players(): Player[] {
    if (!this.localPlayer) { return []; }

    return [
      this.localPlayer,
      ...Array.from(this.remotePlayers.values()),
    ]
  }

  get tileSize(): number {
    return this.canvas.tileSize;
  }

  set backgroundImageUrl(value: string | null) {
    this.canvas.backgroundImageUrl = value;
  }

  set interactive(value: boolean) {
    this.canvas.interactive = value;
  }
  // #endregion

  async act(characterId: Character['id']): Promise<void> {
    // if (!this.authToken) {
    //   console.error('Missing authToken');
    //   return;
    // }

    // const currentCharacter = this.characters[characterId];
    // if (!currentCharacter) {
    //   console.error(`Character ${characterId} not found`);
    //   return;
    // }

    // const response = await requestAiAct({
    //   combatAttributes: this.combatAttributes,
    //   combatRules: this.combatRules,
    //   currentCharacter,
    //   gridType: this.gridType,
    //   otherCharacters: this.charactersList.filter(c => c.id !== characterId),
    //   rpgSystem: this.rpgSystem,
    //   token: this.authToken,
    // });

    // const actions = this.extractJsonFromAiResponse(response);

    // actions.slice(0, 1).forEach(({ description, position, maneuver, reasoning }) => {
    //   if (position) { this.moveCharacter(characterId, position); }
    //   console.log('Maneuver: ' + maneuver);
    //   console.log(`### DESCRIPTION ###\n${description}`);
    //   console.log(`### REASONING  ###\n${reasoning}`);
    // });
  }

  addCharacter(character: Character): void {
    if (this.characters[character.id]) { return; }

    const { alignment, hidden, id, name } = character;
    const token = this.canvas.addToken({
      direction: character.direction,
      hidden,
      id: character.id,
      lineColor: alignment === CharacterAlignment.Friendly ? TOKEN_LINE_FRIENDLY_COLOR : TOKEN_LINE_AGGRESSIVE_COLOR,
      onClick: (token) => {
        const character = this.characters[token.id];
        this.selectCharacter(character);
        this.onCharacterClick && this.onCharacterClick(character);
      },
      position: character.canvasPosition,
      text: name.length > 1 ? name[0] + name.slice(-1) : name[0],
    });
    this._characters[id] = character;
    this.charactersTokens[id] = token;
  }

  addPlayerToCharacter(player: Player, character: Character): void {
    character.playersIds = [player.uid].filter(Boolean) as string[];
  }

  changeBackgroundImageUrl(imageUrl: string | null): void {
    this.canvas.backgroundImageUrl = imageUrl;
    return this.canvas.changeBackgroundImage(imageUrl);
  }

  changeGridOffset(offset: [number, number] | undefined): void {
    if (offset === this.gridOffset) { return; }
    // Se o offset for exatamente o mesmo, ignore
    if (
      offset && this.gridOffset &&
      offset[0] === this.gridOffset[0] &&
      offset[1] === this.gridOffset[1]
    ) {
      return;
    }
    this.canvas.changeGridOffset(offset);
  }

  changeBackgroundImageSize(size: [number, number] | null): void {
    this.canvas.backgroundImageSize = size || undefined;
    return this.canvas.changeBackgroundImage(this.canvas.backgroundImageUrl);
  }

  changeCharacterImage(character: Character, imageUrl: string): void {
    const { id } = character;
    const token = this.charactersTokens[id];
    if (!token) { return; }
    this.canvas.changeTokenImage(token, imageUrl);
  }

  changeGridType(gridType: GridType): void {
    if (gridType === this.gridType) { return; }
    this.canvas.changeGridType(gridType);
    this.canvas.draw();
  }

  changeMapSize(size: [number, number]): void {
    if (size[0] === this.mapSize[0] && size[1] === this.mapSize[1]) { return; }
    this.mapSize = size;
    this.canvas.changeMapSize(size);
  }

  changeTileSize(tileSize: number): void {
    if (tileSize === this.tileSize) { return; }
    this.canvas.changeTileSize(tileSize);
    this.canvas.draw();
  }

  deselectCharacter() {
    this.selectedCharacter = null;
    this.canvas.deselectToken();
    this.onChangeSelectedCharacter && this.onChangeSelectedCharacter(null);
  }

  destroy(): void {
    this.canvas.destroy();
    this.multiplayerRoom?.leave();
  }

  disableCharactersRotation(): void {
    Object.values(this.charactersTokens).forEach(token => {
      token.disableRotation();
    });
  }

  enableCharacterRotation(character: Character): void {
    const token = this.charactersTokens[character.id];
    token?.enableRotation();
  }

  async initMultiplayer(): Promise<void> {
    this.multiplayerRoom = this.buildMultiplayerRoom();

    // Conecta a todos os players disponíveis
    await this.multiplayerRoom.connectToRemotePlayers((remotePlayer) => {
      const player = this.addRemotePlayer(remotePlayer);
      this.onPlayerConnect && this.onPlayerConnect(player);
    });

    // On local player data update
    this.multiplayerRoom.onLocalPlayerDataUpdate((data) => {
      this.localPlayer = {
        ...this.localPlayer,
        ...(data as any)
      };
      // Atualiza visalização de GameMaster
      this.updateGameMasterView();
      this.onPlayerDataUpdate && this.onPlayerDataUpdate(this.localPlayer);
    });

    // On remote player data update
    this.multiplayerRoom.onRemotePlayerDataUpdate((remotePlayer) => {
      const player = this.buildPlayerFromRemotePlayer(remotePlayer);
      this.remotePlayers.set(remotePlayer.id, player);
      this.onPlayerDataUpdate && this.onPlayerDataUpdate(player);
    });
  }

  moveCharacter(characterId: Character['id'], gridPosition: number[]) {
    const token = this.charactersTokens[characterId];

    if (!token) {
      console.warn('[BattleAssistant#move] Character not found: ', characterId);
      return;
    }

    this.canvas.moveTokenInGrid(token, gridPosition);
  }

  removeCharacter({ id }: Character): void {
    // Remove character
    delete this._characters[id];

    // Remove token
    const token = this.charactersTokens[id];
    token && this.canvas.removeToken(token);
    delete this.charactersTokens[id];
  }

  set(options: BattleAssistantJSON) {
    this.backgroundImagePath = options.backgroundImagePath;
    this.combatAttributes = options.combatAttributes || [];
    this.mapSize = options.mapSize || DEFAULT_MAP_SIZE;
    this.name = options.name;

    this.canvas.set({ ...options, mapSize: this.mapSize })

    if (options.characters) {
      Object.values(options.characters || {}).forEach((character) => {
        if (this.characters[character.id]) {
          this.updateCharacter(character);
        } else {
          this.addCharacter(character);
        }
      });

      difference(Object.keys(this.characters), Object.keys(options.characters)).forEach(id => {
        this.removeCharacter(this.characters[id]);
      });
    }
  }

  selectCharacter(character: Character) {
    this.selectedCharacter = character;
    const token = this.charactersTokens[character.id];
    this.canvas.selectToken(token);
    this.onChangeSelectedCharacter && this.onChangeSelectedCharacter(character);
  }

  toJSON(): BattleAssistantJSON {
    return {
      backgroundImagePath: this.backgroundImagePath || null,
      backgroundImageSize: this.backgroundImageSize || null,
      backgroundThumbPath: this.backgroundThumbPath || null,
      characters: this.characters,
      combatAttributes: this.combatAttributes,
      grid: this.canvas.grid.toJSON(),
      gridOffset: this.gridOffset || null,
      mapId: this.mapId,
      mapSize: this.mapSize,
      name: this.name || '',
    };
  }

  updateCharacter(character: Character): void {
    const { id, direction, canvasPosition, hidden } = character;
    const token = this.charactersTokens[id];

    if (canvasPosition && canvasPosition !== token.position) {
      this.canvas.changeTokenPosition(token, canvasPosition);
    }

    if (direction != null && direction !== token.direction) {
      this.canvas.changeTokenDirection(token, direction);
    }

    if (hidden) {
      this.canvas.hideToken(token);
    } else {
      this.canvas.showToken(token)
    }

    this._characters[id] = character;
  }

  updateGameMasterView(): void {
    if (this.localPlayer.isGameMaster) {
      this.canvas.showHiddenLayer();
    } else {
      this.canvas.hideHiddenLayer();
    }
  }

  updatePlayer(data: Player): void {
    if (this.localPlayer.sessionId === data.sessionId) {
      this.localPlayer = data;
    } else {
      this.remotePlayers.set(data.sessionId, data);
    }

    this.multiplayerRoom?.updatePlayerData(data.sessionId, data as any)
  }

  // #region private
  private addRemotePlayer(remotePlayer: RemotePlayer): Player {
    const player = this.buildPlayerFromRemotePlayer(remotePlayer);
    if (this.remotePlayers.has(remotePlayer.id)) { return player; }

    this.remotePlayers.set(remotePlayer.id, player);

    const cursor = this.canvas.addCursor(remotePlayer.id, {
      color: remotePlayer.data.color as string || this.getColorForPlayer(),
    });

    remotePlayer.onMouseMove(pos => { cursor.position = pos });

    remotePlayer.onDragStart((objectId) => {
      const token = this.charactersTokens[objectId];
      if (token) { token.interactive = false; }
    });

    remotePlayer.onDragEnd((objectId) => {
      const token = this.charactersTokens[objectId];
      if (token) { token.interactive = true; }
    });

    remotePlayer.onDragMove((objectId, [x, y]) => {
      const token = this.charactersTokens[objectId];
      token?.move(x, y);
    });

    return player;
  }

  private buildPlayerFromRemotePlayer(remotePlayer: RemotePlayer): Player {
    const { color, isCampaignOwner, isGameMaster, nickname, uid } = remotePlayer.data as any;

    return {
      color,
      isCampaignOwner: Boolean(isCampaignOwner),
      isGameMaster: Boolean(isGameMaster),
      nickname,
      sessionId: remotePlayer.id,
      uid,
    };
  }

  private buildMultiplayerRoom(): MultiplayerRoom {
    if (!this.localPlayer) {
      throw new Error('Missing localPlayer');
    }

    const signalingService = new RealtimeDatabaseSignalingService({
      db,
      sessionId: `campaigns/${this.campaignId}/signaling/${this.mapId}`,
    });
    if (!this.localPlayer.color) { this.localPlayer.color = this.getColorForPlayer(); }

    const { color, isCampaignOwner, isGameMaster, nickname, uid } = this.localPlayer;
    const localPlayerData: PeerData = { color, isCampaignOwner, isGameMaster, nickname };
    if (uid) { localPlayerData.uid = uid }

    const multiplayerRoom = new MultiplayerRoom({
      authToken: this.authToken,
      debug: debugMode,
      localPlayerId: this.localPlayer.sessionId,
      localPlayerData,
      mapId: this.mapId,
      signalingService,
    });

    // Evento de conexão de players remotos
    multiplayerRoom.onPlayerConnect(remotePlayer => {
      const player = this.addRemotePlayer(remotePlayer)
      this.onPlayerConnect && this.onPlayerConnect(player);
    });

    // Evento de desconexão de players remotos
    multiplayerRoom.onPlayerDisconnect((remotePlayer) => {
      const player = this.removeRemotePlayer(remotePlayer);
      this.onPlayerDisconnect && this.onPlayerDisconnect(player);
    });

    // Adiciona evento de mouse move e envia para todos os players
    this.canvas.onMouseMove = throttle((position: [number, number]) => {
      this.multiplayerRoom?.remotePlayers.forEach(player => {
        player.sendMouseMove(position, this.draggingObjects);
      });
    }, 25);

    return multiplayerRoom;
  }

  private extractJsonFromAiResponse(text: string): Array<{
    description: string;
    maneuver: string;
    position: number[];
    reasoning: string;
    targetId: Character['id'];
  }> {
    const regex = /```json([\s\S]*?)```/;
    const match = text.match(regex);

    if (match && match[1]) {
      const jsonString = match[1].trim();
      try {
        return JSON.parse(jsonString);
      } catch (error) {
        throw new Error('Invalid AI response');
      }
    } else {
      throw new Error('Invalid AI response');
    }
  }

  private getColorForPlayer(): string {
    let availableColors = difference(PLAYER_COLORS, this.players.map(p => p.color));
    if (availableColors.length === 0) { availableColors = PLAYER_COLORS; }

    const index = Math.floor(Math.random() * availableColors.length);
    return availableColors[index] as string;
  }

  private removeRemotePlayer(remotePlayer: RemotePlayer): Player | undefined {
    const player = this.remotePlayers.get(remotePlayer.id);
    this.canvas.removeCursor(remotePlayer.id);
    this.remotePlayers.delete(remotePlayer.id);

    return player;
  }
  // #endregion
}

export default BattleAssistant;
