import { collection, doc, DocumentReference, Firestore, getDoc, setDoc } from 'firebase/firestore';

import { RpgSystem } from '../battleAssistant/BattleAssistant';
import { uuidBase62 } from '../utils/uuidBase62';

// #region typings
interface RpgSystemRepositoryDependencies {
  db: Firestore;
}
// #endregion

class RpgSystemRepository implements RpgSystemRepositoryDependencies {
  db: Firestore;

  constructor({
    db,
  }: RpgSystemRepositoryDependencies) {
    this.db = db;
  }

  async find(id: string): Promise<RpgSystem | undefined> {
    const snapshot = await getDoc(this.buildRef(id));
    return snapshot.data() as any;
  }

  generateId(uid: string): string {
    return `${uid}-${uuidBase62()}`;
  }

  async updateRpgSystem(id: string, rpgSystem: RpgSystem): Promise<void> {
    await setDoc(
      this.buildRef(id),
      rpgSystem,
    )
  }

  private buildRef(rpgSystemId: string): DocumentReference {
    return doc(
      collection(this.db, 'rpgSystems'),
      rpgSystemId,
    )
  }
}

export default RpgSystemRepository;
