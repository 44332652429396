async function stringToHash(string: string, algorithm = 'SHA-1') {
  // Transforma a string em um ArrayBuffer
  const buffer = new TextEncoder().encode(string);

  // Gera o hash
  const hashBuffer = await crypto.subtle.digest(algorithm, buffer);

  // Transforma o hash em uma string de caracteres hexadecimais
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

  return hashHex;
}

export default stringToHash;
