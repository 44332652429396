import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Button,
  DialogActions,
} from "@mui/material";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

export interface RemoveConfirmationDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => unknown;
  resourceName: string;
}

export function RemoveConfirmationDialog({
  onConfirm,
  resourceName,
  isOpen,
  onClose,
}: RemoveConfirmationDialogProps) {
  const [confirmationText, setConfirmationText] = useState("");

  //#region handlers
  const handleClose = () => {
    setConfirmationText('');
    onClose();
  }

  const handleConfirmationTextChange = (value: string) => {
    setConfirmationText(value);
  };

  const handleSubmit = () => {
    if (confirmationText.toLowerCase() === "remove") {
      onConfirm();
      handleClose();
    }
  };
  //#endregion

  return (
    <Dialog fullWidth maxWidth="xs" open={isOpen}>
      <DialogTitle>Remove {resourceName}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent sx={{ pt: 0 }}>
        <DialogContentText>
          Please confirm removal by typing "<strong>REMOVE</strong>" in the field below.
        </DialogContentText>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <TextField
            autoFocus
            margin="dense"
            id="confirmation-text"
            fullWidth
            variant="standard"
            value={confirmationText}
            onChange={(event) =>
              handleConfirmationTextChange(event.target.value)
            }
          />
        </form>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>

        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={confirmationText.toLowerCase() !== 'remove'}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RemoveConfirmationDialog;
