import {
  Box,
  Divider,
  Fab,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';

import { AuthContext } from '../../AuthProvider';
import theme from '../../theme';
import sendMessageOnChat from '../../clients/sendMessageOnChat';
import loadChatMessages from '../../clients/loadChatMessages';

//#region typings
interface Message {
  type: 'ai' | 'human',
  content: string;
}

interface FloatingChatProps {
  sessionId: string;
}
//#endregion

function FloatingChat({ sessionId }: FloatingChatProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState<Message[]>([]);
  const { token: authToken } = useContext(AuthContext);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const handleSendMessage = () => {
    if (!authToken) { return; }

    if (message.trim()) {
      const userMessage: Message = { type: 'human', content: message };
      setMessages([...messages, userMessage]);
      setMessage('');

      setIsLoading(true);
      sendMessageOnChat({ prompt: message, sessionId, token: authToken }).then((answer) => {
        setMessages([
          ...messages,
          userMessage,
          { type: 'ai', content: answer }
        ]);
        setIsLoading(false);
      });
    }
  };

  //#region Lifecycle
  useEffect(() => {
    if (!authToken) { return; }

    loadChatMessages({ sessionId, token: authToken }).then((messages) => {
      setMessages(messages);
    });
  }, [authToken, sessionId])

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);
  //#endregion

  return (
    <Box
      sx={{
        bottom: 20,
        height: '80%',
        position: 'absolute',
        right: 20,
    }}>
      {isOpen && (
        <Paper
          elevation={2}
          sx={{
            backdropFilter: 'blur(10px)',
            backgroundColor: theme.palette.background.paperBlur,
            bottom: 68,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            position: 'absolute',
            right: 0,
            width: 400,
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              overflowY: 'auto',
              padding: 2,
              position: 'relative',
            }}
          >
            {messages.length === 0 ? (
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="h6" color="textPrimary">
                  Need help with RPG rules?
                </Typography>

                <Typography variant="body2" color="textSecondary" align='center'>
                  Start a chat and ask any questions about the game rules you're currently playing.
                </Typography>
              </Box>
            ) : (
              <>
                {messages.map((msg, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      justifyContent: msg.type === 'human' ? 'flex-end' : 'flex-start',
                      width: '100%',
                      marginY: 1,
                    }}
                  >
                    <Paper
                      sx={{
                        background: msg.type === 'human' ? theme.palette.info.dark : theme.palette.background.paper,
                        marginBottom: 1,
                        maxWidth: '80%',
                        padding: 1,
                      }}
                    >
                      {msg.content}
                    </Paper>
                  </Box>
                ))}
              </>
            )}

            {/* {isLoading && <CircularProgress size={24} />} */}
            <div ref={messagesEndRef}>
              {isLoading && (
                <p
                  style={{
                    bottom: 0,
                    margin: '8px 0',
                    position: 'absolute',
                  }}
                >
                  Zen is typing...
                </p>
              )}
            </div>
          </Box>

          <Divider />

          <TextField
            multiline
            autoFocus
            maxRows={5}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSendMessage();
              }
            }}
            sx={{
              background: 'transparent',
              width: '100%',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
                '&:hover fieldset': {
                  border: 'none',
                },
                '&.Mui-focused fieldset': {
                  border: 'none',
                },
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="send message"
                    color='primary'
                    onClick={handleSendMessage}
                  >
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Paper>
      )}

      <Fab sx={{ position: 'absolute', bottom: '0', right: 0 }} onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? <CloseIcon /> : <ChatIcon />}
      </Fab>
    </Box>
  )
}

export default FloatingChat;
