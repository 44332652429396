import React, { InputHTMLAttributes } from 'react';
import { useTheme } from '@mui/material';

interface TransparentInputProps extends InputHTMLAttributes<HTMLInputElement> {}

const TransparentInput: React.FC<TransparentInputProps> = ({ style, ...props }) => {
  const theme = useTheme();

  const defaultStyle = {
    background: 'transparent',
    border: 'none',
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    fontSize: '1.25rem',
    outline: 'none',
  };

  return <input style={{ ...defaultStyle, ...style }} {...props} />;
};

export default TransparentInput;
