import { Graphics } from 'pixi.js';
import { GridType } from './Canvas';
import BaseGrid from './BaseGrid';

class SquareGrid extends BaseGrid {
  private drawId = '';

  get cols(): number {
    return Math.floor(this.width / this.tileSize);
  }

  get rows(): number {
    return Math.floor(this.height / this.tileSize);
  }

  get type(): GridType {
    return GridType.Square;
  }

  changeOffset(offset: [number, number]): void {
    this.offsetX = offset[0];
    this.offsetY = offset[1];
    this.draw(true);
  }

  destroy(): void {
    this.container.removeChild(this.gridContainer);
    this.gridContainer.destroy({ children: true, texture: true, baseTexture: true });
  }

  draw(force = false): void {
    if (!force && this.drawId === this.buildDrawId()) { return; }

    this.gridContainer.removeChildren();
    const graphic = new Graphics();
    const effectiveLineWidth = 1 / Math.max(this.gridContainer.scale.x, this.gridContainer.scale.y);

    graphic.beginFill(0xFFFFFF, 0.001);
    graphic.lineStyle(effectiveLineWidth, this.lineColor, this.lineOpacity);

    // Desenhe linhas verticais
    for (let j = 0; j <= this.cols; j++) {
      const x = this.offsetX + (j * this.tileSize);
      graphic.moveTo(x, 0);
      graphic.lineTo(x, this.height);
    }

    // Desenhe linhas horizontais
    for (let i = 0; i <= this.rows; i++) {
      const y = this.offsetY + (i * this.tileSize);
      graphic.moveTo(0, y);
      graphic.lineTo(this.width, y);
    }

    graphic.endFill();
    this.gridContainer.addChild(graphic);

    this.drawId = this.buildDrawId();
  }

  getCenterFromTilePosition(position: [number, number]): [number, number] {
    const [row, column] = position;
    const x = (column * this.tileSize) + (this.tileSize / 2);
    const y = (row * this.tileSize) + (this.tileSize / 2);
    return [x, y];
  }

  getPositionFromPoint(x: number, y: number): [number, number] {
    const row = Math.floor(y / this.tileSize);
    const col = Math.floor(x / this.tileSize);
    return [row, col];
  }

  private buildDrawId(): string {
    return `${this.width}x${this.height}-${this.tileSize}`;
  }

  private drawSquare(graphic: Graphics, x: number, y: number): void {
    const effectiveLineWidth = 1 / Math.max(this.gridContainer.scale.x, this.gridContainer.scale.y);

    graphic.clear();
    graphic.beginFill(0xFFFFFF, 0.001);
    graphic.lineStyle(effectiveLineWidth, this.lineColor, this.lineOpacity);
    graphic.drawRect(x, y, this.tileSize, this.tileSize);
    graphic.endFill();
  }
}

export default SquareGrid;
