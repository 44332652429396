import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { Player } from '../../../battleAssistant/BattleAssistant';
import { useBattleAssistant } from '../BattleAssistantContext';
import { useState } from 'react';

function PlayersMenu() {
  const [menuState, setMenuState] = useState<{ anchorEl: null | HTMLElement, playerId: null | number }>({ anchorEl: null, playerId: null });

  const {
    localPlayer,
    players,
    setUpdatedPlayer,
  } = useBattleAssistant();

  //#region handlers
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>, playerId: number) => {
    setMenuState({ anchorEl: event.currentTarget, playerId });
  };

  const handleMenuClose = () => {
    setMenuState({ anchorEl: null, playerId: null });
  };

  const promoteToGm = (player: Player) => {
    setUpdatedPlayer({ ...player, isGameMaster: true })
    handleMenuClose();
  }

  const revokeGmAccess = (player: Player) => {
    setUpdatedPlayer({ ...player, isGameMaster: false })
    handleMenuClose();
  }
  //#endregion

  return (
    <Box
      sx={{
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        width: '280px',
      }}
    >
      <Box
        sx={{ display: 'flex', alignItems: 'center', pb: 1, pl: 2, pr: 2, pt: 1 }}
      >
        <Typography variant="h6" gutterBottom sx={{ flexGrow: 1, margin: 0 }}>
          Players
        </Typography>
      </Box>

      <Divider />

      <Box p={0} flexGrow={1} overflow="auto">
        <List>
          {Object.values(players).map((player, index) => (
            <ListItem
              key={index}
              secondaryAction={localPlayer?.isCampaignOwner ? (
                <IconButton edge="end" aria-label="options" onClick={(event) => handleClickMenu(event, index)}>
                  <MoreVertIcon />
                </IconButton>
              ) : undefined}
            >
              {/* Cor do cursor do jogador  */}
              <div
                style={{
                  backgroundColor: player.color || 'transparent',
                  borderRadius: '10px',
                  height: '20px',
                  marginRight: '10px',
                  minWidth: '20px',
                  width: '20px',
                }}>
              </div>

              {/* Nickname do jogador */}
              <ListItemText
                primary={player.nickname}
                secondary={player.isGameMaster ? 'Game Master' : undefined}
              />

              {localPlayer?.isCampaignOwner && (
                <Menu
                  anchorEl={menuState.anchorEl}
                  open={Boolean(menuState.anchorEl) && menuState.playerId === index}
                  onClose={handleMenuClose}
                  MenuListProps={{
                    'aria-labelledby': 'player-menu',
                  }}
                >
                  {player.isGameMaster ? (
                    <MenuItem onClick={() => revokeGmAccess(player)}>
                      Revoke GM access
                    </MenuItem>
                  ): (
                    <MenuItem onClick={() => promoteToGm(player)}>
                      Promote to GM
                    </MenuItem>
                  )}
                </Menu>
              )}
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
}

export default PlayersMenu;
