import { useNavigate } from 'react-router-dom';
import { ReactNode, useEffect, useState } from 'react';

import { auth } from '../../config/firebaseConfig';

const UserRoute = ({ children }: { children: ReactNode }): React.ReactElement | null => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setIsLoading(false);
      } else {
        navigate("/login");
        setIsLoading(false);
      }
    });

    return unsubscribe;
  }, [navigate]);

  if (isLoading) {
    return null;
  }

  return <>{children}</>;
};

export default UserRoute;
