export const debugMode = process.env.REACT_APP_DEBUG_MODE === 'true';
export const isDevelopment = process.env.NODE_ENV === 'development';
export const isProduction = process.env.NODE_ENV === 'production';
export const multiplayer = process.env.REACT_APP_MULTIPLAYER ? process.env.REACT_APP_MULTIPLAYER === 'true' : true;

export const buildFunctionUrl = (functionName: string): string => {
  const functionsUrl = process.env.REACT_APP_FUNCTIONS_URL || '';
  if (isProduction) { functionName = functionName.replace(/_/g, '-'); }

  return functionsUrl.replace('{}', functionName);
}
