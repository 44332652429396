import { Database, get, onChildChanged, onValue, remove, ref, set, update } from 'firebase/database';

import { RealtimeDatabaseRepositoryAdapter, Unsubscribe } from './RealtimeDatabaseRepositoryAdapter.interface';

interface FirebaseRealtimeDatabaseAdapterDependencies {
  db: Database;
}

class FirebaseRealtimeDatabaseAdapter implements RealtimeDatabaseRepositoryAdapter, FirebaseRealtimeDatabaseAdapterDependencies {
  db: Database;

  constructor({
    db,
  }: FirebaseRealtimeDatabaseAdapterDependencies) {
    this.db = db;
  }

  async get(path: string[]): Promise<Record<string, any> | undefined> {
    const snapshot = await get(ref(this.db, path.join('/')));
    return snapshot.val();
  }

  onChildChange(path: string[], handler: (childId: string, value: any) => unknown): Unsubscribe {
    return onChildChanged(ref(this.db, path.join('/')), (child) => {
      handler(child.key as string, child.val());
    });
  }

  onValueChange(path: string[], handler: (value: any) => unknown): Unsubscribe {
    return onValue(ref(this.db, path.join('/')), (child) => {
      handler(child.val());
    });
  }

  async remove(path: string[]): Promise<void> {
    remove(ref(this.db, path.join('/')));
  }

  async set(path: string[], value: Record<string, any>): Promise<void> {
    await set(ref(this.db, path.join('/')), value);
  }

  async update(path: string[], data: any): Promise<void> {
    await update(ref(this.db, path.join('/')), data);
  }
}

export default FirebaseRealtimeDatabaseAdapter;
