/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useEffect, CSSProperties, useState, useContext } from 'react';

import { AuthContext } from '../../AuthProvider';
import { multiplayer } from '../../config/config';
import { Unsubscribe } from '../../repositories/databaseAdapters/RealtimeDatabaseRepositoryAdapter.interface';
import { useBattleAssistant } from './BattleAssistantContext';
import { useNavigate } from 'react-router-dom';
import { uuidBase62 } from '../../utils/uuidBase62';
import BattleAssistantCls, { Character } from '../../battleAssistant/BattleAssistant';
import CharacterFormDialog from './dialogs/CharacterFormDialog';
import MainMenu from './menus/MainMenu';
import MapRepository, { BattleAssistantData } from '../../repositories/MapRepository';
import Toolbar from './Toolbar';
import WithFeatureAccess, { Feature } from './WithFeatureAccess';
import FloatingChat from '../chat/FloatingChat';

const containerStyle: CSSProperties = {
  width: '100%',
  height: '100%',
  position: 'relative',
};

// ID do timeout para fazer debounce de persistência dos dados
let persistTimeoutId: NodeJS.Timeout | undefined;

// Função de desinscrição
let MapRepositoryOnChangeUnsubscribe: Unsubscribe | undefined;
let MapRepositoryOnCharacterChangeUnsubscribe: Unsubscribe | undefined;

function BattleAssistant() {
  const navigate = useNavigate();
  const [newCharacterImageUrl, setNewCharacterImageUrl] = useState<[string, string] | null>(null);
  const [wasLoaded, setWasLoaded] = useState<boolean>(false);
  const { email: userNickname, token: authToken, uid: userUid } = useContext(AuthContext);

  const {
    backgroundImage,
    gridOffsetX,
    gridOffsetY,
    backgroundImageSize,
    backgroundImageUrl,
    campaignId,
    campaignRepository,
    characterAiAction,
    charactersImagesUrls,
    combatAttributes,
    createdCharacter,
    deletedCharacter,
    gridType,
    isCharacterFormDialogOpen,
    localPlayer,
    mapChanged,
    mapFileRepository,
    mapHeight,
    mapId,
    mapRepository,
    mapWidth,
    newCharacterImage,
    rotateCharacter,
    rpgSystem,
    rpgSystemRepository,
    selectedCharacter,
    setBackgroundImage,
    setGridOffsetX,
    setGridOffsetY,
    setBackgroundImageSize,
    setBackgroundImageUrl,
    setCharacterAiAction,
    setCharacters,
    setCharactersImagesUrls,
    setCombatAttributes,
    setCreatedCharacter,
    setDeletedCharacter,
    setGridType,
    setLocalPlayer,
    setMapChanged,
    setMapHeight,
    setMapWidth,
    setNewCharacterImage,
    setPlayers,
    setRotateCharacter,
    setRpgSystem,
    setSelectedCharacter,
    setTileSize,
    setUpdatedCharacter,
    tileSize,
    updatedCharacter,
    updatedPlayer,
  } = useBattleAssistant();
  const battleAssistantContainer = useRef<HTMLDivElement>(null);
  const [battleAssistant, setBattleAssistant] = useState<BattleAssistantCls | null>(null);

  // #region private
  const navigateToMapSelection = () => {
    navigate(`/campaigns/${campaignId}`)
  };

  const persistData = async (): Promise<void> => {
    if (!wasLoaded || !MapRepository || !battleAssistant) { return; }

    if (persistTimeoutId) { clearTimeout(persistTimeoutId) };
    persistTimeoutId = setTimeout(() => {
      mapRepository.save(mapId, battleAssistant);
      persistTimeoutId = undefined;
    }, 2000);
  }

  const updateData = (battleAssistant: BattleAssistantCls) => {
    const {
      gridOffset,
      backgroundImageSize,
      charactersList,
      combatAttributes,
      gridType,
      mapSize,
      tileSize,
    } = battleAssistant;

    setGridOffsetX(gridOffset ? String(gridOffset[0]) : '');
    setGridOffsetY(gridOffset ? String(gridOffset[1]) : '');
    setBackgroundImageSize(backgroundImageSize || null);
    setCharacters(charactersList);
    setCombatAttributes(combatAttributes);
    setGridType(gridType);
    setMapHeight(String(mapSize[1]));
    setMapWidth(String(mapSize[0]));
    setTileSize(tileSize);

    // Especifica que o BattleAssistant foi carregado
    setWasLoaded(true);
  }
  // #endregion

  // #region Lifecycle functions
  useEffect(() => {
    // Event listeners
    document.addEventListener('keyup', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress)
      MapRepositoryOnChangeUnsubscribe?.call(undefined);
      MapRepositoryOnCharacterChangeUnsubscribe?.call(undefined);
      battleAssistant?.destroy();
      setBattleAssistant(null);
    };
  }, []);

  useEffect(() => {
    if (!campaignId || !mapId) { return; }

    // Destrói BattleAssistant caso já haja algum inicializado
    battleAssistant?.destroy();
    MapRepositoryOnChangeUnsubscribe?.call(undefined);
    MapRepositoryOnCharacterChangeUnsubscribe?.call(undefined);

    // Começa inicialização
    setWasLoaded(false);

    // Função de carregamento de dados assícrono
    const initBattleAssistant = async () => {
      if (!battleAssistantContainer.current) { return; }

      const data = await mapRepository.load(mapId);

      if (!data) {
        navigateToMapSelection();
        return;
      }

      const battleAssistant = new BattleAssistantCls(
        battleAssistantContainer.current,
        {
          ...data,
          campaignId,
          localPlayer: {
            isCampaignOwner: false,
            isGameMaster: false,
            nickname: userNickname || 'Unknown',
            sessionId: uuidBase62(),
          },
          mapId,
          onChangeSelectedCharacter: (character) => {
            setSelectedCharacter(character);
          },
          onPlayerConnect: () => {
            setPlayers(battleAssistant.players);
          },
          onPlayerDataUpdate: () => {
            setLocalPlayer(battleAssistant.localPlayer);
            setPlayers(battleAssistant.players);
          },
          onPlayerDisconnect: () => {
            setPlayers(battleAssistant.players);
          },
        }
      );
      setBattleAssistant(battleAssistant);

      // Carrega backgound image à partir do path
      if (data.backgroundImagePath) {
        const url = await mapFileRepository.getUrlByPath(data.backgroundImagePath);
        battleAssistant.changeBackgroundImageUrl(url);
        setBackgroundImageUrl(url);
      }

      // Carrega imagens dos personagens
      const charactersImagesUrls: Record<string, string> = {};
      Promise.all(
        Object.values(data.characters || {}).map((character: Character) => {
          if (!character.avatarImagePath) { return null; }

          return mapFileRepository.getUrlByPath(character.avatarImagePath).then((url) => {
            if (!url) { return null; }
            battleAssistant.changeCharacterImage(character, url);
            charactersImagesUrls[character.id] = url;
            return url;
          });
        }),
      ).then(() => {
        setCharactersImagesUrls(charactersImagesUrls);
      });

      // Carrega RpgSystem
      if (battleAssistant.rpgSystemId) {
        battleAssistant.rpgSystem = await rpgSystemRepository.find(battleAssistant.rpgSystemId);
        setRpgSystem(battleAssistant.rpgSystem || null);
      }

      updateData(battleAssistant);
    };

    initBattleAssistant();
  }, [campaignId, mapId]);

  useEffect(() => {
    if (!authToken || !battleAssistant) { return; }

    campaignRepository.find(campaignId).then(({ ownerId, gameMastersIds, rpgSystemId }) => {
      battleAssistant.authToken = authToken;
      battleAssistant.localPlayer = {
        ...battleAssistant.localPlayer,
        isCampaignOwner: userUid === ownerId,
        isGameMaster: (gameMastersIds || []).includes(userUid || ''),
        nickname: userNickname || 'Unknown',
        uid: userUid || '',
      };
      battleAssistant.rpgSystemId = rpgSystemId;
      battleAssistant.updateGameMasterView();

      setLocalPlayer(battleAssistant.localPlayer)
      setPlayers([battleAssistant.localPlayer]);
      if (multiplayer) { battleAssistant.initMultiplayer(); }

      if (battleAssistant.rpgSystemId) {
        return rpgSystemRepository.find(battleAssistant.rpgSystemId);
      }
    }).then(rpgSystem => {
      battleAssistant.rpgSystem = rpgSystem;
      if (rpgSystem) { setRpgSystem(rpgSystem); }
    });
  }, [authToken, battleAssistant, userUid]);

  useEffect(() => {
    if (!battleAssistant || !characterAiAction) { return; }

    battleAssistant.act(characterAiAction.id);
    setCharacterAiAction(null);
  }, [characterAiAction, setCharacterAiAction]);

  useEffect(() => {
    if (!battleAssistant) { return; }

    if (combatAttributes != null) {
      battleAssistant.combatAttributes = combatAttributes;
    }

    if (createdCharacter) {
      battleAssistant.addCharacter(createdCharacter);
      localPlayer && battleAssistant.addPlayerToCharacter(localPlayer, createdCharacter);
      setCreatedCharacter(null);
    }

    if (deletedCharacter) {
      battleAssistant.removeCharacter(deletedCharacter);
      setDeletedCharacter(null);

      if (deletedCharacter.id === selectedCharacter?.id) {
        setSelectedCharacter(null);
      }
    }

    if (updatedCharacter) {
      battleAssistant.updateCharacter(updatedCharacter);
      setUpdatedCharacter(null);

      if (updatedCharacter.id === selectedCharacter?.id) {
        setSelectedCharacter(updatedCharacter);
      }
    }

    if (createdCharacter || deletedCharacter || updatedCharacter) {
      setCharacters(battleAssistant.charactersList);
    }

    if (newCharacterImage) {
      const character = createdCharacter || updatedCharacter;
      const characterId = character?.id;

      if (characterId) {
        const imageUrl = URL.createObjectURL(newCharacterImage);
        battleAssistant.changeCharacterImage(character, imageUrl);
        setCharactersImagesUrls({ ...charactersImagesUrls, [characterId]: imageUrl });

        // Armazena imagem do avatar do personagem
        mapFileRepository.uploadCharacterImage(characterId, newCharacterImage)
          .then((imagePath) => {
            if (!characterId) { return; }
            const character = battleAssistant.characters[characterId];
            if (!character) { return; }
            setUpdatedCharacter({ ...character, avatarImagePath: imagePath })
          });
      }

      setNewCharacterImage(null);
    }

    // Atualizações em Player
    if (updatedPlayer?.uid) {
      const playerBeforeUpdate = battleAssistant.players.find(p => p.sessionId === updatedPlayer.sessionId);

      // Verifica se o valor de isGameMaster mudou
      if (playerBeforeUpdate && updatedPlayer.isGameMaster !== playerBeforeUpdate.isGameMaster) {
        if (updatedPlayer.isGameMaster) {
          campaignRepository.addGameMaster(campaignId, updatedPlayer.uid);
        } else {
          campaignRepository.removeGameMaster(campaignId, updatedPlayer.uid);
        }
      }

      battleAssistant.updatePlayer(updatedPlayer);
      setPlayers(battleAssistant.players);
      setLocalPlayer(battleAssistant.localPlayer);
      setUpdatedCharacter(null);
    }
  }, [
    combatAttributes,
    createdCharacter,
    deletedCharacter,
    setCreatedCharacter,
    setDeletedCharacter,
    setUpdatedCharacter,
    updatedCharacter,
    updatedPlayer,
  ]);

  useEffect(() => {
    if (!battleAssistant || !backgroundImage) { return; }

    mapFileRepository.uploadBackgroundImage(backgroundImage).then((imagePath) => {
      battleAssistant.backgroundImagePath = imagePath;
      return mapFileRepository.getUrlByPath(imagePath)
    }).then(() => {
      return persistData();
    });

    setBackgroundImage(null);
  }, [backgroundImage]);

  useEffect(() => {
    if (!battleAssistant || backgroundImageUrl === undefined) { return; }
    battleAssistant.changeBackgroundImageUrl(backgroundImageUrl);
  }, [backgroundImageUrl]);

  useEffect(() => {
    if (!battleAssistant) { return; }

    battleAssistant.changeGridOffset([
      Number(gridOffsetX) || 0,
      Number(gridOffsetY) || 0,
    ]);
  }, [gridOffsetX, gridOffsetY]);

  useEffect(() => {
    if (!battleAssistant) { return; }

    battleAssistant.changeBackgroundImageSize(backgroundImageSize || null);
  }, [backgroundImageSize]);

  useEffect(() => {
    if (!battleAssistant || gridType == null) { return; }
    battleAssistant.changeGridType(gridType);
  }, [gridType]);

  // Lifecycle para persistência de dados
  useEffect(() => {
    if (!createdCharacter && !deletedCharacter && !mapChanged && !updatedCharacter) { return; }

    if (mapChanged) {
      setMapChanged(false);
    }

    persistData();
  }, [createdCharacter, deletedCharacter, mapChanged, updatedCharacter]);

  // Atualiza estado de charactersImagesUrls
  // Esse useEffect é necessário pois o callback onde a imagem é atualizada não tem acesso aos estados do React
  useEffect(() => {
    if (!newCharacterImageUrl) { return; }

    const [id, url] = newCharacterImageUrl;
    setCharactersImagesUrls({
      ...charactersImagesUrls,
      [id]: url,
    });
    setNewCharacterImageUrl(null);
  }, [newCharacterImageUrl]);

  useEffect(() => {
    if (!battleAssistant || !mapWidth || !mapHeight) { return; }
    battleAssistant.changeMapSize([Number(mapWidth), Number(mapHeight)]);
  }, [mapWidth, mapHeight]);

  useEffect(() => {
    if (!battleAssistant) { return; }

    if (rotateCharacter && selectedCharacter) {
      battleAssistant.enableCharacterRotation(rotateCharacter);
      return;
    }

    if (rotateCharacter) { setRotateCharacter(null); }
    battleAssistant.disableCharactersRotation();
  }, [selectedCharacter, rotateCharacter]);

  useEffect(() => {
    if (!battleAssistant || !rpgSystem || !userUid) { return; }

    const rpgSystemId = battleAssistant.rpgSystemId || rpgSystemRepository.generateId(userUid);
    rpgSystemRepository.updateRpgSystem(rpgSystemId, rpgSystem);
    campaignRepository.update(campaignId, { rpgSystemId });
    battleAssistant.rpgSystemId = rpgSystemId;
  }, [rpgSystem, userUid]);

  useEffect(() => {
    if (battleAssistant && selectedCharacter) {
      battleAssistant.selectCharacter(selectedCharacter)
    }
  }, [selectedCharacter])

  useEffect(() => {
    if (!battleAssistant) { return ;}
    battleAssistant.changeTileSize(tileSize);
  }, [tileSize]);

  useEffect(() => {
    if (!wasLoaded || !battleAssistant) { return; }

    battleAssistant.onCharacterMoveEnd = handleCharacterMoveEnd;
    battleAssistant.onCharacterDirectionChange = handleCharacterDirectionChange;
    MapRepositoryOnChangeUnsubscribe = mapRepository.onChange(mapId, handleRemoteDataUpdate);
    MapRepositoryOnCharacterChangeUnsubscribe = mapRepository.onCharacterChange(mapId, handleRemoteCharacterDataChange);
  }, [wasLoaded]);
  // #endregion

  // #region handlers
  const handleCharacterMoveEnd = () => {
    persistData();
  };

  const handleCharacterDirectionChange = () => {
    persistData();
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape' || event.key === 'Esc') { // 'Esc' is for older browsers
      setSelectedCharacter(null);
    }
  };

  const handleRemoteCharacterDataChange = (updatedCharacter: Character) => {
    if (!battleAssistant) { return; }
    const currentCharacter = battleAssistant.characters[updatedCharacter.id];
    if (!currentCharacter) { return; }

    const { avatarImagePath, id } = updatedCharacter;
    if (avatarImagePath && avatarImagePath !== currentCharacter.avatarImagePath) {
      mapFileRepository.getUrlByPath(avatarImagePath).then((url) => {
        if (!url) { return; }
        battleAssistant.changeCharacterImage(updatedCharacter, url);
        setNewCharacterImageUrl([id, url]);
      });
    }
  }

  const handleRemoteDataUpdate = (data: BattleAssistantData | undefined): void => {
    if (!data || !battleAssistant) { return; }
    if (data.updatedBy === battleAssistant.localPlayer?.sessionId) { return; }

    mapRepository.buildHash(battleAssistant).then((hash) => {
      // Ignora a atualização dos dados caso nenhum dado tenha sido alterado
      if (hash === data.hash) { return; }

      // Atualiza background image
      if (data.backgroundImagePath !== battleAssistant.backgroundImagePath) {
        battleAssistant.changeBackgroundImageUrl(null);

        if (data.backgroundImagePath) {
          mapFileRepository.getUrlByPath(data.backgroundImagePath).then((url) => {
            battleAssistant.changeBackgroundImageUrl(url);
          });
        }
      }

      battleAssistant.set(data);
      updateData(battleAssistant);
    });
  }
  // #endregion

  return (
    <div ref={battleAssistantContainer} style={containerStyle}>
      <MainMenu />

      <WithFeatureAccess feature={Feature.Toolbar} action='view'>
        <Toolbar />
      </WithFeatureAccess>

      <CharacterFormDialog
        character={selectedCharacter}
        characterImageUrl={selectedCharacter ? charactersImagesUrls[selectedCharacter.id] : undefined}
        isOpen={isCharacterFormDialogOpen}
      />

      <FloatingChat sessionId={campaignId} />
    </div>
  );
}

export default BattleAssistant;
