// #region typings
export type Unsubscribe = () => void;

export type MessageHandler = (message: string) => void;

export interface DataChannelDependencies {
  debug: boolean;
  name: string;
  peerId: string;
}
// #endregion

class DataChannel implements DataChannelDependencies {
  debug: boolean;
  name: string;
  peerId: string;

  private _isOpen = false;
  private _rtcDataChannel?: RTCDataChannel;
  private messageHandlers: Array<MessageHandler> = [];

  constructor({
    debug,
    name,
    peerId,
  }: DataChannelDependencies) {
    this.debug = debug;
    this.name = name;
    this.peerId = peerId;
  }

  close(): void {
    this.rtcDataChannel?.close();
  }

  get isOpen(): boolean {
    return this._isOpen;
  }

  get rtcDataChannel(): RTCDataChannel | undefined {
    return this._rtcDataChannel;
  }

  set rtcDataChannel(rtcDataChannel: RTCDataChannel | undefined) {
    this._rtcDataChannel = rtcDataChannel;
    this.addMessageHandlers();
  }

  onMessage(handler: MessageHandler): Unsubscribe {
    this.messageHandlers.push(handler);
    this.addMessageHandlers();

    return () => {
      const index = this.messageHandlers.indexOf(handler);
      this.messageHandlers.splice(index, 1);
    }
  }

  send(message: string): void {
    this.isOpen && this.rtcDataChannel?.send(message);
  }

  private addMessageHandlers() {
    if (!this.rtcDataChannel) { return; }

    this.rtcDataChannel.onmessage = (event) => {
      this.messageHandlers.forEach((h) => h(event.data));
    }

    this.rtcDataChannel.onopen = () => {
      this.log(`Channel opened`);
      this._isOpen = true;
    }

    this.rtcDataChannel.onclosing = () => {
      this.log(`Channel closing`);
      this._isOpen = false;
    }

    this.rtcDataChannel.onclose = () => {
      this.log(`Channel closed`);
      this._isOpen = false;
    }

    this.rtcDataChannel.onerror = (event: any) => {
      this.log(`Channel error: `, JSON.stringify(event));
    }
  }

  private log(message: string, ...optionalParams: any[]): void {
    if (!this.debug) { return; }
    console.log(`[${this.peerId}][${this.name}] ${message}`, ...optionalParams);
  }
}

export default DataChannel;
