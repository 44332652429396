import { deleteObject, getDownloadURL, FirebaseStorage, ref, uploadBytes } from 'firebase/storage';

import { StorageRepositoryAdapter } from './StorageRepositoryAdapter.interface';

interface FirebaseStorageRepositoryAdapterDependencies {
  storage: FirebaseStorage;
}

class FirebaseStorageRepositoryAdapter implements StorageRepositoryAdapter {
  storage: FirebaseStorage;

  constructor({
    storage,
  }: FirebaseStorageRepositoryAdapterDependencies) {
    this.storage = storage;
  }

  async delete(path: string): Promise<void> {
    try {
      await deleteObject(
        ref(this.storage, path),
      );
    } catch (err) {
      switch ((err as any).code) {
        case "storage/object-not-found": return;
        default: throw err;
      }
    }
  }

  async getDownloadUrl(path: string): Promise<string | null> {
    try {
      return await getDownloadURL(
        ref(this.storage, path),
      );
    } catch (err) {
      switch ((err as any).code) {
        case "storage/object-not-found": return null;
        default: throw err;
      }
    }
  }

  async upload(path: string, file: File, cacheControl?: string): Promise<string> {
    await uploadBytes(
      ref(this.storage, path),
      file,
      {
        cacheControl,
      }
    )

    return this.getDownloadUrl(path) as any;
  }
}

export default FirebaseStorageRepositoryAdapter;
