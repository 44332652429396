import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useCallback, useEffect, useState } from 'react';

import { useBattleAssistant } from '../BattleAssistantContext';
import { uuidBase62 } from '../../../utils/uuidBase62';
import { RpgSystem } from '../../../battleAssistant/BattleAssistant';

//#region typings
interface FormStep {
  id: string;
  label: string;
}

interface SystemAbilitiesSkillsResources {
  ablities?: string;
  cooldownAndRestrictons?: string;
  movement?: string;
  range?: string;
  resourceManagement?: string;
}

interface SystemCombatAttributes {
  depletionImpact?: string;
  id?: string;
  name: string;
  recoveryMechanisms?: string;
}

interface SystemCombatMechanics {
  roundsTurnsDuration?: string;
  actionSequencing?: string;
  additionalCombatMechanics?: string;
  damageEffectDetermination?: string;
  hitSuccessCalculation?: string;
}

interface SystemEnemiesThreatAssessment {
  categoriesAndTiers?: string;
  threatLevelMechanics?: string;
  abilitiesAndTactics?: string;
}

interface SystemPositioningTerrainStategy {
  positioningAndMovement?: string;
  distanceMeasurement?: string;
  influenceOnCombat?: string;
  environmentalFeatures?: string;
}

interface RPGSystemFormDialogProps {
  isOpen: boolean;
  onClose?: () => void;
}
//#endregion

const steps: FormStep[] = [
  { id: 'systemName', label: 'System Name' },
  { id: 'combatAttributes', label: 'Combat Attributes' },
  { id: 'abilitiesSkillsResources', label: 'Abilities, Skills & Resources' },
  { id: 'combatMechanics', label: 'Combat Mechanics' },
  { id: 'positioningTerrainStategy', label: 'Positioning, Terrain & Strategy' },
  { id: 'enemiesThreatAssessment', label: 'Enemies & Threat Assessment' },
];

function RPGSystemFormDialog({ isOpen, onClose }: RPGSystemFormDialogProps) {
  const [combatAttributeErrors, setCombatAttributeErrors] = useState<string[]>([]);
  const [currentStep, setCurrentStep] = useState(steps[0]);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [systemAbilitiesSkillsResources, setSystemAbilitiesSkillsResources] = useState<SystemAbilitiesSkillsResources>({});
  const [systemCombatAttributes, setSystemCombatAttributes] = useState<SystemCombatAttributes[]>([]);
  const [systemCombatMechanics, setSystemCombatMechanics] = useState<SystemCombatMechanics>({});
  const [systemEnemiesThreatAssessment, setSystemEnemiesThreatAssessment] = useState<SystemEnemiesThreatAssessment>({});
  const [systemName, setSystemName] = useState('');
  const [systemNameError, setSystemNameError] = useState<boolean>(false);
  const [systemPositioningTerrainStategy, setSystemPositioningTerrainStategy] = useState<SystemPositioningTerrainStategy>({});

  const {
    combatAttributes,
    rpgSystem,
    setCombatAttributes,
    setRpgSystem,
  } = useBattleAssistant();

  const isFormValid = (stepId?: string) => {
    let valid = true;
    const updatedErrors = [...combatAttributeErrors];

    if (!stepId || stepId === 'systemName') {
      if (!systemName.trim()) {
        setSystemNameError(true);
        valid = false;
      }
    }

    if (!stepId || stepId === 'combatAttributes') {
      systemCombatAttributes.forEach((attribute, index) => {
        if (!attribute.name.trim()) {
          updatedErrors[index] = "error";
          valid = false;
        } else {
          updatedErrors[index] = "";
        }
      });

      setCombatAttributeErrors(updatedErrors);
    }

    return valid;
  };

  //#region handlers
  const handleAbilitiesSkillsResourcesChange = (attrName: keyof SystemAbilitiesSkillsResources, value: string) => {
    setSystemAbilitiesSkillsResources({
      ...systemAbilitiesSkillsResources,
      [attrName]: value,
    });
  };

  const handleAddCombatAttribute = useCallback(() => {
    setSystemCombatAttributes([...systemCombatAttributes, {
      depletionImpact: '',
      name: '',
      recoveryMechanisms: ''
    }]);
  }, [setSystemCombatAttributes, systemCombatAttributes]);

  const handleBack = () => {
    setCurrentStepIndex(step => step - 1);
  };

  const handleClose = () => {
    onClose && onClose();
  };

  const handleCombatAttributeChange = (index: number, key: keyof SystemCombatAttributes, value: string) => {
    const updatedCombatAttributes = [...systemCombatAttributes];
    updatedCombatAttributes[index][key] = value;
    setSystemCombatAttributes(updatedCombatAttributes);
  };

  const handleCombatAttributeNameValidation = (index: number) => {
    const updatedErrors = [...combatAttributeErrors];

    if (!systemCombatAttributes[index].name.trim()) {
      updatedErrors[index] = "error";
    } else {
      updatedErrors[index] = "";
    }

    setCombatAttributeErrors(updatedErrors);
};

  const handleCombatMechanicsChange = (attrName: keyof SystemCombatMechanics, value: string) => {
    setSystemCombatMechanics({
      ...systemCombatMechanics,
      [attrName]: value,
    });
  }

  const handleEnemiesThreatAssessment = (attrName: keyof SystemEnemiesThreatAssessment, value: string) => {
    setSystemEnemiesThreatAssessment({
      ...systemEnemiesThreatAssessment,
      [attrName]: value,
    });
  };

  const handleNext = () => {
    isFormValid(currentStep.id);

    if (currentStepIndex < steps.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
    } else {
      handleSubmit();
    }
  };

  const handlePositioningTerrainStategy = (attrName: keyof SystemPositioningTerrainStategy, value: string) => {
    setSystemPositioningTerrainStategy({
      ...systemPositioningTerrainStategy,
      [attrName]: value,
    });
  }

  const handleRemoveCombatAttribute = (index: number) => {
    const updatedCombatAttributes = [...systemCombatAttributes];
    updatedCombatAttributes.splice(index, 1);

    if (updatedCombatAttributes.length === 0) {
      updatedCombatAttributes.push({
        depletionImpact: '',
        name: '',
        recoveryMechanisms: ''
      });
    }

    setSystemCombatAttributes(updatedCombatAttributes);
  };

  const handleSubmit = () => {
    if (!isFormValid()) { return; }

    const systemCombatAttrs: RpgSystem['combatAttributes'] = systemCombatAttributes.map(combatAttribute => ({
      ...combatAttribute,
      id: combatAttribute.id || uuidBase62(),
    }))

    setCombatAttributes(
      systemCombatAttrs.map(({ name, id }) => ({ id, name })),
    );

    setRpgSystem({
      systemName,
      abilitiesSkillsResources: systemAbilitiesSkillsResources,
      combatAttributes: systemCombatAttrs,
      combatMechanics: systemCombatMechanics,
      enemiesThreatAssessment: systemEnemiesThreatAssessment,
      positioningTerrainStategy: systemPositioningTerrainStategy,
    });

    handleClose();
  }

  const handleSystemNameBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!e.target.value.trim()) {
      setSystemNameError(true);
    }
  };

  const handleSystemNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSystemName(e.target.value);

    if (e.target.value.trim()) {
      setSystemNameError(false);
    }
  };
  //#endregion

  //#region lifecycle functions
  useEffect(() => {
    // Caso já exista algum atributo no combatAttributes devemos colocálo no form
    const systemCombatAttributes = [...(rpgSystem?.combatAttributes || [])]
    combatAttributes?.forEach(attr => {
      if (systemCombatAttributes.map(a => a.id).includes(attr.id)) { return; }
      systemCombatAttributes.push({
        id: attr.id,
        name: attr.name,
      })
    });

    setSystemAbilitiesSkillsResources(rpgSystem?.abilitiesSkillsResources || {});
    setSystemCombatAttributes(systemCombatAttributes);
    setSystemCombatMechanics(rpgSystem?.combatMechanics || {});
    setSystemEnemiesThreatAssessment(rpgSystem?.enemiesThreatAssessment || {});
    setSystemName(rpgSystem?.systemName || '');
    setSystemPositioningTerrainStategy(rpgSystem?.positioningTerrainStategy || {});
  }, [combatAttributes, rpgSystem]);

  useEffect(() => {
    if (systemCombatAttributes.length === 0) {
      handleAddCombatAttribute();
    }
  }, [handleAddCombatAttribute, systemCombatAttributes]);

  useEffect(() => {
    setCurrentStep(steps[currentStepIndex]);
  }, [currentStepIndex])
  //#endregion

  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      onClose={handleClose}
      open={isOpen}
      PaperProps={{
        style: {
          height: '80%',
        },
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">
            RPG System
          </Typography>
          <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box display="flex" flexDirection="row" sx={{ height: '100%' }}>
          {/* Vertical Stepper */}
          <Box sx={{ height: '100%', minWidth: 'fit-content', width: '200px' }}>
            <Stepper activeStep={currentStepIndex} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepLabel
                    error={
                      Boolean(step.id === 'systemName' && systemNameError) ||
                      Boolean(step.id === 'combatAttributes' && combatAttributeErrors.some(e => e))
                    }
                  >
                    {step.label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          {/* Step Content */}
          {currentStep.id === 'systemName' && (
            <Box flexGrow={1} p={2} display="flex" flexDirection="column" justifyContent="center">
              <>
                <Typography variant="h4">
                  What is the name of your RPG system?
                </Typography>

                <FormControl fullWidth margin="normal">
                  <TextField
                    fullWidth
                    label="System Name"
                    value={systemName}
                    onChange={handleSystemNameChange}
                    onBlur={handleSystemNameBlur}
                    error={systemNameError}
                    helperText={systemNameError ? "System name is required" : ""}
                  />
                </FormControl>
              </>
            </Box>
          )}

          {/* Combat Attributes */}
          {currentStep.id === 'combatAttributes' && (
            <Box sx={{ ml: 2, width: '100%' }}>
              <Typography variant="h4">
                {currentStep.label}
              </Typography>

              {systemCombatAttributes.map((combatAttribute, index) => (
                <Box key={index} mt={3}>
                  <Typography variant="h5">{`Combat attribute ${index + 1}`}</Typography>

                  <TextField
                    fullWidth
                    margin="normal"
                    label="Name"
                    placeholder="E.g., Health, Mana, Stamina"
                    helperText={combatAttributeErrors[index] === "error"
                      ? "Name is required"
                      : "Name of the combat attribute. This is how it will be referred to in battles."
                    }
                    value={combatAttribute.name}
                    error={combatAttributeErrors[index] === "error"}
                    onChange={(e) => handleCombatAttributeChange(index, 'name', e.target.value)}
                    onBlur={() => handleCombatAttributeNameValidation(index)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    fullWidth
                    margin="normal"
                    label="Depletion Impact"
                    placeholder="Describe how a character is affected as this attribute depletes."
                    helperText="E.g., 'At 50% health, the character becomes weakened. At 0%, the character falls unconscious.'"
                    multiline
                    minRows={3}
                    value={combatAttribute.depletionImpact}
                    onChange={(e) => handleCombatAttributeChange(index, 'depletionImpact', e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    fullWidth
                    margin="normal"
                    label="Recovery Mechanisms"
                    placeholder="How can this attribute be restored or recovered?"
                    helperText="List or describe methods to regain or restore this attribute during combat, E.g., potions, spells, rest."
                    multiline
                    minRows={3}
                    value={combatAttribute.recoveryMechanisms}
                    onChange={(e) => handleCombatAttributeChange(index, 'recoveryMechanisms', e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <Button
                    color="secondary"
                    onClick={() => handleRemoveCombatAttribute(index)}
                  >
                    Remove
                  </Button>
                </Box>
              ))}

              <Box mt={3}>
                <Button variant="outlined" onClick={handleAddCombatAttribute}>
                  Add combat attribute
                </Button>
              </Box>
            </Box>
          )}

          {/* Abilities, Skills and Resources */}
          {currentStep.id === 'abilitiesSkillsResources' && (
            <Box sx={{ ml: 2, width: '100%' }}>
              <Typography variant="h4">
                {currentStep.label}
              </Typography>

              <Box mt={3}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Movement Mechanisms"
                  placeholder="Describe how character movement is determined in your system. Include base movement rates, modifiers, or special conditions, if any."
                  helperText="E.g., 'Characters have a base movement of 6 tiles. Certain terrains may modify this rate.'"
                  multiline
                  minRows={3}
                  value={systemAbilitiesSkillsResources.movement}
                  onChange={(e) => handleAbilitiesSkillsResourcesChange('movement', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <TextField
                  fullWidth
                  margin="normal"
                  label="Abilities Categorization"
                  placeholder="Describe the various categories of abilities available. Include any key distinctions or unique mechanics."
                  helperText="E.g., 'Abilities are divided into Melee, Ranged, and Magic. Magic abilities require mana; Melee abilities might have a cooldown.'"
                  multiline
                  minRows={3}
                  value={systemAbilitiesSkillsResources.ablities}
                  onChange={(e) => handleAbilitiesSkillsResourcesChange('ablities', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <TextField
                  fullWidth
                  margin="normal"
                  label="Resources Governing Abilities"
                  placeholder="List and describe the resources that govern ability use. Include how they're consumed and regenerated."
                  helperText="E.g., 'Mana: Used for casting spells. Regenerates at a rate of 10% per turn.'"
                  multiline
                  minRows={3}
                  value={systemAbilitiesSkillsResources.resourceManagement}
                  onChange={(e) => handleAbilitiesSkillsResourcesChange('resourceManagement', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <TextField
                  fullWidth
                  margin="normal"
                  label="Range & AoE Mechanisms"
                  placeholder="Describe how ability range and areas of effect are determined."
                  helperText="E.g., 'Melee abilities have a range of adjacent tiles. Ranged abilities might have a range of 5 tiles. AoE abilities affect a radius.'"
                  multiline
                  minRows={3}
                  value={systemAbilitiesSkillsResources.range}
                  onChange={(e) => handleAbilitiesSkillsResourcesChange('range', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <TextField
                  fullWidth
                  margin="normal"
                  label="Ability Cooldowns & Restrictions"
                  placeholder="Detail the general mechanics around ability cooldowns or usage restrictions."
                  helperText="E.g., 'After using a strong ability, there's a cooldown of 3 turns. Some abilities can only be used once per battle.'"
                  multiline
                  minRows={3}
                  value={systemAbilitiesSkillsResources.cooldownAndRestrictons}
                  onChange={(e) => handleAbilitiesSkillsResourcesChange('cooldownAndRestrictons', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Box>
          )}

          {/* Combat Mechanicics */}
          {currentStep.id === 'combatMechanics' && (
            <Box sx={{ ml: 2, width: '100%' }}>
              <Typography variant="h4">
                {currentStep.label}
              </Typography>

              <Box mt={3}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Rounds & Turns Structure"
                  placeholder="Describe the structure of rounds and turns, as well as any time constraints within them."
                  helperText="E.g., 'Combat is broken into rounds. Each character gets one turn per round based on initiative. Each turn lasts 60 seconds in real-time or is constrained by action points.'"
                  multiline
                  minRows={3}
                  value={systemCombatMechanics.roundsTurnsDuration}
                  onChange={(e) => handleCombatMechanicsChange('roundsTurnsDuration', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <TextField
                  fullWidth
                  margin="normal"
                  label="Action Sequencing"
                  placeholder="Describe how actions are ordered in combat."
                  helperText="E.g., 'Actions are determined by initiative rolls at the beginning of each round.'"
                  multiline
                  minRows={3}
                  value={systemCombatMechanics.actionSequencing}
                  onChange={(e) => handleCombatMechanicsChange('actionSequencing', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <TextField
                  fullWidth
                  margin="normal"
                  label="Hit/Success Calculation"
                  placeholder="Explain the method used to calculate the likelihood of an attack or action succeeding."
                  helperText="E.g., 'Hit chances are calculated by comparing the attacker's accuracy stat to the defender's evasion.'"
                  multiline
                  minRows={3}
                  value={systemCombatMechanics.hitSuccessCalculation}
                  onChange={(e) => handleCombatMechanicsChange('hitSuccessCalculation', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <TextField
                  fullWidth
                  margin="normal"
                  label="Damage/Effect Determination"
                  placeholder="Detail how damage or the effectiveness of abilities is determined."
                  helperText="E.g., 'Damage is the result of the attacker's strength minus the defender's armor, modified by any active effects or resistances.'"
                  multiline
                  minRows={3}
                  value={systemCombatMechanics.damageEffectDetermination}
                  onChange={(e) => handleCombatMechanicsChange('damageEffectDetermination', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <TextField
                  fullWidth
                  margin="normal"
                  label="Additional Combat Mechanics (Optional)"
                  placeholder="Include any other pivotal combat rules or mechanics specific to your system."
                  helperText="You can detail any house rules, unique systems, or other specifics not covered above."
                  multiline
                  minRows={3}
                  value={systemCombatMechanics.additionalCombatMechanics}
                  onChange={(e) => handleCombatMechanicsChange('additionalCombatMechanics', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Box>
          )}

          {/* Positioning, Terrain & Strategy */}
          {currentStep.id === 'positioningTerrainStategy' && (
            <Box sx={{ ml: 2, width: '100%' }}>
              <Typography variant="h4">
                {currentStep.label}
              </Typography>

              <Box mt={3}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Character Positioning and Movement Mechanics"
                  placeholder="Describe how characters are positioned on the battle grid and their movement capabilities. Include any unique mechanics or rules."
                  helperText="E.g., 'Characters can move up to their speed value in squares per turn. Diagonal movement costs 1.5x a square.'"
                  multiline
                  minRows={3}
                  value={systemPositioningTerrainStategy.positioningAndMovement}
                  onChange={(e) => handlePositioningTerrainStategy('positioningAndMovement', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <TextField
                  fullWidth
                  margin="normal"
                  label="Distance Measurement on the Grid"
                  placeholder="Explain how distance is gauged between characters, objects, and terrains."
                  helperText="E.g., 'Distance is measured in squares. Each square represents 5 feet.'"
                  multiline
                  minRows={3}
                  value={systemPositioningTerrainStategy.distanceMeasurement}
                  onChange={(e) => handlePositioningTerrainStategy('distanceMeasurement', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <TextField
                  fullWidth
                  margin="normal"
                  label="Influence of Positioning on Combat Outcomes"
                  placeholder="Detail how character orientation and positioning affect combat scenarios."
                  helperText="E.g., 'Flanking enemies provide a +2 bonus to attack rolls. Characters behind cover receive a +4 to defense.'"
                  multiline
                  minRows={3}
                  value={systemPositioningTerrainStategy.influenceOnCombat}
                  onChange={(e) => handlePositioningTerrainStategy('influenceOnCombat', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <TextField
                  fullWidth
                  margin="normal"
                  label="Terrain and Environmental Mechanics"
                  placeholder="Describe the types of terrains, their effects, and other key environmental features."
                  helperText="E.g., 'Swamps reduce movement by half. Elevated terrains provide advantage on ranged attacks.'"
                  multiline
                  minRows={3}
                  value={systemPositioningTerrainStategy.environmentalFeatures}
                  onChange={(e) => handlePositioningTerrainStategy('environmentalFeatures', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Box>
          )}

          {/* Enemies & Threat Assessment */}
          {currentStep.id === 'enemiesThreatAssessment' && (
            <Box sx={{ ml: 2, width: '100%' }}>
              <Typography variant="h4">
                {currentStep.label}
              </Typography>

              <Box mt={3}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Enemy Categories & Tiers (optional)"
                  placeholder="E.g., Minions, Elites, Bosses, Underlings..."
                  helperText="List or describe how enemies are categorized or tiered in your system."
                  multiline
                  minRows={3}
                  value={systemEnemiesThreatAssessment.categoriesAndTiers}
                  onChange={(e) => handleEnemiesThreatAssessment('categoriesAndTiers', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <TextField
                  fullWidth
                  margin="normal"
                  label="Threat Level Mechanisms (optional)"
                  placeholder="E.g., Based on size, abilities, proximity to the hero..."
                  helperText="Explain the factors or mechanics that determine an enemy's threat level or priority in combat."
                  multiline
                  minRows={3}
                  value={systemEnemiesThreatAssessment.threatLevelMechanics}
                  onChange={(e) => handleEnemiesThreatAssessment('threatLevelMechanics', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <TextField
                  fullWidth
                  margin="normal"
                  label="Enemy Abilities & Tactics (optional)"
                  placeholder="E.g., Stealth attacks, summoning reinforcements, using environment..."
                  helperText="Detail any special abilities, tactics, or techniques that enemies may employ in battle."
                  multiline
                  minRows={3}
                  value={systemEnemiesThreatAssessment.abilitiesAndTactics}
                  onChange={(e) => handleEnemiesThreatAssessment('abilitiesAndTactics', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>

      <DialogActions>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box flexGrow={1} />

          {currentStepIndex >= 1 && (
            <Button
              color="primary"
              onClick={handleBack}
            >
              Back
            </Button>
          )}

          <Button
            color="primary"
            onClick={handleNext}
          >
            {currentStepIndex < steps.length - 1 ? "Next" : "Finish"}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default RPGSystemFormDialog;
