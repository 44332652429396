function throttle<T extends (...args: any[]) => unknown>(
  func: T,
  wait: number
): (...args: Parameters<T>) => void {
  let timeout: ReturnType<typeof setTimeout> | null = null;
  let lastRun = 0;

  return function(...args: Parameters<T>) {
    const runCallback = () => {
      lastRun = Date.now();
      func(...args);
      timeout = null;
    };

    if (timeout === null) {
      const elapsed = Date.now() - lastRun;

      if (elapsed >= wait) {
        runCallback();
      } else {
        timeout = setTimeout(runCallback, wait - elapsed);
      }
    }
  };
}

export default throttle;
