import {
  Application,
  Container,
  Graphics,
  InteractionData,
  InteractionEvent,
  Point,
  Rectangle,
  Sprite,
  Texture,
} from 'pixi.js';

import { TOKEN_COLOR, TOKEN_TEXT_COLOR } from './BattleAssistant';
import BaseGrid, { GridOptions } from './BaseGrid';
import Cursor from './Cursor';
import EmptyGrid from './EmptyGrid';
import HexagonGrid from './HexagonGrid';
import SquareGrid from './SquareGrid';
import Token from './Token';

// #region typings
export enum GridType {
  Empty = 'empty',
  Hexagon = 'hexagon',
  Square = 'square',
}

export interface CanvasOptions {
  backgroundColor?: number | null;
  gridOffset?: [number, number] | null;
  backgroundImageSize?: [number, number] | null;
  backgroundImageUrl?: string | null;
  mapSize: [number, number];
  grid?: {
    lineColor?: number | null;
    tileSize?: number | null;
    type?: GridType | null;
  } | null;
}
// #endregion

const BACKGROUND_COLOR = 0x1E1E1E;
const DEFAULT_GRID_LINE_COLOR = 0x000000;
const DEFAULT_GRID_TILE_SIZE = 40;
const DEFAULT_GRID_TYPE = GridType.Empty;
const MAP_COLOR = 0x303030;
const MAP_OFFSET: [number, number] = [0, 0];
const MAX_ZOOM = 5;
const TOKEN_SIZE_RATIO = 0.35 // proporção comparada com o tile size
const TOKEN_SNAP_DISTANCE_PERCENTAGE = 25; // percent
const ZOOM_FACTOR = 0.1;

class Canvas {
  gridOffset?: [number, number];
  backgroundImageSize?: [number, number];
  backgroundImageUrl: string | null = null;
  currentZoom = 1;
  grid: BaseGrid;
  mapSize: [number, number];
  onChangeSelectedToken?: (token: Token | null) => void;
  onClick?: (position: [number, number] | null) => void;
  onMouseMove?: (position: [number, number]) => void;
  onTokenChangeDirection?: (direction: number, token: Token) => void;
  onTokenMove?: (position: [number, number], token: Token) => void;
  onTokenMoveEnd?: (position: [number, number], token: Token) => void;

  private _gridType: GridType;
  private _interactive = true;
  private app: Application;
  private cursorLayer: Container;
  private cursors: Map<string, Cursor> = new Map();
  private dragData: InteractionData | null = null;
  private dragStartPoint = { x: 0, y: 0 };
  private dragStartStagePos = { x: 0, y: 0 };
  private hiddenLayer: Container;
  private interactiveArea: Graphics;
  private isDraggingStage: boolean = false;
  private mapContainer: Graphics;
  private rootElement: HTMLElement;
  private selectedToken: Token | null = null;
  private tokenLayer: Container;
  private tokens: Token[] = [];
  private wasDragged = false;

  constructor(
    rootElement: HTMLElement,
    options: CanvasOptions,
  ) {
    this.backgroundImageSize = options.backgroundImageSize || undefined;
    this.backgroundImageUrl = options.backgroundImageUrl || null;
    this.mapSize = options.mapSize;
    this.rootElement = rootElement;
    const { lineColor, tileSize, type: gridType } = options.grid || {};
    this._gridType = gridType || DEFAULT_GRID_TYPE;

    this.app = new Application({
      antialias: true,
      backgroundColor: options.backgroundColor || BACKGROUND_COLOR,
      height: rootElement.clientHeight,
      width: rootElement.clientWidth,
    });

    rootElement.appendChild(this.app.view);

    this.mapContainer = this.buildMapContainer();
    this.interactiveArea = this.buildInteractiveArea();
    this.tokenLayer = this.buildLayer();
    this.cursorLayer = this.buildLayer();
    this.hiddenLayer = this.buildHiddenLayer()
    this.grid = this.buildGrid(this.mapContainer, { gridType: this.gridType, lineColor, tileSize });
    this.addEventListeners();
  }

  // #region handlers
  private finalizeDrag = (event: InteractionEvent): void => {
    const position = this.dragData?.global;
    if (!this.wasDragged && event?.target && this.onClick) {
      this.onClick(position ? [position.x, position.y] : null);
    }

    this.dragData = null;
    this.isDraggingStage = false;
    this.wasDragged = false;
  };

  private handleDirectionChange = (direction: number, token: Token): void => {
    this.onTokenChangeDirection?.call(this, direction, token);
  }

  private handleKeyShortcut = (event: KeyboardEvent): void => {
    // ESC
    if (event.key === "Escape") {
      this.deselectToken();
    }
  };

  private handleMouseScroll = (event: WheelEvent): void => {
    // Normalmente, deltaY > 0 é scroll para baixo
    if (event.deltaY > 0) {
      this.zoomOut(event);
    } else {
      this.zoomIn(event);
    }
  };

  private handleOnDragMove = (): void => {
    if (!this.interactive || !this.isDraggingStage || !this.dragData) return;

    const newPosition = this.dragData.global;

    const dx = newPosition.x - this.dragStartPoint.x;
    const dy = newPosition.y - this.dragStartPoint.y;

    const newStageX = this.dragStartStagePos.x + dx;
    const newStageY = this.dragStartStagePos.y + dy;

    // Limites de arrastamento
    const minX = this.app.renderer.width;
    const maxX = -(this.mapContainer.width * this.currentZoom);
    const minY = this.app.renderer.height;
    const maxY = -(this.mapContainer.height * this.currentZoom);

    // Restringindo o app.stage dentro dos limites
    this.app.stage.x = Math.max(maxX, Math.min(minX, newStageX));
    this.app.stage.y = Math.max(maxY, Math.min(minY, newStageY));

    const distanceMoved = Math.sqrt((newPosition.x - this.dragStartPoint.x) ** 2 + (newPosition.y - this.dragStartPoint.y) ** 2);
    if (distanceMoved > 5) {
      this.wasDragged = true;
    }

    this.resizeInteractiveArea();
  };

  private handleResize = () => {
    this.app.renderer.resize(this.rootElement.clientWidth, this.rootElement.clientHeight);
  };

  private handleTokenMove = (x: number, y: number, token: Token): [number, number] | void => {
    // Ajusta as coordenadas para sincronizar com o offset do mapa
    const adjustedX = x - MAP_OFFSET[0];
    const adjustedY = y - MAP_OFFSET[1];

    // token snap implementation
    const tilePosition = this.grid.getPositionFromPoint(adjustedX, adjustedY);
    const [centerX, centerY] = this.grid.getCenterFromTilePosition(tilePosition);
    const distance = Math.sqrt((centerX - adjustedX) ** 2 + (centerY - adjustedY) ** 2);
    const snapDistance = this.grid.tileSize * (TOKEN_SNAP_DISTANCE_PERCENTAGE / 100);

    if (distance <= snapDistance) {
      const adjustedCenterX = centerX + MAP_OFFSET[0];
      const adjustedCenterY = centerY + MAP_OFFSET[1];
      this.onTokenMove?.call(this, [adjustedCenterX, adjustedCenterY], token)
      return [centerX, centerY];
    }

    this.onTokenMove?.call(this, [x, y], token)
  }

  private handleTokenMoveEnd = (x: number, y: number, token: Token): [number, number] | void => {
    this.onTokenMoveEnd && this.onTokenMoveEnd([x, y], token);
  }

  private prepareForDrag = (event: InteractionEvent): void => {
    this.isDraggingStage = true;
    this.wasDragged = false;

    this.dragData = event.data;
    this.dragStartPoint.x = this.dragData.global.x;
    this.dragStartPoint.y = this.dragData.global.y;

    this.dragStartStagePos.x = this.app.stage.x;
    this.dragStartStagePos.y = this.app.stage.y;
  };

  private onReleaseClick = (event: InteractionEvent): void => {
    this.finalizeDrag(event);
  }

  private onStartClick = (event: InteractionEvent): void => {
    if (!this.interactive) return;

    this.prepareForDrag(event);
  }
  // #endregion

  get gridType(): GridType {
    return this._gridType;
  }

  get interactive(): boolean {
    return this._interactive;
  }

  get tileSize(): number {
    return this.grid.tileSize;
  }

  set interactive(value: boolean) {
    this._interactive = value;
    this.tokens.forEach(token => token.interactive = value);
  }

  addCursor(
    label: string,
    {
      color,
      text,
      textColor,
    }: {
      color?: string;
      text?: string;
      textColor?: string
    } = {},
  ): Cursor {
    const cursor = new Cursor(this.cursorLayer, {
      color,
      text,
      textColor,
    });

    cursor.draw();
    this.cursors.set(label, cursor);

    return cursor;
  }

  addToken({
    direction,
    hidden,
    id,
    lineColor,
    onClick,
    position,
    text,
  }: {
    direction?: number;
    hidden?: boolean;
    id: string;
    lineColor?: string;
    onClick?: (token: Token) => unknown;
    position?: [number, number];
    text?: string;
  }): Token {
    const container = hidden ? this.hiddenLayer : this.tokenLayer;

    const token = new Token(container, {
      backgroundColor: TOKEN_COLOR,
      direction,
      id,
      interactive: this.interactive,
      lineColor,
      mapOffset: MAP_OFFSET,
      onChangeDirection: this.handleDirectionChange,
      onClick,
      onMove: this.handleTokenMove,
      onMoveEnd: this.handleTokenMoveEnd,
      position: position || this.getCenterOfVisibleCanvas(),
      size: this.tileSize * TOKEN_SIZE_RATIO,
      text,
      textColor: TOKEN_TEXT_COLOR,
    });

    token.draw();
    this.tokens.push(token);

    return token;
  }

  changeBackgroundImage(imageUrl: string | null): void {
    this.backgroundImageUrl = imageUrl;

    const existingBackgrounds = this.mapContainer.children.filter(child => child.name === 'backgroundSprite');
    if (existingBackgrounds.length) {
      existingBackgrounds.forEach(b => this.mapContainer.removeChild(b));
    }

    if (imageUrl !== null) {
      this.addBackgroundImageToContainer(this.mapContainer);

      if (this.gridOffset != null) {
        this.changeGridOffset(this.gridOffset);
      }
    }
  }

  changeGridOffset(offset: [number, number] | undefined): void {
    this.gridOffset = offset || [0, 0];
    this.grid.changeOffset(this.gridOffset);
  }

  changeGridType(gridType: GridType): void {
    if (gridType === this.gridType) { return; }
    this._gridType = gridType;

    const newGrid = this.buildGrid(this.mapContainer, {
      gridType,
      lineColor: this.grid.lineColor,
      tileSize: this.grid.tileSize,
    });

    this.grid.destroy();
    this.grid = newGrid;
  }

  changeMapSize(size: [number, number]): void {
    this.mapSize = size;

    // Update mapContainer dimensions
    const mapContainer = this.mapContainer;
    mapContainer.clear();
    mapContainer.beginFill(MAP_COLOR);
    mapContainer.drawRect(0, 0, this.mapSize[0], this.mapSize[1]);
    mapContainer.endFill();
    mapContainer.position.set(...MAP_OFFSET);

    // Update mapMask dimensions
    const mapMask = this.mapContainer.mask as Graphics;
    mapMask.clear();
    mapMask.beginFill(0xFFFFFF);
    mapMask.drawRect(MAP_OFFSET[0], MAP_OFFSET[1], size[0], size[1]);
    mapMask.endFill();

    // Update interactiveArea dimensions
    const newWidth = size[0] + this.app.screen.width;
    const newHeight = size[1] + this.app.screen.height;
    this.resizeInteractiveArea();

    // Update layers dimensions
    [this.cursorLayer, this.tokenLayer].forEach(layer => {
      layer.hitArea = new Rectangle(0, 0, newWidth, newHeight);
    });

    if (this.backgroundImageUrl != null) {
      this.changeBackgroundImage(this.backgroundImageUrl);
    }

    this.grid.width = size[0];
    this.grid.height = size[1];
    this.grid.draw();
  }

  changeTileSize(tileSize: number): void {
    this.grid.tileSize = tileSize;
    this.tokens.forEach(t => {
      t.size = tileSize * TOKEN_SIZE_RATIO
      t.draw()
    });
  }

  changeTokenDirection(token: Token, direction: number): void {
    token.changeDirection(direction);
  }

  changeTokenImage(token: Token, imageUrl: string): void {
    token.changeImage(imageUrl);
  }

  changeTokenPosition(token: Token, canvasPosition: [number, number]): void {
    const [x, y] = canvasPosition;
    token.move(x, y);
  }

  deselectToken(): void {
    if (!this.selectedToken) { return; }
    this.selectedToken.selected = false;
    this.selectedToken.draw();
    this.selectedToken = null;
    this.onChangeSelectedToken && this.onChangeSelectedToken(null);
  }

  destroy(): void {
    window.removeEventListener('resize', this.handleResize);
    this.app.destroy(true, true);
  }

  draw() {
    this.grid.draw();
    this.tokens.forEach(t => t.draw());
  }

  getCenterOfVisibleCanvas(): [number, number] {
    // Ponto central na tela (em pixels)
    const centerX = this.app.screen.width / 2;
    const centerY = this.app.screen.height / 2;

    // Traduzindo o ponto da tela para as coordenadas do canvas
    const canvasCenterX = (centerX - this.app.stage.x) / this.app.stage.scale.x;
    const canvasCenterY = (centerY - this.app.stage.y) / this.app.stage.scale.y;

    return [canvasCenterX, canvasCenterY];
  }


  getTilePositionFromPoint(point: [number, number]): number[] {
    const [x, y] = point;
    return this.grid.getPositionFromPoint(x, y);
  }

  getTokenTilePosition(token: Token): [number, number] {
    const x = token.centerX;
    const y = token.centerY;

    const row = Math.floor(y / this.grid.tileSize) + 1;
    const col = Math.floor(x / this.grid.tileSize) + 1;

    return [row, col];
  }

  hideHiddenLayer(): void {
    this.app.stage.removeChild(this.hiddenLayer);
  }

  hideToken(token: Token): void {
    token.changeContainer(this.hiddenLayer);
  }

  showToken(token: Token): void {
    token.changeContainer(this.tokenLayer);
  }

  moveTokenInGrid(token: Token, gridPosition: number[]): void {
    const [x, y] = this.grid.getCenterFromTilePosition(gridPosition);
    token.moveAnimated(x, y);
  }

  removeCursor(label: string): void {
    const cursor = this.cursors.get(label);
    cursor?.destroy();
  }

  removeToken(token: Token) {
    const index = this.tokens.indexOf(token);
    index >= 0 && this.tokens.splice(index, 1);
    token.destroy();
  }

  selectToken(token: Token): void {
    if (this.selectedToken) { this.deselectToken(); }
    this.selectedToken = token;
    token.selected = true;
    token.draw();
    this.onChangeSelectedToken && this.onChangeSelectedToken(token);
  }

  set(options: CanvasOptions) {
    const { lineColor, tileSize, type: newGridType } = options.grid || {};
    this.gridOffset = options.gridOffset || undefined;
    this.backgroundImageSize = options.backgroundImageSize || undefined;

    if (options.backgroundColor) {
      this.app.renderer.backgroundColor = options.backgroundColor;
    }

    if (lineColor) { this.grid.lineColor = lineColor; }
    if (tileSize) { this.changeTileSize(tileSize); }

    if (newGridType && newGridType !== this.gridType) {
      this.changeGridType(newGridType);
    }

    this.changeMapSize(options.mapSize)
  }

  showHiddenLayer(): void {
    this.app.stage.addChild(this.hiddenLayer);
  }

  zoomIn(event: WheelEvent): void {
    const currentZoom = this.currentZoom + ZOOM_FACTOR;
    if (currentZoom <= MAX_ZOOM) {
      this.applyZoom(currentZoom, event);
    }
  }

  zoomOut(event: WheelEvent): void {
    const currentZoom = this.currentZoom - ZOOM_FACTOR;
    const visibleWidth = this.app.screen.width / currentZoom;
    const visibleHeight = this.app.screen.height / currentZoom;
    const maxWidth = this.mapContainer.width * 2;
    const maxHeight = this.mapContainer.height * 2;

    if (currentZoom > 0 && visibleWidth <= maxWidth && visibleHeight <= maxHeight) {
      this.applyZoom(currentZoom, event);
    }
  }

  // #region private
  private addBackgroundImageToContainer(container: Container): void {
    if (!this.backgroundImageUrl) { return; }

    const texture = Texture.from(this.backgroundImageUrl)
    const backgroundSprite = new Sprite(texture);
    backgroundSprite.name = 'backgroundSprite';

    if (this.backgroundImageSize) {
      backgroundSprite.width = this.backgroundImageSize[0];
      backgroundSprite.height = this.backgroundImageSize[1];
    }
    backgroundSprite.x = 0;
    backgroundSprite.y = 0;

    // Create and apply a mask to the background sprite
    const spriteMask = new Graphics();
    spriteMask.beginFill(0xFFFFFF);
    spriteMask.drawRect(0, 0, this.mapSize[0], this.mapSize[1]);
    spriteMask.endFill();

    backgroundSprite.mask = spriteMask;
    container.addChildAt(backgroundSprite, 0);
    container.addChildAt(spriteMask, 1); // You might need to adjust this index
  }

  private addEventListeners(): void {
    window.addEventListener('resize', this.handleResize);
    this.app.view.addEventListener('wheel', this.handleMouseScroll);
    window.addEventListener('keydown', this.handleKeyShortcut);

    this.interactiveArea
      .on('pointerdown', this.onStartClick)
      .on('pointerup', this.finalizeDrag)
      .on('pointerupoutside', this.onReleaseClick)
      .on('pointermove', this.handleOnDragMove);

    // Mouse move event
    this.interactiveArea.on('pointermove', (event: InteractionEvent) => {
      if (!this.onMouseMove) { return; }

      const { x, y } = event.data.getLocalPosition(this.mapContainer);
      this.onMouseMove([x, y]);
    });
  }

  private applyZoom(newZoom: number, event: WheelEvent): void {
    const { x: beforeX, y: beforeY } = this.app.stage.toLocal(new Point(event.clientX, event.clientY));

    this.app.stage.scale.x = newZoom;
    this.app.stage.scale.y = newZoom;
    this.currentZoom = newZoom;

    const { x: afterX, y: afterY } = this.app.stage.toLocal(new Point(event.clientX, event.clientY));

    this.app.stage.position.x += (afterX - beforeX) * newZoom;
    this.app.stage.position.y += (afterY - beforeY) * newZoom;

    this.resizeInteractiveArea();
    this.draw();
    this.tokens.forEach(token => token.afterZoom(newZoom));
  }

  private buildGrid(mapContainer: Container, {
    gridType,
    lineColor,
    tileSize,
  }: {
    gridType: GridType,
    lineColor?: number | null;
    tileSize?: number | null;
  }): BaseGrid {
    const params: GridOptions = {
      height: this.interactiveArea.height,
      lineColor: lineColor || DEFAULT_GRID_LINE_COLOR,
      tileSize: tileSize || DEFAULT_GRID_TILE_SIZE,
      width: this.interactiveArea.width,
    };

    switch (gridType) {
      case GridType.Empty:
        return new EmptyGrid(mapContainer, params);
      case GridType.Hexagon:
        return new HexagonGrid(mapContainer, params);
      default:
        return new SquareGrid(mapContainer, params);
    }
  }

  private buildHiddenLayer(): Container {
    const layer = this.buildLayer(false);
    layer.alpha = 0.5

    return layer;
  }

  private buildInteractiveArea(): Graphics {
    const width = this.app.screen.width;
    const height = this.app.screen.height;

    const layer = new Graphics();
    layer.beginFill(0x7FE688, 0.001);
    layer.drawRect(0, 0, width, height);
    layer.endFill();
    layer.interactive = true;

    this.app.stage.addChild(layer);

    return layer;
  }

  private buildLayer(shouldDisplay = true): Container {
    const width = this.mapSize[0] + this.app.screen.width;
    const height = this.mapSize[1] + this.app.screen.height;

    const layer = new Container();
    layer.hitArea = new Rectangle(0, 0, width, height)

    if (shouldDisplay) {
      this.app.stage.addChild(layer);
    }

    return layer;
  }

  private buildMapContainer(): Graphics {
    const mapContainer = new Graphics();
    mapContainer.beginFill(MAP_COLOR);
    mapContainer.drawRect(0, 0, this.mapSize[0], this.mapSize[1]);
    mapContainer.endFill();
    mapContainer.position.set(...MAP_OFFSET);
    this.app.stage.addChild(mapContainer);

    const mapMask = new Graphics();
    mapMask.beginFill(0xFFFFFF);
    mapMask.drawRect(MAP_OFFSET[0], MAP_OFFSET[1], this.mapSize[0], this.mapSize[1]);
    mapMask.endFill();
    this.app.stage.addChild(mapMask);

    mapContainer.mask = mapMask;

    return mapContainer;
  }

  private resizeInteractiveArea(): void {
    this.interactiveArea.width = this.app.screen.width / this.currentZoom;
    this.interactiveArea.height = this.app.screen.height / this.currentZoom;

    // Move a área interativa para que ela ocupe a tela visível do usuário
    this.interactiveArea.position.x = -this.app.stage.position.x / this.currentZoom;
    this.interactiveArea.position.y = -this.app.stage.position.y / this.currentZoom;
  }
  // #endregion
}

export default Canvas;
