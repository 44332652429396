import { Container } from 'pixi.js';

import { GridType } from './Canvas';

// #region typing
export interface GridOptions {
  backgroundColor?: number | null;
  height: number;
  lineColor: number;
  offsetX?: number;
  offsetY?: number;
  tileSize: number;
  width: number;
}

type GridJSON = Required<
  Omit<GridOptions, 'offsetX' | 'offsetY'>
> & { type: GridType };
// #endregion

abstract class BaseGrid {
  backgroundColor?: number;
  height: number;
  lineColor: number;
  lineOpacity = 0.19;
  offsetX: number;
  offsetY: number;
  tileSize: number;
  width: number;

  protected container: Container;
  protected gridContainer: Container;

  constructor(
    container: Container,
    options: GridOptions,
  ) {
    this.backgroundColor = options.backgroundColor || undefined;
    this.container = container;
    this.gridContainer = new Container();
    this.height = options.height;
    this.lineColor = options.lineColor;
    this.offsetX = options.offsetX || 0;
    this.offsetY = options.offsetY || 0;
    this.tileSize = options.tileSize;
    this.width = options.width;

    this.gridContainer.interactive = true;
    this.container.addChild(this.gridContainer);
  }

  abstract get type(): GridType;
  abstract changeOffset(offset: [number, number]): void;
  abstract destroy(): void;
  abstract draw(): void;
  abstract getCenterFromTilePosition(position: number[]): [number, number];
  abstract getPositionFromPoint(x: number, y: number): number[];

  getBoundaryDimensions(): { width: number; height: number } {
    return {
      height: this.gridContainer.height,
      width: this.gridContainer.width,
    };
  }

  toJSON(): GridJSON {
    return {
      backgroundColor: this.backgroundColor || null,
      width: this.width,
      lineColor: this.lineColor,
      height: this.height,
      tileSize: this.tileSize,
      type: this.type,
    }
  }
}

export default BaseGrid;
