
import { v4 as uuidv4 } from 'uuid';

const BASE62 = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

function toBase62(num: number): string {
  let result = '';
  while (num) {
    result = BASE62[num % 62] + result;
    num = Math.floor(num / 62);
  }
  return result;
}

export function uuidBase62(): string {
  const uuid = uuidv4();

  // Remove os hífens do UUID e interpreta o resultado como um número hexadecimal
  const hex = uuid.replace(/-/g, '');

  // Converta o número hexadecimal para decimal
  const decimal = BigInt(`0x${hex}`);

  // Converta o número decimal para base62
  return toBase62(Number(decimal));
}
