import { Container, Graphics, InteractionData, InteractionEvent, Loader, Sprite, Text, TextStyle, Texture, utils } from 'pixi.js';

// #region typings
export interface TokenJSON {
  backgroundColor: string;
  lineColor?: string;
  position: [number, number];
  size: number;
  text?: string;
  textColor?: string;
}
// #endregion

const DEFAULT_BACKGROUND_COLOR = '#FFFFFF';
const MOVE_ANIMATION_TIME = 300; // in milliseconds
const SELECTED_OUTLINE_COLOR = '#0c8be8';


class Token {
  backgroundColor: string;
  direction?: number;
  id: string;
  imageUrl?: string;
  interactive: boolean;
  lineColor?: string;
  mapOffset: [number, number];
  onChangeDirection?: (direction: number, token: Token) => void;
  onClick?: (token: Token) => void;
  onMove?: (x: number, y: number, token: Token) => [number, number] | void;
  onMoveEnd?: (x: number, y: number, token: Token) => [number, number] | void;
  selected: boolean;
  size: number;
  text?: string;
  textColor?: string;

  private arrowGraphic?: Graphics;
  private borderGraphic?: Graphics;
  private circleGraphic?: Graphics;
  private container: Container;
  private currentZoom = 1;
  private graphic: Graphics;
  private imageSprite?: Sprite;
  private isDragging = false;
  private loader: Loader;
  private rotateButtonEnabled = false;
  private rotateButtonGraphic?: Graphics;
  private rotatePressed = false;

  constructor(
    container: Container,
    {
      backgroundColor,
      direction = 270,
      id,
      imageUrl,
      interactive,
      lineColor,
      mapOffset,
      onChangeDirection,
      onClick,
      onMove,
      onMoveEnd,
      position,
      selected = false,
      size,
      text,
      textColor,
    }: {
      backgroundColor?: string;
      direction?: number;
      id: string;
      imageUrl?: string;
      interactive?: boolean;
      lineColor?: string;
      mapOffset?: [number, number];
      onChangeDirection?: (direction: number, token: Token) => void;
      onClick?: (token: Token) => void;
      onMove?: (x: number, y: number, token: Token) => [number, number] | void;
      onMoveEnd?: (x: number, y: number, token: Token) => [number, number] | void;
      position?: [number, number];
      selected?: boolean;
      size: number;
      text?: string;
      textColor?: string;
    },
  ) {
    this.backgroundColor = backgroundColor || DEFAULT_BACKGROUND_COLOR;
    this.container = container;
    this.direction = direction;
    this.id = id;
    this.imageUrl = imageUrl;
    this.interactive = interactive == null ? true : interactive;
    this.lineColor = lineColor;
    this.mapOffset = mapOffset || [0, 0];
    this.onChangeDirection = onChangeDirection;
    this.onClick = onClick;
    this.onMove = onMove;
    this.onMoveEnd = onMoveEnd;
    this.selected = selected;
    this.size = size;
    this.text = text;
    this.textColor = textColor;

    this.graphic = this.createTokenGraphics(position);
    this.container.addChild(this.graphic);
    this.loader = this.buildLoader();
    this.setupEvents();
  }

  get centerX(): number {
    return this.graphic.x;
  }

  get centerY(): number {
    return this.graphic.y;
  }

  get position(): [number, number] {
    return [this.centerX, this.centerY];
  }

  afterZoom(scale: number): void {
    this.currentZoom = scale;
    this.drawRotateButton();
  }

  changeContainer(container: Container) {
    this.container.removeChild(this.graphic);
    container.addChild(this.graphic);

    this.container = container;
  }

  changeDirection(direction: number): void {
    this.direction = direction;
    this.drawArrow();
  }

  changeImage(imageUrl: string): void {
    this.imageUrl = imageUrl;
    this.drawImage();
  }

  destroy(): void {
    // Remove todos os listeners de eventos do token
    this.graphic.off('pointerdown')
      .off('pointerup')
      .off('pointerupoutside')
      .off('pointermove');

    // Remove o token do stage do Pixi.js
    this.container.removeChild(this.graphic);

    // Destrua o gráfico para liberar memória
    this.graphic.destroy();
  }

  disableRotation(): void {
    this.rotateButtonEnabled = false;
    this.destroyRotateButton();
  }

  draw(): void {
    this.drawArrow();
    this.drawCircle();
    this.drawBorder();
    this.drawImage();

    if (this.rotateButtonEnabled) {
      this.drawRotateButton();
    } else {
      this.destroyRotateButton();
    }
  }

  enableRotation(): void {
    this.rotateButtonEnabled = true;
    this.drawRotateButton();
  }

  move(x: number, y: number) {
    this.graphic.x = x;
    this.graphic.y = y;
  }

  moveAnimated(x: number, y: number): void {
    const startX = this.graphic.x;
    const startY = this.graphic.y;
    let startTime: number;

    const animate = (time: number) => {
      // Inicializa o tempo de início
      if (!startTime) {
        startTime = time;
      }

      // Calcula quanto tempo passou
      const timeElapsed = time - startTime;

      // Calcula a fração de tempo decorrido em relação ao tempo total de movimento
      const fraction = Math.min(timeElapsed / MOVE_ANIMATION_TIME, 1);

      // Atualiza a posição do token usando interpolação linear
      this.graphic.x = startX + (x - startX) * fraction;
      this.graphic.y = startY + (y - startY) * fraction;

      // Continua a animação se o movimento ainda não terminou
      if (fraction < 1) {
        requestAnimationFrame(animate);
      }
    };

    // Inicia a animação
    requestAnimationFrame(animate);
  }

  toJSON(): TokenJSON {
    return {
      backgroundColor: this.backgroundColor,
      lineColor: this.lineColor,
      position: this.position,
      size: this.size,
      text: this.text,
      textColor: this.textColor,
    }
  }

  private buildLoader(): Loader {
    const loader = new Loader();
    loader.add('mdiRotateRight', '/icons/rotate-right.svg')
    return loader;
  }

  private clearRotateButton(): void {
    if (!this.rotateButtonGraphic) { return; }

    // Limpa o gráfico existente
    this.rotateButtonGraphic.clear();

    // Remove todos os filhos existentes (por exemplo, o tokenText)
    while (this.rotateButtonGraphic.children.length) {
      this.rotateButtonGraphic.removeChildAt(0);
    }
  }

  private createTokenGraphics(position?: [number, number]): Graphics {
    const graphic = new Graphics();

    graphic.x = position ? position[0] : 0;
    graphic.y = position ? position[1] : 0;
    graphic.interactive = true;
    graphic.buttonMode = true;

    return graphic;
  }

  private destroyRotateButton(): void {
    if (!this.rotateButtonGraphic) { return; }

    this.clearRotateButton();
    this.rotateButtonGraphic.destroy();
    this.rotateButtonGraphic = undefined;
    this.rotatePressed = false;
  }

  private drawArrow(): Graphics | undefined {
    if (this.direction == null) { return; }

    if (!this.arrowGraphic) {
      this.arrowGraphic = new Graphics();
      this.graphic.addChild(this.arrowGraphic);
    }

    const arrowLength = this.size * 0.35;
    const arrowWidth = this.size * 0.15;
    const angleInRadians = (this.direction * Math.PI) / 180;

    const tipX = (this.size + arrowLength) * Math.cos(angleInRadians);
    const tipY = (this.size + arrowLength) * Math.sin(angleInRadians);

    const widthAngle = Math.atan2(arrowWidth, arrowLength);

    const tailX1 = this.size * Math.cos(angleInRadians + widthAngle);
    const tailY1 = this.size * Math.sin(angleInRadians + widthAngle);

    const tailX2 = this.size * Math.cos(angleInRadians - widthAngle);
    const tailY2 = this.size * Math.sin(angleInRadians - widthAngle);

    const lineColor = this.selected ? SELECTED_OUTLINE_COLOR : this.lineColor || '#FF0000';
    this.arrowGraphic.clear();
    this.arrowGraphic.beginFill(utils.string2hex(lineColor));
    this.arrowGraphic.moveTo(tipX, tipY);
    this.arrowGraphic.lineTo(tailX1, tailY1);
    this.arrowGraphic.lineTo(tailX2, tailY2);
    this.arrowGraphic.closePath();
    this.arrowGraphic.endFill();

    return this.arrowGraphic;
  }

  private drawBorder(): void {
    if (this.borderGraphic) {
      this.borderGraphic.clear();
    } else {
      this.borderGraphic = new Graphics();
      this.graphic.addChild(this.borderGraphic);
    }

    const lineWidth = this.size / 20;

    if (this.selected) {
      const color = utils.string2hex(SELECTED_OUTLINE_COLOR);
      this.borderGraphic.lineStyle(lineWidth, color);
    } else if (this.lineColor) {
      const color = utils.string2hex(this.lineColor);
      this.borderGraphic.lineStyle(lineWidth, color);
    }

    this.borderGraphic.drawCircle(0, 0, this.size);
  }

  private drawCircle(): void {
    if (this.circleGraphic) {
      // Limpa o gráfico existente
      this.circleGraphic.clear();

      // Remove todos os filhos existentes (por exemplo, o tokenText)
      while (this.circleGraphic.children.length) {
        this.circleGraphic.removeChildAt(0);
      }
    } else {
      this.circleGraphic = new Graphics();
    }

    this.circleGraphic.beginFill(utils.string2hex(this.backgroundColor));

    // Desenha o círculo (token)
    this.circleGraphic.drawCircle(0, 0, this.size);

    // Encerra o preenchimento
    this.circleGraphic.endFill();

    if (this.text && !this.imageUrl) {
      const scale = 5
      const textStyle = new TextStyle({
        fontSize: Math.round(this.size * 0.8 * scale), // 80% do tamanho do token
        fill: this.textColor || '#000000',
        align: 'center'
      });
      const tokenText = new Text(this.text, textStyle);
      tokenText.anchor.set(0.5);
      tokenText.scale.set(1 / scale);
      this.circleGraphic.addChild(tokenText);
    }

    this.graphic.addChild(this.circleGraphic);
  }

  private async drawImage(): Promise<void> {
    if (!this.imageUrl || !this.circleGraphic) { return; }

    if (this.imageSprite) {
      this.graphic.removeChild(this.imageSprite);
      if (!this.imageSprite.destroyed) { this.imageSprite.destroy(); }
    }

    const texture = await Texture.fromURL(this.imageUrl)
    this.imageSprite = new Sprite(texture);

    // Ajusta o sprite para ficar no centro
    this.imageSprite.anchor.set(0.5, 0.5);

    // Ajusta o tamanho da imagem para caber dentro do círculo
    const minDim = Math.min(this.imageSprite.width, this.imageSprite.height);
    const scale = this.size * 2 / minDim;
    this.imageSprite.scale.set(scale, scale);

    // Usa o gráfico circular existente como uma máscara
    this.imageSprite.mask = this.circleGraphic;

    this.graphic.addChild(this.imageSprite);

    // Desenha a borda em cima da imagem
    this.drawBorder();
  }

  private drawRotateButton(): void {
    if (this.direction == null || !this.rotateButtonEnabled || !this.selected) { return; }

    if (!this.rotateButtonGraphic) {
      this.rotateButtonGraphic = new Graphics();
      this.rotateButtonGraphic.interactive = true;
      this.rotateButtonGraphic.buttonMode = true;
      this.rotateButtonGraphic.cursor = 'grab';
    } else {
      this.clearRotateButton();
    }

    const angleInRadians = (this.direction * Math.PI) / 180;

    // Adicione os event listeners
    const onRotateButtonPressed = () => {
      this.rotatePressed = true;
      if (this.rotateButtonGraphic) { this.rotateButtonGraphic.cursor = 'grabbing'; }
      document.body.style.cursor = 'grabbing';
    }
    const onRotateButtonRelease = (event: InteractionEvent) => {
      this.rotatePressed = false;
      if (this.rotateButtonGraphic) { this.rotateButtonGraphic.cursor = 'grab'; }
      document.body.style.cursor = 'default';
      event.stopPropagation();
    }
    this.rotateButtonGraphic.on('pointerdown', onRotateButtonPressed)
      .on('pointerup', onRotateButtonRelease)
      .on('pointerupoutside', onRotateButtonRelease)

    this.loader.load((_loader, resources) => {
      const rotateIconTexture = resources.mdiRotateRight.texture;
      if (!rotateIconTexture || !this.rotateButtonGraphic) { return; }

      const rotateIconSprite = new Sprite(rotateIconTexture);
      const scale = (this.size / rotateIconSprite.width) * 1.5;
      rotateIconSprite.scale.set(scale);

      // Calcule o raio do círculo baseado no tamanho do sprite mais um pequeno padding.
      const circleRadius = rotateIconSprite.width / 2 + 2;

      // Calcule a posição X, Y do botão baseado na ponta da seta e no zoom
      const [arrowTipX, arrowTipY] = this.getArrowTipPosition() || [0, 0];
      let iconX = this.graphic.x + arrowTipX;
      let iconY = this.graphic.y + arrowTipY;
      iconX = (iconX * this.currentZoom) + (((circleRadius * this.currentZoom) + 4) * Math.cos(angleInRadians));
      iconY = (iconY * this.currentZoom) + (((circleRadius * this.currentZoom) + 4) * Math.sin(angleInRadians));

      // Crie o círculo gráfico
      this.rotateButtonGraphic.beginFill(utils.string2hex("#303030"));
      this.rotateButtonGraphic.drawCircle(iconX, iconY, circleRadius);
      this.rotateButtonGraphic.endFill();

      // Adicione o círculo ao gráfico principal primeiro, para que ele fique atrás do sprite.
      this.container.addChild(this.rotateButtonGraphic);

      // Atualize as coordenadas x e y do ícone de rotação
      rotateIconSprite.x = iconX;
      rotateIconSprite.y = iconY;
      rotateIconSprite.anchor.set(0.5);

      // Atualiza escala baseado no zoom atual;
      this.rotateButtonGraphic?.scale.set(1 / this.currentZoom);

      // Adicione o sprite ao gráfico principal após o círculo
      this.rotateButtonGraphic.addChild(rotateIconSprite);
    });
  }

  private getArrowTipPosition(): [number, number] | undefined {
    if (this.direction == null) { return undefined; }

    const arrowLength = this.size * 0.35;
    const angleInRadians = (this.direction * Math.PI) / 180;

    const tipX = (this.size + arrowLength) * Math.cos(angleInRadians);
    const tipY = (this.size + arrowLength) * Math.sin(angleInRadians);

    return [tipX, tipY];
  }

  private setupEvents() {
    this.isDragging = false;
    let wasDragged = false;
    let startX: number, startY: number;
    let eventData: InteractionData | null = null;

    const onTokenClick = (event: InteractionEvent) => {
      if (this.rotatePressed) { return; }

      prepareForDrag(event);
    };

    const onTokenRelease = (event: InteractionEvent) => {
      finalizeDrag(event);
    };

    const onDragMove = (event: InteractionEvent) => {
      if (!this.interactive) { return; }

      if (!this.rotatePressed && this.isDragging) {
        moveTokenBasedOnMouse();
      } else if (this.rotatePressed) {
        changeDirectionBasedOnMouse(event);
      }
    };

    const prepareForDrag = (event: InteractionEvent) => {
      eventData = event.data;
      const position = eventData.getLocalPosition(this.graphic.parent);
      startX = position.x;
      startY = position.y;
      this.graphic.alpha = 0.5;
      this.isDragging = true;
      wasDragged = false;
    };

    const finalizeDrag = (event?: InteractionEvent) => {
      this.graphic.alpha = 1;
      this.isDragging = false;
      wasDragged && handleDragEnd();
      eventData = null;

      if (!wasDragged && event?.target && this.onClick) {
        this.onClick(this);
      }

      wasDragged = false;
      this.drawRotateButton();
    };

    const handleDragEnd = () => {
      const newPosition = eventData?.getLocalPosition(this.graphic.parent);
      this.onMoveEnd && this.onMoveEnd(newPosition!.x, newPosition!.y, this);
    };

    const moveTokenBasedOnMouse = () => {
      const newPosition = eventData?.getLocalPosition(this.graphic.parent);
      if (newPosition) {
        let { x, y } = newPosition;
        x -= this.mapOffset[0];
        y -= this.mapOffset[1];

        const renderPosition = (this.onMove && this.onMove(newPosition.x, newPosition.y, this)) || [x, y];
        this.graphic.x = renderPosition[0] + this.mapOffset[0];
        this.graphic.y = renderPosition[1] + this.mapOffset[1];

        const distanceMoved = Math.sqrt((x - startX) ** 2 + (y - startY) ** 2);
        if (distanceMoved > 5) {
          wasDragged = true;
          this.destroyRotateButton();
        }
      }
    };

    const changeDirectionBasedOnMouse = (event: InteractionEvent) => {
      if (this.direction != null) {
        const position = event.data.getLocalPosition(this.graphic.parent);

        const { x, y } = position;

        // Calcule o ângulo da direção entre o cursor e o token
        const deltaX = x - this.centerX;
        const deltaY = y - this.centerY;
        this.direction = Math.atan2(deltaY, deltaX) * (180 / Math.PI);

        // Redesenhe a seta e o botão de rotação
        this.drawArrow();
        this.drawRotateButton();

        // Dispara evento de mudança de direção
        this.onChangeDirection && this.onChangeDirection(this.direction, this);
      }
    };

    // Adicionar e remover listeners do teclado
    this.graphic.on('pointerdown', onTokenClick)
      .on('pointerup', onTokenRelease)
      .on('pointerupoutside', onTokenRelease)
      .on('pointermove', onDragMove);
  }
}

export default Token;
