import { isDevelopment } from '../../config/config';
import fetchIceServers from '../../clients/fetchIceServers';
import PeerSession from './PeerSession';
import RemotePlayer from './RemotePlayer';
import SignalingService, { PeerData, Unsubscribe } from './SignalingService';

// #region typings
export type PlayerHandler = (player: RemotePlayer) => unknown;

export interface MultiplayerRoomDependencies {
  authToken?: string;
  debug: boolean;
  localPlayerData?: PeerData;
  localPlayerId: string;
  mapId: string;
  signalingService: SignalingService;
}
// #endregion

class MultiplayerRoom implements MultiplayerRoomDependencies {
  authToken?: string;
  debug: boolean;
  localPlayerData?: PeerData;
  localPlayerId: string;
  remotePlayers: RemotePlayer[] = [];
  mapId: string;
  signalingService: SignalingService;

  private iceServers: RTCIceServer[] = [];
  private localPlayerDataUpdateHandlers: Array<(data: PeerData) => unknown> = [];
  private playerDisconnectHandlers: PlayerHandler[] = [];
  private remotePlayerDataUpdateHandlers: PlayerHandler[] = [];
  private unsubscribers: Unsubscribe[] = [];

  constructor({
    debug = false,
    authToken,
    localPlayerData,
    localPlayerId,
    mapId,
    signalingService,
  }: MultiplayerRoomDependencies) {
    this.debug = debug;
    this.authToken = authToken;
    this.localPlayerData = localPlayerData;
    this.localPlayerId = localPlayerId;
    this.mapId = mapId;
    this.signalingService = signalingService;

    this.init();
  }

  async connectToRemotePlayers(handler: PlayerHandler): Promise<RemotePlayer[]> {
    const [iceServers, players] = await Promise.all([
      this.fetchIceServers(),
      this.fetchRemotePlayers(),
    ]);

    players.forEach((player) => {
      if (!player.peerSession.isPending()) { return; }
      player.peerSession.iceServers = iceServers;
      player.peerSession.connect().then(() => handler(player));
    });

    return players;
  }

  async fetchIceServers(): Promise<RTCIceServer[] | undefined> {
    if (isDevelopment || !this.authToken) { return; }

    if (this.iceServers.length === 0) {
      this.iceServers = await fetchIceServers(this.authToken);
    }

    return this.iceServers;
  }

  async fetchRemotePlayers(): Promise<RemotePlayer[]> {
    const peers = await this.signalingService.listPeers();
    const remotePeers = peers.filter(([id]) => id !== this.localPlayerId);

    const players = remotePeers.map(([id, peerData]) => {
      const player = this.remotePlayers.find(p => p.id === id);
      if (player) { return player; }

      return this.buildRemotePlayer(id, peerData);
    });

    this.remotePlayers = players;
    return players;
  }

  async init(): Promise<void> {
    this.signalingService.sendConnection(this.localPlayerId, this.localPlayerData);
    this.watchLocalPlayerDisconnection();

    this.signalingService.onPeerDataUpdate(this.localPlayerId, (data) => {
      this.localPlayerDataUpdateHandlers.forEach(h => h && h(data));
    })

    this.signalingService.onPeerDisconnect((peerId) => {
      const remotePlayer = this.remotePlayers.find(p => p.id === peerId);
      if (!remotePlayer) { return; }
      this.playerDisconnectHandlers.forEach(h => h(remotePlayer));
    });
  }

  leave(): void {
    this.unsubscribers.forEach(unsubscriber => unsubscriber());

    this.remotePlayers.forEach((player) => {
      player.peerSession.disconnect();
    });
  }

  async onPlayerConnect(
    handler: PlayerHandler,
    failHandler?: PlayerHandler,
  ): Promise<void> {
    const unsubscriber = await this.signalingService.onPeerConnect(async (playerId, playerData) => {
      if (playerId === this.localPlayerId) { return; }

      let player = this.remotePlayers.find(p => p.id === playerId);
      if (player?.peerSession.isConnected) { return; }

      player = this.buildRemotePlayer(playerId, playerData);
      player.peerSession.iceServers = await this.fetchIceServers();
      this.remotePlayers.push(player);

      await player.peerSession.onConnect(
        () => { handler(player as RemotePlayer) },
        failHandler && (() => { failHandler(player as RemotePlayer); })
      );

      // On player disconnect
      player.peerSession.onDisconnect(() => {
        if (!player) { return; }

        this.removeRemotePlayer(player);

        // Chama todos os handlers de desconexão
        this.playerDisconnectHandlers.forEach(handler => player && handler(player));
      });

      // On update remote player data
      player.peerSession.onDataUpdate((data) => {
        if (!player || !data) { return; }
        player.data = data;
        this.remotePlayerDataUpdateHandlers.forEach(handler => player && handler(player))
      });
    });

    this.unsubscribers.push(unsubscriber);
  }

  onLocalPlayerDataUpdate(handler: (data: PeerData) => unknown): void {
    this.localPlayerDataUpdateHandlers.push(handler);
  }

  onPlayerDisconnect(handler: PlayerHandler): void {
    this.playerDisconnectHandlers.push(handler);
  }

  onRemotePlayerDataUpdate(handler: PlayerHandler): void {
    this.remotePlayerDataUpdateHandlers.push(handler);
  }

  async updatePlayerData(playerId: string, data: PeerData): Promise<void> {
    await this.signalingService.updatePeerData(playerId, data);
  }

  // #region private
  private buildRemotePlayer(id: string, data: PeerData): RemotePlayer {
    const peerSession = new PeerSession({
      debug: this.debug,
      localId: this.localPlayerId,
      peerId: id,
      sessionId: this.mapId,
      signalingService: this.signalingService,
    });

    return new RemotePlayer({
      data,
      id,
      peerSession,
    });
  }

  private removeRemotePlayer(player: RemotePlayer) {
    if (player.id === this.localPlayerId) { return; }

    const index = this.remotePlayers.indexOf(player);
    this.remotePlayers.splice(index, 1);

    // Remove o peer no registro de peers conectados
    this.signalingService.removePeer(player.id);
  }

  private watchLocalPlayerDisconnection() {
    // Caso outro peer remova esse player da lista de players conectados,
    // devemos adicionar novamente como conectado
    this.signalingService.onPeerDisconnect((playerId) => {
      if (playerId !== this.localPlayerId) { return; }
      this.signalingService.sendConnection(this.localPlayerId, this.localPlayerData);
    });
  }
  // #endregion
}

export default MultiplayerRoom;
