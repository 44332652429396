import { constants } from 'smooth-dnd';
import PropTypes from 'prop-types';
import React, { ReactNode, FC } from 'react';

const { wrapperClass } = constants;

interface DraggableProps {
	render?: () => React.ReactElement;
	className?: string;
	children?: ReactNode;
}

const Draggable: FC<DraggableProps> = ({ render, className, children }) => {
	if (render) {
		return React.cloneElement(render(), { className: wrapperClass });
	}

	const clsName = `${className ? (className + ' ') : ''}`;
	return (
		<div className={`${clsName}${wrapperClass}`} >
			{children}
		</div>
	);
};

Draggable.propTypes = {
	render: PropTypes.func,
	className: PropTypes.string,
};

export default Draggable;
