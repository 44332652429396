import { StorageRepositoryAdapter } from './storageAdapters/StorageRepositoryAdapter.interface';

interface MapFileRepositoryDependencies {
  adapter: StorageRepositoryAdapter;
  campaignId: string;
  mapId: string;
}

class MapFileRepository implements MapFileRepositoryDependencies {
  adapter: StorageRepositoryAdapter;
  campaignId: string;
  mapId: string;

  constructor({
    adapter,
    campaignId,
    mapId,
  }: MapFileRepositoryDependencies) {
    this.adapter = adapter;
    this.campaignId = campaignId;
    this.mapId = mapId;
  }

  async deleteByPath(filePath: string): Promise<void> {
    return this.adapter.delete(filePath);
  }

  async getUrlByPath(filePath: string): Promise<string | null> {
    return this.adapter.getDownloadUrl(filePath);
  }

  async uploadBackgroundImage(file: File): Promise<string> {
    const filePath = this.buildBackgroundImagePath();
    await this.adapter.upload(filePath, file, 'public, max-age=1036800');

    return filePath;
  }

  async uploadCharacterImage(characterId: string, file: File): Promise<string> {
    const filePath = this.buildCharacterImagePath(characterId);
    await this.adapter.upload(filePath, file, 'public, max-age=1036800')

    return filePath;
  }

  private buildBackgroundImagePath() {
    return this.buildBasePath() + `/background/backgroundImage`;
  }

  private buildBasePath(): string {
    return `/campaigns/${this.campaignId}/maps/${this.mapId}`;
  }

  private buildCharacterImagePath(characterId: string) {
    return this.buildBasePath() + `/characters/${characterId}`;
  }
}

export default MapFileRepository;
