import { GridType } from './Canvas';
import BaseGrid from './BaseGrid';

class EmptyGrid extends BaseGrid {
  readonly cols = 0;
  readonly rows = 0;
  readonly type = GridType.Empty;

  changeOffset(): void {
  }

  destroy(): void {
  }

  draw(): void {
  }

  getCenterFromTilePosition(): [number, number] {
    return [0, 0];
  }

  getPositionFromPoint(): [number, number] {
    return [0, 0];
  }
}

export default EmptyGrid;
