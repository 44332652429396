import { createTheme } from '@mui/material';
import { ThemeOptions } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#FFA726',
      light: '#FFC97B',
      dark: '#E18600',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    secondary: {
      main: '#DAA520', // Gold Accent
      contrastText: '#2C3E50', // Metallic Gray for text on Gold
    },
    text: {
      primary: 'rgba(255, 255, 255, 1)',
      secondary: 'rgba(255, 255, 255, 0.6)',
      disabled: 'rgba(255, 255, 255, 0.38)',
    },
    background: {
      default: '#303030',
      paper: '#303030',
      paperBlur: 'rgba(48, 48, 48, 0.6)',
    },
  },
} as ThemeOptions);

export default theme;
