import { difference } from '../../utils/arrayFunctions';
import DataChannel from './DataChannel';
import PeerSession from './PeerSession';
import { PeerData } from './SignalingService';

// #region typings
type DragEndHandler = (objectId: string) => unknown;
type DragMoveHandler = (objectId: string, position: [number, number]) => unknown;
type DragStartHandler = (objectId: string) => unknown;
type MouseMoveHandler = (position: [number, number]) => unknown;
export type ObjectMove = Record<string, [number, number]>;

type MoveChannelData = [
  number, // X axis
  number, // Y axis
  ObjectMove, // Record<objectId, [x, y]>
];

interface RemotePlayerDependencies {
  data: PeerData;
  id: string;
  peerSession: PeerSession;
}
// #endregion

class RemotePlayer implements RemotePlayerDependencies {
  data: PeerData;
  id: string;
  peerSession: PeerSession;

  private dragEndHandlers: DragEndHandler[] = [];
  private draggingObjects: Set<string> = new Set();
  private dragMoveHandlers: DragMoveHandler[] = [];
  private dragStartHandlers: DragStartHandler[] = [];
  private mouseMoveHandlers: MouseMoveHandler[] = [];
  private moveChannel: DataChannel;

  constructor({
    data,
    id,
    peerSession,
  }: RemotePlayerDependencies) {
    this.data = data;
    this.id = id;
    this.peerSession = peerSession;

    this.moveChannel = this.createMoveChannel(peerSession);
  }

  onDragEnd(handler: DragEndHandler): void {
    this.dragEndHandlers.push(handler);
  }

  onDragMove(handler: DragMoveHandler): void {
    this.dragMoveHandlers.push(handler);
  }

  onDragStart(handler: DragStartHandler): void {
    this.dragStartHandlers.push(handler);
  }

  onMouseMove(handler: MouseMoveHandler): void {
    this.mouseMoveHandlers.push(handler);
  }

  sendMouseMove(pos: [number, number], objectsMoves?: ObjectMove): void {
    this.moveChannel.send(JSON.stringify([...pos, objectsMoves]));
  }

  private createMoveChannel(peerSession: PeerSession): DataChannel {
    const channel = peerSession.createChannel('move');

    channel.onMessage((message: string) => {
      const [x, y, objects]: MoveChannelData = JSON.parse(message);

      this.mouseMoveHandlers.forEach(h => h([x, y]));
      const objectIds = Object.keys(objects);
      const newObjectIds = difference(objectIds, this.draggingObjects);
      const oldObjectIds = difference(this.draggingObjects, objectIds);

      if (newObjectIds.length) {
        // Adiciona novos objetos em draggingObjects
        newObjectIds.forEach(elem => this.draggingObjects.add(elem));

        newObjectIds.forEach(id => {
          this.dragStartHandlers.forEach(h => h(id));
        });
      }

      if (oldObjectIds.length) {
        // Remove objetos antigos de draggingObjects
        oldObjectIds.forEach(elem => this.draggingObjects.delete(elem));

        oldObjectIds.forEach(id => {
          this.dragEndHandlers.forEach(h => h(id));
        });
      }

      objectIds.forEach(id => {
        this.dragMoveHandlers.forEach(h => h(id, objects[id]))
      });
    });

    return channel;
  }
}

export default RemotePlayer;
