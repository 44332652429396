import { IconButton, Paper } from '@mui/material';
import { useState } from 'react';
import DescriptionIcon from '@mui/icons-material/Description';
import ImageIcon from '@mui/icons-material/Image';

import CanvasFormDialog from './dialogs/CanvasFormDialog';
import RPGSystemFormDialog from './dialogs/RPGSystemFormDialog';
import WithFeatureAccess, { Feature } from './WithFeatureAccess';

// #region typings
export interface ToolbarProps {
  onCloseDialog?: () => void;
  onOpenDialog?: () => void;
}
// #endregion


function Toolbar({
  onCloseDialog,
  onOpenDialog,
}: ToolbarProps) {
  const [isGridFormDialogOpen, setGridFormDialogOpen] = useState(false);
  const [isRPGSystemFormDialogOpen, setRPGSystemFormDialogOpen] = useState(false);

  const handleCloseGridFormDialog = () => {
    setGridFormDialogOpen(false);
    onCloseDialog && onCloseDialog();
  };

  const handleCloseRPGSystemFormDialog = () => {
    setRPGSystemFormDialogOpen(false);
    onCloseDialog && onCloseDialog();
  };

  const handleOpenGridFormDialog = () => {
    setGridFormDialogOpen(true);
    onOpenDialog && onOpenDialog();
  };

  const handleOpenRPGSystemFormDialog = () => {
    setRPGSystemFormDialogOpen(true);
    onOpenDialog && onOpenDialog();
  };

  return (
    <Paper
      elevation={3}
      style={{
        alignItems: 'center',
        backgroundColor: '#424242',
        borderRadius: '60px',
        display: 'flex',
        flexDirection: 'column',
        height: '60%',
        justifyContent: 'flex-start',
        paddingTop: '20px',
        position: 'absolute',
        right: '20px',
        top: '20%',
        width: '60px',
      }}
    >
      <IconButton color="primary" aria-label="edit" onClick={handleOpenGridFormDialog}>
        <ImageIcon />
      </IconButton>

      <CanvasFormDialog
        isOpen={isGridFormDialogOpen}
        onClose={handleCloseGridFormDialog}
      />

      <WithFeatureAccess feature={Feature.AI} action='change'>
        <IconButton color="primary" aria-label="rules" onClick={handleOpenRPGSystemFormDialog}>
          <DescriptionIcon />
        </IconButton>

        <RPGSystemFormDialog
          isOpen={isRPGSystemFormDialogOpen}
          onClose={handleCloseRPGSystemFormDialog}
        />
      </WithFeatureAccess>
    </Paper>
  );
}

export default Toolbar;
