import { Avatar, SxProps, Theme } from '@mui/material';

import { useEffect, useState } from 'react';
import { useBattleAssistant } from './BattleAssistantContext';
import { CharacterAlignment, TOKEN_LINE_AGGRESSIVE_COLOR, TOKEN_LINE_FRIENDLY_COLOR } from '../../battleAssistant/BattleAssistant';

export interface CharacterAvatarProps {
  character: {
    alignment: CharacterAlignment,
    avatarImagePath?: string,
    name: string,
  };
  sx?: SxProps<Theme>;
}

function getInitials(name: string): string {
  const words = name.split(/[^\p{L}\d]+/gu).filter(Boolean);
  const initials = words.length > 1
    ? words.map(w => w[0]).join('').slice(0, 2)
    : words[0].slice(0, 2);

  return initials.toUpperCase();
}

export function CharacterAvatar({ character, sx }: CharacterAvatarProps) {
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);

  const { mapFileRepository } = useBattleAssistant();

  useEffect(() => {
    if (!character.avatarImagePath) { return; }

    mapFileRepository.getUrlByPath(character.avatarImagePath).then(url => {
      if (!url) { return; }
      setImageUrl(url);
    });
  }, [character, mapFileRepository]);

  return (
    <Avatar
      alt={character.name}
      src={imageUrl}
      sx={{
        borderColor: character.alignment === CharacterAlignment.Friendly ? TOKEN_LINE_FRIENDLY_COLOR : TOKEN_LINE_AGGRESSIVE_COLOR,
        borderWidth: 2,
        borderStyle: 'solid',
        ...(sx || {}),
      }}
    >
      {getInitials(character.name)}
    </Avatar>
  );
}
