export function difference<T>(A: Array<T> | Set<T>, B: Array<T> | Set<T>): Array<T> {
  const setB = new Set(B);
  return Array.from(A).filter(x => !setB.has(x));
}

export function sortBy<T>(array: T[], key: keyof T): T[] {
  return [...array].sort((a, b) => {
    const valA = a[key];
    const valB = b[key];

    if (valA < valB) return -1;
    if (valA > valB) return 1;
    return 0;
  });
}

export function uniqBy<T>(array: T[], iteratee: (value: T) => any): T[] {
  const seen = new Set();
  return array.filter(item => {
      const key = iteratee(item);
      if (!seen.has(key)) {
          seen.add(key);
          return true;
      }
      return false;
  });
}
