import { collection, doc, DocumentReference, Firestore, getDoc, updateDoc } from 'firebase/firestore';

// #region typings
interface Campaign {
  gameMastersIds: string[];
  ownerId: string;
  rpgSystemId?: string;
}

interface CampaignRepositoryDependencies {
  db: Firestore;
}
// #endregion

class CampaignRepository implements CampaignRepositoryDependencies {
  db: Firestore;

  constructor({
    db,
  }: CampaignRepositoryDependencies) {
    this.db = db;
  }

  async addGameMaster(id: string, gameMasterId: string): Promise<void> {
    const campaign = await this.find(id);
    const gameMastersIds = [
      ...(campaign.gameMastersIds || []),
      gameMasterId,
    ];

    await updateDoc(this.buildRef(id), { gameMastersIds });
  }

  async find(id: string): Promise<Campaign> {
    const snapshot = await getDoc(this.buildRef(id));

    const {
      gameMastersIds,
      ownerId,
      rpgSystemId,
    } = await snapshot.data() || {};

    return {
      gameMastersIds,
      ownerId,
      rpgSystemId,
    }
  }

  async removeGameMaster(id: string, gameMasterId: string): Promise<void> {
    const campaign = await this.find(id);
    const gameMastersIds = campaign.gameMastersIds.filter(id => id !== gameMasterId);

    await updateDoc(
      this.buildRef(id),
      {
        gameMastersIds: Array.from(new Set(gameMastersIds)),
      },
    );
  }

  async update(id: string, data: Partial<Campaign>): Promise<void> {
    await updateDoc(this.buildRef(id), data);
  }

  private buildRef(campaignId: string): DocumentReference {
    return doc(
      collection(this.db, 'campaigns'),
      campaignId,
    )
  }
}

export default CampaignRepository;
