import { Box, Typography, useTheme } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import ImageIcon from '@mui/icons-material/Image';
import React, { useEffect, useMemo, useState } from 'react';

interface CharacterAvatarDropzoneProps {
  defaultImageUrl?: string;
  onChange?: (imageUrl: File) => void;
  style: React.CSSProperties;
}

const CharacterAvatarDropzone: React.FC<CharacterAvatarDropzoneProps> = ({ defaultImageUrl, onChange, style }) => {
  const theme = useTheme();
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [isHovered, setIsHovered] = useState(false);

  const {
    getInputProps,
    getRootProps,
    isDragAccept,
    isDragReject,
    isFocused,
  } = useDropzone({
    accept: {
      'image/*': ['.png', '.jpeg', '.jpg'],
    },
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      const imageUrl = URL.createObjectURL(file);
      setImageUrl(imageUrl);
      onChange && onChange(file);
    }
  });

  useEffect(() => {
    if (!defaultImageUrl) { return; }
    setImageUrl(defaultImageUrl);
  }, [defaultImageUrl]);

  const baseStyle: React.CSSProperties = useMemo(() => ({
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.divider,
    borderRadius: theme.shape.borderRadius,
    borderStyle: 'dashed',
    borderWidth: 2,
    boxSizing: 'border-box',
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    outline: 'none',
    padding: theme.spacing(2.5),
    position: 'relative',
    textAlign: 'center',
    transition: theme.transitions.create('border', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeIn,
    }),
  }), [theme]);

  const focusedStyle: React.CSSProperties = useMemo(() => ({
    borderColor: theme.palette.primary.main,
  }), [theme]);

  const acceptStyle: React.CSSProperties = useMemo(() => ({
    borderColor: theme.palette.success.main,
  }), [theme]);

  const rejectStyle: React.CSSProperties = useMemo(() => ({
    borderColor: theme.palette.error.main,
  }), [theme]);

  const dropzoneStyle: React.CSSProperties = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    acceptStyle,
    baseStyle,
    focusedStyle,
    isDragAccept,
    isDragReject,
    isFocused,
    rejectStyle,
  ]);

  const dropzoneStyleWithImage: React.CSSProperties = useMemo(() => ({
    ...dropzoneStyle,
    backgroundSize: 'cover',
    backgroundImage: imageUrl ? `url(${imageUrl})` : undefined,
    borderWidth: imageUrl ? 0 : 2,
  }), [dropzoneStyle, imageUrl]);

  return (
    <Box
      {...getRootProps({
        style: {
          ...dropzoneStyleWithImage,
          ...style,
        },
      })}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <input {...getInputProps()} />

      {!imageUrl && (
        <Typography variant="body2">Character's avatar</Typography>
      )}

      {isHovered && (
        <div style={{
          backgroundColor: 'rgba(0, 0, 0, 0.35)',
          bottom: 0,
          color: 'white',
          left: 0,
          position: 'absolute',
          right: 0,
          top: 0,
        }}>
          <div style={{
            left: '50%',
            marginLeft: '-12px',
            marginTop: '-12px',
            position: 'absolute',
            top: '50%',
          }}>
            <ImageIcon />
          </div>
        </div>
      )}
    </Box>
  );
}

export default CharacterAvatarDropzone;
