import { createContext, useContext, ReactNode, useState, useEffect } from 'react';

import { Character, CombatAttribute, Player, RpgSystem } from '../../battleAssistant/BattleAssistant';
import { db, firestore, storage } from '../../config/firebaseConfig';
import { GridType } from '../../battleAssistant/Canvas';
import { useParams } from 'react-router-dom';
import CampaignRepository from '../../repositories/CampaignRepository';
import FirebaseRealtimeDatabaseAdapter from '../../repositories/databaseAdapters/FirebaseRealtimeDatabaseAdapter';
import FirebaseStorageRepositoryAdapter from '../../repositories/storageAdapters/FirebaseStorageRepositoryAdapter';
import MapFileRepository from '../../repositories/MapFileRepository';
import MapRepository from '../../repositories/MapRepository';
import RpgSystemRepository from '../../repositories/RpgSystemRepository';

export interface BattleAssistantState {
  backgroundImage: File | null;
  backgroundImageSize?: [number, number] | null;
  backgroundImageUrl?: string | null;
  campaignId: string;
  campaignRepository: CampaignRepository;
  characterAiAction: Character | null;
  characters: Character[];
  charactersImagesUrls: Record<string, string>;
  combatAttributes: CombatAttribute[] | null;
  createdCharacter: Character | null;
  deletedCharacter: Character | null;
  gridOffsetX?: string;
  gridOffsetY?: string;
  gridType: GridType | null;
  isCharacterFormDialogOpen: boolean;
  localPlayer: Player | null;
  mapChanged: boolean;
  mapFileRepository: MapFileRepository;
  mapHeight: string;
  mapId: string;
  mapRepository: MapRepository;
  mapWidth: string;
  newCharacterImage: File | null;
  players: Player[];
  rotateCharacter: Character | null;
  rpgSystem: RpgSystem | null;
  rpgSystemRepository: RpgSystemRepository;
  selectedCharacter: Character | null;
  tileSize: number;
  updatedCharacter: Character | null;
  updatedPlayer: Player | null;
}

interface BattleAssistantContextType extends BattleAssistantState {
  setBackgroundImage: (file: File | null) => void;
  setBackgroundImageSize: (size: [number, number] | null) => void;
  setBackgroundImageUrl: (value: string | null) => void;
  setCampaignId: (value: string) => void;
  setCampaignRepository: (value: CampaignRepository) => void;
  setCharacterAiAction: (value: Character | null) => void;
  setCharacters: (value: Character[]) => void;
  setCharactersImagesUrls: (value: Record<string, string>) => void;
  setCombatAttributes: (value: CombatAttribute[]) => void;
  setCreatedCharacter: (value: Character | null) => void;
  setDeletedCharacter: (value: Character | null) => void;
  setGridOffsetX: (offsetX: string) => void;
  setGridOffsetY: (offsetY: string) => void;
  setGridType: (type: GridType | null) => void;
  setIsCharacterFormDialogOpen: (value: boolean) => void;
  setLocalPlayer: (value: Player) => void;
  setMapChanged: (value: boolean) => void;
  setMapFileRepository: (value: MapFileRepository) => void;
  setMapHeight: (value: string) => void;
  setMapId: (value: string) => void;
  setMapRepository: (value: MapRepository) => void;
  setMapWidth: (value: string) => void;
  setNewCharacterImage: (value: File | null) => void;
  setPlayers: (value: Player[]) => void;
  setRotateCharacter: (value: Character | null) => void;
  setRpgSystem: (value: RpgSystem | null) => void;
  setRpgSystemRepository: (value: RpgSystemRepository) => void;
  setSelectedCharacter: (value: Character | null) => void;
  setTileSize: (size: number) => void;
  setUpdatedCharacter: (value: Character | null) => void;
  setUpdatedPlayer: (value: Player | null) => void;
}

export const BattleAssistantContext = createContext<BattleAssistantContextType | undefined>(undefined);

interface BattleAssistantProviderProps {
  children: ReactNode;
}

export function BattleAssistantProvider({ children }: BattleAssistantProviderProps) {
  const { campaignId, mapId } = useParams();

  const campaignRepo = new CampaignRepository(({ db: firestore }))
  const rpgSystemRepo = new RpgSystemRepository(({ db: firestore }));

  const mapFileRepo = new MapFileRepository({
    adapter: new FirebaseStorageRepositoryAdapter({ storage }),
    campaignId: campaignId as string,
    mapId: mapId as string,
  })

  const mapRepo = new MapRepository({
    adapter: new FirebaseRealtimeDatabaseAdapter({ db }),
    campaignId: campaignId as string,
  });

  const [backgroundImage, setBackgroundImage] = useState<BattleAssistantState['backgroundImage']>(null);
  const [backgroundImageSize, setBackgroundImageSize] = useState<BattleAssistantState['backgroundImageSize']>(null);
  const [backgroundImageUrl, setBackgroundImageUrl] = useState<BattleAssistantState['backgroundImageUrl']>(null);
  const [campaignIdValue, setCampaignId] = useState(campaignId as string);
  const [campaignRepository, setCampaignRepository] = useState<BattleAssistantState['campaignRepository']>(campaignRepo);
  const [characterAiAction, setCharacterAiAction] = useState<BattleAssistantState['characterAiAction']>(null);
  const [characters, setCharacters] = useState<BattleAssistantState['characters']>([]);
  const [charactersImagesUrls, setCharactersImagesUrls] = useState<BattleAssistantState['charactersImagesUrls']>({});
  const [combatAttributes, setCombatAttributes] = useState<BattleAssistantState['combatAttributes']>(null);
  const [createdCharacter, setCreatedCharacter] = useState<BattleAssistantState['createdCharacter']>(null);
  const [deletedCharacter, setDeletedCharacter] = useState<BattleAssistantState['deletedCharacter']>(null);
  const [gridOffsetX, setGridOffsetX] = useState('0');
  const [gridOffsetY, setGridOffsetY] = useState('0');
  const [gridType, setGridType] = useState<BattleAssistantState['gridType']>(null);
  const [isCharacterFormDialogOpen, setIsCharacterFormDialogOpen] = useState<BattleAssistantState['isCharacterFormDialogOpen']>(false);
  const [localPlayer, setLocalPlayer] = useState<BattleAssistantState['localPlayer']>(null);
  const [mapChanged, setMapChanged] = useState(false);
  const [mapFileRepository, setMapFileRepository] = useState<BattleAssistantState['mapFileRepository']>(mapFileRepo);
  const [mapHeight, setMapHeight] = useState<BattleAssistantState['mapHeight']>('');
  const [mapIdValue, setMapId] = useState(mapId as string);
  const [mapRepository, setMapRepository] = useState<BattleAssistantState['mapRepository']>(mapRepo);
  const [mapWidth, setMapWidth] = useState<BattleAssistantState['mapWidth']>('');
  const [newCharacterImage, setNewCharacterImage] = useState<BattleAssistantState['newCharacterImage']>(null);
  const [players, setPlayers] = useState<BattleAssistantState['players']>([]);
  const [rotateCharacter, setRotateCharacter] = useState<BattleAssistantState['rotateCharacter']>(null);
  const [rpgSystem, setRpgSystem] = useState<BattleAssistantState['rpgSystem']>(null);
  const [rpgSystemRepository, setRpgSystemRepository] = useState<BattleAssistantState['rpgSystemRepository']>(rpgSystemRepo);
  const [selectedCharacter, setSelectedCharacter] = useState<BattleAssistantState['selectedCharacter']>(null);
  const [tileSize, setTileSize] = useState(50);
  const [updatedCharacter, setUpdatedCharacter] = useState<BattleAssistantState['updatedCharacter']>(null);
  const [updatedPlayer, setUpdatedPlayer] = useState<BattleAssistantState['updatedPlayer']>(null);

  useEffect(() => {
    setCampaignId(campaignId as string);
    setMapId(mapId as string);
  }, [campaignId, mapId]);

  return (
    <BattleAssistantContext.Provider
      value={{
        backgroundImage,
        backgroundImageSize,
        backgroundImageUrl,
        campaignId: campaignIdValue,
        campaignRepository,
        characterAiAction,
        characters,
        charactersImagesUrls,
        combatAttributes,
        createdCharacter,
        deletedCharacter,
        gridOffsetX,
        gridOffsetY,
        gridType,
        isCharacterFormDialogOpen,
        localPlayer,
        mapChanged,
        mapFileRepository,
        mapHeight,
        mapId: mapIdValue,
        mapRepository,
        mapWidth,
        newCharacterImage,
        players,
        rotateCharacter,
        rpgSystem,
        rpgSystemRepository,
        selectedCharacter,
        setBackgroundImage,
        setBackgroundImageSize,
        setBackgroundImageUrl,
        setCampaignId,
        setCampaignRepository,
        setCharacterAiAction,
        setCharacters,
        setCharactersImagesUrls,
        setCombatAttributes,
        setCreatedCharacter,
        setDeletedCharacter,
        setGridOffsetX,
        setGridOffsetY,
        setGridType,
        setIsCharacterFormDialogOpen,
        setLocalPlayer,
        setMapChanged,
        setMapFileRepository,
        setMapHeight,
        setMapId,
        setMapRepository,
        setMapWidth,
        setNewCharacterImage,
        setPlayers,
        setRotateCharacter,
        setRpgSystem,
        setRpgSystemRepository,
        setSelectedCharacter,
        setTileSize,
        setUpdatedCharacter,
        setUpdatedPlayer,
        tileSize,
        updatedCharacter,
        updatedPlayer,
      }}
    >
      {children}
    </BattleAssistantContext.Provider>
  );
}

export function useBattleAssistant() {
  const context = useContext(BattleAssistantContext);
  if (context === undefined) {
    throw new Error('useBattleAssistant must be used within a BattleAssistantProvider');
  }
  return context;
}
