import { Graphics } from 'pixi.js';
import { GridType } from './Canvas';
import BaseGrid from './BaseGrid';

class HexagonGrid extends BaseGrid {
  private drawId = '';

  get cols(): number {
    return Math.floor(this.width / (this.tileEdgeSize * 1.5)) + 2;
  }

  get rows(): number {
    return Math.floor(this.height / (this.tileEdgeSize * Math.sqrt(3))) + 2;
  }

  get type(): GridType {
    return GridType.Hexagon;
  }

  get tileEdgeSize(): number {
    return this.tileSize / Math.sqrt(3);
  }

  changeOffset(offset: [number, number]): void {
    this.offsetX = offset[0];
    this.offsetY = offset[1];
    this.draw(true);
  }

  destroy(): void {
    this.container.removeChild(this.gridContainer);
    this.gridContainer.destroy({ children: true, texture: true, baseTexture: true });
  }

  draw(force = false): void {
    if (!force && this.drawId === this.buildDrawId()) { return; }

    this.gridContainer.removeChildren();

    const graphic = new Graphics();
    graphic.clear();
    graphic.beginFill(0xFFFFFF, 0.001);
    const effectiveLineWidth = 1 / Math.max(this.gridContainer.scale.x, this.gridContainer.scale.y);
    graphic.lineStyle(effectiveLineWidth, this.lineColor, this.lineOpacity);

    for (let i = 0; i < this.rows; i++) {
      for (let j = 0; j < this.cols; j++) {
        const tileOffsetX = this.offsetX + j * this.tileEdgeSize * 1.5;
        const tileOffsetY = this.offsetY + (i * this.tileEdgeSize * Math.sqrt(3)) + ((j % 2) * 0.5 * this.tileEdgeSize * Math.sqrt(3));
        this.drawHexagon(graphic, tileOffsetX, tileOffsetY, false);  // Modifique para não chamar clear e endFill a cada vez
      }
    }

    graphic.endFill();
    this.gridContainer.addChild(graphic);

    this.drawId = this.buildDrawId();
  }

  getCenterFromTilePosition(position: [number, number, number]): [number, number] {
    const [rx, _ry, rz] = position;
    const q = rx;
    const r = rz;

    const x = this.tileEdgeSize * 3 / 2 * q;
    const y = this.tileEdgeSize * Math.sqrt(3) * (r + q / 2);

    return [x, y];
  }

  getPositionFromPoint(x: number, y: number): [number, number, number] {
    // Primeiro, calculamos as coordenadas axiais
    const q = (x * 2 / 3) / this.tileEdgeSize;
    const r = (-x / 3 + Math.sqrt(3) / 3 * y) / this.tileEdgeSize;

    // Convertendo coordenadas axiais para cúbicas
    const xCube = q;
    const zCube = r;
    const yCube = -xCube - zCube;

    // Arredondamos para obter os valores mais próximos
    let rx = Math.round(xCube);
    let ry = Math.round(yCube);
    let rz = Math.round(zCube);

    const xDiff = Math.abs(rx - xCube);
    const yDiff = Math.abs(ry - yCube);
    const zDiff = Math.abs(rz - zCube);

    if (xDiff > yDiff && xDiff > zDiff) {
      rx = -ry - rz;
    } else if (yDiff > zDiff) {
      ry = -rx - rz;
    } else {
      rz = -rx - ry;
    }

    return [rx, ry, rz];
  }

  private buildDrawId(): string {
    return `${this.width}x${this.height}-${this.tileSize}`;
  }

  private drawHexagon(graphic: Graphics, x: number, y: number, clear: boolean = true): void {
    const h = Math.sqrt(3) / 2 * this.tileEdgeSize;

    if (clear) {
      graphic.clear();
    }

    graphic.moveTo(x + this.tileEdgeSize, y);
    graphic.lineTo(x + this.tileEdgeSize * 0.5, y - h);
    graphic.lineTo(x - this.tileEdgeSize * 0.5, y - h);
    graphic.lineTo(x - this.tileEdgeSize, y);
    graphic.lineTo(x - this.tileEdgeSize * 0.5, y + h);
    graphic.lineTo(x + this.tileEdgeSize * 0.5, y + h);
    graphic.closePath();
  }

  private drawSingleHexagon(graphic: Graphics, x: number, y: number): void {
    const h = Math.sqrt(3) / 2 * this.tileEdgeSize;
    const effectiveLineWidth = 1 / Math.max(this.gridContainer.scale.x, this.gridContainer.scale.y);

    graphic.clear();
    graphic.beginFill(0xFFFFFF, 0.001);
    graphic.lineStyle(effectiveLineWidth, this.lineColor, this.lineOpacity);
    graphic.moveTo(x + this.tileEdgeSize, y);
    graphic.lineTo(x + this.tileEdgeSize * 0.5, y - h);
    graphic.lineTo(x - this.tileEdgeSize * 0.5, y - h);
    graphic.lineTo(x - this.tileEdgeSize, y);
    graphic.lineTo(x - this.tileEdgeSize * 0.5, y + h);
    graphic.lineTo(x + this.tileEdgeSize * 0.5, y + h);
    graphic.closePath();
    graphic.endFill();
  }
}

export default HexagonGrid;
