import {
  Database,
  DatabaseReference,
  get,
  onChildAdded,
  onChildRemoved,
  onValue,
  ref,
  remove,
  set,
} from 'firebase/database';

import SignalingService, { CandidateSignalHandler, InitSignalHandler, PeerData, SdpSignal, Unsubscribe } from './SignalingService';

class RealtimeDatabaseSignalingService extends SignalingService {
  db: Database;

  private beforeConnectionPeerIds?: Set<string>;

  constructor({
    db,
    sessionId,
  }: {
    db: Database;
    sessionId: string;
  }) {
    super({ sessionId });
    this.db = db;
  }

  async listPeers(): Promise<Array<[string, PeerData]>> {
    const snapshot = await get(this.buildBaseRef());
    const peers: Array<[string, PeerData]> = [];

    snapshot.forEach((child) => {
      if (!child.ref.key) { return; }
      peers.push([child.ref.key, child.val().data])
    });

    return peers;
  }

  onAnswerSignal(originPeerId: string, destinationPeerId: string, handler: InitSignalHandler): Unsubscribe {
    return this.onSdpSignal(originPeerId, destinationPeerId, 'answer', handler);
  }

  onIceCandidateSignal(originPeerId: string, destinationPeerId: string, handler: CandidateSignalHandler): Unsubscribe {
    const reference = ref(
      this.db,
      this.buildIceCandidateRefPath(originPeerId, destinationPeerId),
    );

    return onChildAdded(reference, async (child) => {
      const value = child.val();
      if (!value) { return; }

      const signal = JSON.parse(value);

      try {
        await handler(signal);
      } finally {
        await remove(child.ref);
      }
    })
  }

  onOfferSignal(originPeerId: string, destinationPeerId: string, handler: InitSignalHandler): Unsubscribe {
    return this.onSdpSignal(originPeerId, destinationPeerId, 'offer', handler);
  }

  async onPeerConnect(handler: (id: string, data: PeerData) => void): Promise<Unsubscribe> {
    const beforeIds = await this.fetchBeforeConnectionPeerIds();

    return onChildAdded(
      ref(this.db, this.buildBaseRefPath),
      (child) => {
        // Ignora caso o peer seja anterior a conexão atual
        if (beforeIds.has(child.key as string)) { return; }

        handler(child.key as string, child.val().data);
      },
    )
  }

  onPeerDisconnect(handler: (id: string) => void): Unsubscribe {
    return onChildRemoved(
      ref(this.db, this.buildBaseRefPath),
      (child) => { handler(child.key as string) },
    )
  }

  onPeerDataUpdate(peerId: string, handler: (data: PeerData) => void): Unsubscribe {
    return onValue(
      ref(this.db, this.buildBaseRefPath + `/${peerId}/data`),
      (snapshot) => { handler(snapshot.val()) }
    );
  }

  async removePeer(peerId: string): Promise<void> {
    return remove(
      ref(this.db, this.buildBaseRefPath + `/${peerId}`)
    )
  }

  async sendConnection(originPeerId: string, data?: PeerData): Promise<void> {
    await set(
      ref(this.db, this.buildBaseRefPath + `/${originPeerId}`),
      {
        connectedAt: Date.now(),
        data: data || {},
      }
    )
  }

  async sendIceCandidateSignal(originPeerId: string, destinationPeerId: string, signal: RTCIceCandidate): Promise<void> {
    await set(
      ref(this.db, this.buildIceCandidateRefPath(originPeerId, destinationPeerId) + '/' + signal.candidate.replace(/\W/g, '')),
      JSON.stringify(signal),
    );
  }

  async sendSdpSignal(originPeerId: string, destinationPeerId: string, signalType: SdpSignal, signal: RTCSessionDescriptionInit): Promise<void> {
    await set(
      this.buildSdpRef(originPeerId, destinationPeerId, signalType),
      JSON.stringify(signal),
    );
  }

  async updatePeerData(peerId: string, data: PeerData): Promise<void> {
    await set(
      ref(this.db, this.buildBaseRefPath + `/${peerId}/data`),
      data,
    )
  }

  // #region private
  private get buildBaseRefPath(): string {
    return `${this.sessionId}/connectedUsers`
  }

  private buildBaseRef(): DatabaseReference {
    return ref(this.db, this.buildBaseRefPath);
  }

  private buildIceCandidateRefPath(destinationPeerId: string, originPeerId: string): string {
    return this.buildBaseRefPath + `/${destinationPeerId}/signals/${originPeerId}/candidates`;
  }

  private buildSdpRef(originPeerId: string, destinationPeerId: string, signalType: SdpSignal): DatabaseReference {
    return ref(this.db, this.buildBaseRefPath + `/${destinationPeerId}/signals/${originPeerId}/${signalType}`);
  }

  private async fetchBeforeConnectionPeerIds(): Promise<Set<string>> {
    if (this.beforeConnectionPeerIds) { return this.beforeConnectionPeerIds; }

    this.beforeConnectionPeerIds = new Set();
    const snapshot = await get(ref(this.db, this.buildBaseRefPath))

    snapshot.forEach(childSnapshot => {
      this.beforeConnectionPeerIds?.add(childSnapshot.key as string);
    });

    return this.beforeConnectionPeerIds;
  }

  private onSdpSignal(
    originPeerId: string,
    destinationPeerId: string,
    signalType: 'answer' | 'offer',
    handler: InitSignalHandler,
  ): Unsubscribe {
    const reference = this.buildSdpRef(originPeerId, destinationPeerId, signalType);

    return onValue(reference, async (child) => {
      const value = child.val();
      if (!value) { return; }
      const signal = JSON.parse(value);

      try {
        await handler(signal);
      } finally {
        await remove(child.ref);
      }
    })
  }
  // #endregion
}

export default RealtimeDatabaseSignalingService;
