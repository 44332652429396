import DataChannel from './DataChannel';
import SignalingService, { PeerData, SdpSignal, Unsubscribe } from './SignalingService';

// #region typings
export interface PeerSessionDependencies {
  debug?: boolean;
  iceServers?: any;
  localId: string;
  peerId: string;
  sessionId: string;
  signalingService: SignalingService;
}
// #endregion

export enum PeerSessionStatus {
  Closed = 'closed',
  Connected = 'connected',
  Connecting = 'connection',
  Pending = 'pending',
}

class PeerSession implements PeerSessionDependencies {
  connectionTimeout = 30 * 1000;
  debug: boolean;
  iceServers?: RTCIceServer[];
  isOfferer?: boolean;
  localId: string;
  peerId: string;
  sessionId: string;
  signalingService: SignalingService;
  status = PeerSessionStatus.Pending;

  private _peerConnection: RTCPeerConnection | undefined;
  private connectionEstablished = false;
  private connectionHandler?: () => void;
  private dataChannels: Map<string, DataChannel> = new Map();
  private dataUpdateHandlers: Array<(data: PeerData) => void> = [];
  private disconnectHandlers: (() => void)[] = [];
  private failConnectionHandler?: () => void;
  private peerAnswered = false;
  private unsubscribers: Map<'answer' | 'data' | 'offer' | 'candidate', Unsubscribe> = new Map();

  constructor({
    debug = false,
    iceServers,
    localId,
    peerId,
    sessionId,
    signalingService,
  }: PeerSessionDependencies) {
    this.debug = debug;
    this.iceServers = iceServers;
    this.localId = localId;
    this.peerId = peerId;
    this.sessionId = sessionId;
    this.signalingService = signalingService;
  }

  // #region callbacks
  private afterAnswer = () => {
    const answerUnsubscribe = this.unsubscribers.get('answer');
    if (answerUnsubscribe) {
      answerUnsubscribe();
      this.unsubscribers.delete('answer');
    }

    const candidateUnsubscribe = this.signalingService.onIceCandidateSignal(this.peerId, this.localId, this.candidateHandler);
    this.unsubscribers.set('candidate', candidateUnsubscribe);
  }

  private afterConnect = () => {
    this.status = PeerSessionStatus.Connected;
    this.connectionEstablished = true;
    this.connectionHandler && this.connectionHandler();

    // On peer data update
    const dataUpdateUnsubscribe = this.signalingService.onPeerDataUpdate(this.peerId, (data) => {
      this.dataUpdateHandlers.forEach(h => h(data));
    });
    this.unsubscribers.set('data', dataUpdateUnsubscribe);
  }

  private answerHandler = async (answer: RTCSessionDescriptionInit): Promise<void> => {
    await this.peerConnection.setRemoteDescription(answer);
    this.log('Answer set as remote description: ' + JSON.stringify(answer));
    this.afterAnswer();
  }

  private afterNegotiationEnded = () => {
    const unsubscriber = this.unsubscribers.get('candidate');
    unsubscriber && unsubscriber();
    this.unsubscribers.delete('candidate');
  }

  private afterOffer = () => {
    const offerUnsubscribe = this.unsubscribers.get('offer');
    if (offerUnsubscribe) {
      offerUnsubscribe();
      this.unsubscribers.delete('offer');
    }

    const candidateUnsubscribe = this.signalingService.onIceCandidateSignal(this.peerId, this.localId, this.candidateHandler);
    this.unsubscribers.set('candidate', candidateUnsubscribe);
  }

  private candidateHandler = async (signal: RTCIceCandidate): Promise<void> => {
    this.peerConnection.addIceCandidate(signal);
  }

  private offerHandler = async (offer: RTCSessionDescriptionInit) => {
    await this.peerConnection.setRemoteDescription(offer);
    this.log('Offer set as remote description: ' + JSON.stringify(offer));

    const answer = await this.peerConnection.createAnswer();
    this.peerConnection.setLocalDescription(answer);
    this.log('Answer created with local description: ' + JSON.stringify(answer));

    await this.sendSdpSignal('answer', answer);

    this.afterOffer();
  }
  // #endregion

  async close(): Promise<void> {
    this.disconnectHandlers.forEach(handler => handler());
    Array.from(this.unsubscribers.values()).forEach(unsubscribe => unsubscribe());
    this.dataChannels.forEach(c => c.close());
    this._peerConnection?.close();
    this._peerConnection = undefined;
    this.status = PeerSessionStatus.Closed;
  }

  async connect(): Promise<void> {
    if (this.isConnected()) { return; }

    if (!this.isPending()) {
      console.warn('PeerSession is not pending');
      return;
    }
    this.initConnection(true);

    return new Promise(async (resolve, reject) => {
      this.connectionHandler = resolve;
      this.failConnectionHandler = reject;

      const offer = await this.peerConnection.createOffer();
      await this.peerConnection.setLocalDescription(offer);
      this.log('Offer created with local description: ' + JSON.stringify(offer));

      await this.sendSdpSignal('offer', offer);

      // Configura timeout
      const timeoutId = setTimeout(() => {
        const { connectionState } = this.peerConnection;

        if (connectionState === 'connected') { return; }

        if (!this.peerAnswered) {
          // Remove o peer caso ele não tenha respondido
          this.signalingService.removePeer(this.peerId);
        }

        this.log(`Connection timeout (${this.connectionTimeout}ms)`);
        this.close();
      }, this.connectionTimeout);

      const unsubscribe = this.signalingService.onAnswerSignal(this.peerId, this.localId, (answer) => {
        // Limpa o timeout caso a conexão tenha sido estabelecida
        clearTimeout(timeoutId);
        this.peerAnswered = true;
        return this.answerHandler(answer);
      });
      this.unsubscribers.set('answer', unsubscribe);
    });
  }

  async disconnect(): Promise<void> {
    this.peerConnection.close();
  }

  createChannel(name: string): DataChannel {
    const dataChannel = new DataChannel({ debug: this.debug, name, peerId: this.peerId });
    this.dataChannels.set(name, dataChannel);
    this.addDataChannelsToPeerConnection(name);

    return dataChannel;
  }

  isClosed(): boolean {
    return this.status === PeerSessionStatus.Closed;
  }

  isConnected(): boolean {
    return this.status === PeerSessionStatus.Connected;
  }

  isPending(): boolean {
    return this.status === PeerSessionStatus.Pending;
  }

  async onConnect(handler: () => unknown, failHandler?: () => unknown): Promise<void> {
    if (!this.isPending()) {
      console.warn('PeerSession is not pending');
      return;
    }
    this.initConnection(false);
    this.connectionHandler = handler;
    this.failConnectionHandler = failHandler;

    const unsubscribe = this.signalingService.onOfferSignal(this.peerId, this.localId, this.offerHandler);
    this.unsubscribers.set('offer', unsubscribe);
  }

  onDisconnect(handler: () => unknown): void {
    this.disconnectHandlers.push(handler);
  }

  onDataUpdate(handler: (data: PeerData) => unknown): void {
    this.dataUpdateHandlers.push(handler);
  }

  // #region private
  private get peerConnection(): RTCPeerConnection {
    if (this._peerConnection) { return this._peerConnection; }

    const peerConnection = new RTCPeerConnection({ iceServers: this.iceServers });
    this._peerConnection = peerConnection;

    // Add DataChannels
    Array.from(this.dataChannels.keys()).forEach((name) => {
      this.addDataChannelsToPeerConnection(name);
    });

    peerConnection.onicecandidate = async (event) => {
      this.log('ICE candidate: ' + JSON.stringify(event?.candidate));
      if (!event.candidate) { return; }
      await this.sendIceCandidateSignal(event.candidate);
    };

    peerConnection.onicecandidateerror = (event: any) => {
      console.error(`ICE Candidate Error: (${event.errorCode}) ${event.errorText}`, event);
    };

    peerConnection.onicegatheringstatechange = () => {
      this.log('ICE Gathering state change: ' + peerConnection.iceGatheringState);
    };

    peerConnection.oniceconnectionstatechange = () => {
      this.log('ICE Connection State Change: ' + peerConnection.iceConnectionState);
    };

    peerConnection.onconnectionstatechange = () => {
      const { connectionState } = peerConnection;
      this.log("Connection State: " + connectionState);

      if (connectionState === 'connected') {
        this.log('General connection successfully established!');
        this.afterNegotiationEnded();
        this.afterConnect();
      } else if (connectionState === 'disconnected' || connectionState === 'failed') {
        this.log('Peer disconnected');
        this.afterNegotiationEnded();

        // Caso a conexão nunca tenha sido estabelicida chamar o handler de falha de conexão
        if (!this.connectionEstablished) {
          this.failConnectionHandler && this.failConnectionHandler();
        }

        this.close();
      }
    };

    peerConnection.onnegotiationneeded = () => {
      this.log('Negotiating needed');
    };

    return peerConnection;
  }

  private addDataChannelsToPeerConnection(name: string): void {
    if (!this._peerConnection) { return; }

    if (this.isOfferer) {
      const dataChannel = this.dataChannels.get(name);
      if (dataChannel) {
        dataChannel.rtcDataChannel = this.peerConnection.createDataChannel(name);
      }
    } else {
      this.peerConnection.ondatachannel = (event) => {
        const { channel } = event;
        if (!channel) { return; }

        const dataChannel = this.dataChannels.get(channel.label);
        if (dataChannel) { dataChannel.rtcDataChannel = channel; }
      };
    }
  }

  private initConnection(isOfferer: boolean): void {
    this.isOfferer = isOfferer;
    this.status = PeerSessionStatus.Connecting;
  }

  private log(message: string, ...optionalParams: any[]): void {
    if (!this.debug) { return; }
    console.log(`[${this.peerId}] ${message}`, ...optionalParams);
  }

  private async sendIceCandidateSignal(signal: RTCIceCandidate): Promise<void> {
    await this.signalingService.sendIceCandidateSignal(this.localId, this.peerId, signal);
  }

  private async sendSdpSignal(signalType: SdpSignal, signal: RTCSessionDescriptionInit): Promise<void> {
    await this.signalingService.sendSdpSignal(this.localId, this.peerId, signalType, signal);
  }
  // #endregion
}

export default PeerSession;
