import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Input,
  Slide,
  Slider,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

import { GridType } from "../../../battleAssistant/Canvas";
import { TransitionProps } from "@mui/material/transitions";
import { useBattleAssistant } from "../BattleAssistantContext";

export interface CanvasFormDialogProps {
  isOpen: boolean;
  onClose?: () => void;
}

const MIN_TILE_SIZE = 20;
const MAX_TILE_SIZE = 100;

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CanvasFormDialog({ isOpen, onClose }: CanvasFormDialogProps) {
  const {
    backgroundImageSize,
    backgroundImageUrl,
    gridOffsetX,
    gridOffsetY,
    gridType,
    mapHeight,
    mapWidth,
    setBackgroundImage,
    setBackgroundImageSize,
    setBackgroundImageUrl,
    setGridOffsetX,
    setGridOffsetY,
    setGridType,
    setMapChanged,
    setMapHeight,
    setMapWidth,
    setTileSize,
    tileSize,
  } = useBattleAssistant();

  const [backgroundImageWidth, setBackgroundImageWidth] = useState<string>("");
  const [backgroundImageHeight, setBackgroundImageHeight] =
    useState<string>("");

  useEffect(() => {
    if (backgroundImageSize) {
      setBackgroundImageHeight(String(backgroundImageSize[1]));
      setBackgroundImageWidth(String(backgroundImageSize[0]));
    } else {
      setBackgroundImageHeight("");
      setBackgroundImageWidth("");
    }
  }, [backgroundImageSize]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [".png", ".jpeg", ".jpg"],
    },
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setBackgroundImage(acceptedFiles[0]);

        // Criar URL temporária para o arquivo
        const objectURL = URL.createObjectURL(acceptedFiles[0]);
        setBackgroundImageUrl(objectURL);

        // Criar nova instância de Image
        const img = new Image();
        img.src = objectURL;

        img.onload = () => {
          // Atualizar o tamanho do mapa com as dimensões da imagem
          setBackgroundImageSize([img.width, img.height]);
          setBackgroundImageHeight(String(img.height));
          setBackgroundImageWidth(String(img.width));
          setMapHeight(String(img.height));
          setMapWidth(String(img.width));
        };
      }
    },
  });

  // #region handlers
  const handleBackgroundImageClear = () => {
    setBackgroundImageHeight("");
    setGridOffsetX("");
    setGridOffsetY("");
    setBackgroundImageUrl(null);
    setBackgroundImageWidth("");
  };

  const handleBackgroundImageHeightBlur = () => {
    if (backgroundImageSize) {
      const [width, height] = backgroundImageSize;
      const aspectRatio = width / height;
      const newWidth = Number(backgroundImageHeight) * aspectRatio;
      setBackgroundImageWidth(String(newWidth.toFixed(2)));
      setBackgroundImageSize([newWidth, Number(backgroundImageHeight)]);
    }
  };

  const handleBackgroundImageHeightChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newHeight = Math.max(0, Number(e.target.value));
    setBackgroundImageHeight(String(newHeight));
  };

  const handleGridOffsetXChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) { return setGridOffsetX(''); }
    const newValue = Math.min(tileSize, Math.max(-tileSize, Number(e.target.value)));
    setGridOffsetX(String(newValue));
  };

  const handleGridOffsetYChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) { return setGridOffsetX(''); }
    const newValue = Math.min(tileSize, Math.max(-tileSize, Number(e.target.value)));
    setGridOffsetY(String(newValue));
  };

  const handleBackgroundImageWidthBlur = () => {
    if (!backgroundImageSize) { return; }

    const [width, height] = backgroundImageSize;
    const aspectRatio = height / width;
    const newHeight = Number(backgroundImageWidth) * aspectRatio;
    setBackgroundImageHeight(String(newHeight.toFixed(2)));
    setBackgroundImageSize([Number(backgroundImageWidth), newHeight]);
  };

  const handleBackgroundImageWidthChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newWidth = Math.max(0, Number(e.target.value));
    setBackgroundImageWidth(String(newWidth));
  };

  const handleGridTypeChange = (
    _event: React.MouseEvent<HTMLElement>,
    newGridType: GridType
  ) => {
    if (newGridType) {
      setGridType(newGridType);
    }
  };

  const handleMapHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newHeight = Math.max(0, Number(e.target.value));
    setMapHeight(String(newHeight));
  };

  const handleMapWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newWidth = Math.max(0, Number(e.target.value));
    setMapWidth(String(newWidth));
  };

  const handleTileSizeChange = (value: number | number[] | string) => {
    if (typeof value === 'string') { value = Number(value) || MIN_TILE_SIZE; }
    if (value instanceof Array) { value = value[0]; }
    if (value < MIN_TILE_SIZE) { value = MIN_TILE_SIZE; }
    if (value > MAX_TILE_SIZE) { value = MAX_TILE_SIZE; }

    setTileSize(value);
  };

  const handleFinish = () => {
    setMapChanged(true);
    onClose && onClose();
  };
  // #endregion

  return (
    <Dialog
      fullWidth={true}
      open={isOpen}
      onClose={handleFinish}
      TransitionComponent={Transition}
    >
      <DialogTitle>Change Map</DialogTitle>

      <DialogContent>
        <Box
          sx={{
            "& > legend": { mt: 2 },
          }}
        >
          <Typography component="legend" sx={{ mb: 1 }}>
            Grid type
          </Typography>

          <Box mb={2}>
            <ToggleButtonGroup
              aria-label="grid type"
              exclusive
              onChange={handleGridTypeChange}
              value={gridType}
            >
              <ToggleButton value={GridType.Empty} aria-label="none">
                None
              </ToggleButton>
              <ToggleButton value={GridType.Square} aria-label="square">
                Square
              </ToggleButton>
              <ToggleButton value={GridType.Hexagon} aria-label="hexagon">
                Hexagon
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          <Box>
            <Typography component="legend" sx={{ mb: 1 }}>
              Background image
            </Typography>

            <div
              {...getRootProps()}
              style={{
                border: "1px dashed gray",
                padding: "10px",
                textAlign: "center",
              }}
            >
              <input {...getInputProps()} />
              <Typography variant="body1">
                {backgroundImageUrl
                  ? "Change the background image"
                  : "Add a background image"}
              </Typography>

              <Typography variant="caption" component="em">
                (only *.jpeg and *.png images)
              </Typography>
            </div>
            {backgroundImageUrl && (
              <Button onClick={() => handleBackgroundImageClear()}>
                Clear background
              </Button>
            )}
          </Box>

          <FormControl fullWidth margin="normal">
            <Typography component="legend" sx={{ mb: 1 }}>
              Map size
            </Typography>

            <Box display="flex" alignItems="center" gap={2}>
              <TextField
                label="Width"
                onChange={handleMapWidthChange}
                size="small"
                type="number"
                value={mapWidth}
                inputProps={{ min: 0 }}
              />
              <TextField
                label="Height"
                onChange={handleMapHeightChange}
                size="small"
                type="number"
                value={mapHeight}
                inputProps={{ min: 0 }}
              />
            </Box>
          </FormControl>

          {backgroundImageUrl && (
            <>
              <FormControl fullWidth margin="normal">
                <Typography component="legend" sx={{ mb: 1 }}>
                  Background image size
                </Typography>

                <Box display="flex" alignItems="center" gap={2}>
                  <TextField
                    label="Width"
                    onBlur={handleBackgroundImageWidthBlur}
                    onChange={handleBackgroundImageWidthChange}
                    size="small"
                    type="number"
                    value={backgroundImageWidth}
                    inputProps={{ min: 0 }}
                  />
                  <TextField
                    label="Height"
                    onBlur={handleBackgroundImageHeightBlur}
                    onChange={handleBackgroundImageHeightChange}
                    size="small"
                    type="number"
                    value={backgroundImageHeight}
                    inputProps={{ min: 0 }}
                  />
                </Box>
              </FormControl>

              <FormControl fullWidth margin="normal">
                <Typography component="legend" sx={{ mb: 1 }}>
                  Grid offset
                </Typography>

                <Box display="flex" alignItems="center" gap={2}>
                  <TextField
                    label="X"
                    onChange={handleGridOffsetXChange}
                    size="small"
                    type="number"
                    value={gridOffsetX}
                    inputProps={{ min: -tileSize, max: tileSize }}
                  />
                  <TextField
                    label="Y"
                    onChange={handleGridOffsetYChange}
                    size="small"
                    type="number"
                    value={gridOffsetY}
                    inputProps={{ min: -tileSize, max: tileSize }}
                  />
                </Box>
              </FormControl>
            </>
          )}

          <Typography component="legend">Tile size</Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <Slider
                aria-labelledby="continuous-slider"
                max={MAX_TILE_SIZE}
                min={MIN_TILE_SIZE}
                onChange={(_event, value) => handleTileSizeChange(value) }
                value={tileSize}
                valueLabelDisplay="auto"
              />
            </Grid>

            <Grid item>
              <Input
                value={tileSize}
                size="small"
                onChange={event => handleTileSizeChange(event.target.value)}
                // onBlur={handleBlur}
                inputProps={{
                  step: 0.1,
                  min: MIN_TILE_SIZE,
                  max: MAX_TILE_SIZE,
                  type: "number",
                  "aria-labelledby": "input-slider",
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleFinish}>Finish</Button>
      </DialogActions>
    </Dialog>
  );
}

export default CanvasFormDialog;
