import { ListItemAvatar, ListItemButton, ListItemText, IconButton, ListItemIcon } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import React, { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { Character } from '../../../battleAssistant/BattleAssistant';
import { CharacterAvatar } from '../CharacterAvatar';
import { useBattleAssistant } from '../BattleAssistantContext';
import WithFeatureAccess, { Feature } from '../WithFeatureAccess';

interface CharactersMenuListItemProps {
  character: Character;
  isSelected: boolean;
  onCharacterSelect: (character: Character) => void;
}

const CharactersMenuListItem: React.FC<CharactersMenuListItemProps> = ({
  character,
  isSelected,
  onCharacterSelect,
}) => {
  const [isGrabbing, setIsGrabbing] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const {
    setUpdatedCharacter,
  } = useBattleAssistant();

  const handleVisibilityClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    character.hidden = !character.hidden;
    setUpdatedCharacter(character);
  }

  return (
    <ListItemButton
      onClick={() => onCharacterSelect(character)}
      selected={isSelected}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        position: 'relative',
      }}
    >
      {isHovered && (
        <ListItemIcon
          className="drag-handle"
          onClick={(event) => { event.stopPropagation() }}
          onMouseDown={() => setIsGrabbing(true)}
          onMouseUp={() => setIsGrabbing(false)}
          sx={{
            cursor: isGrabbing ? 'grabbing' : 'grab',
            left: '-3px',
            position: 'absolute',
          }}
        >
          <DragIndicatorIcon />
        </ListItemIcon>
      )}

      <ListItemAvatar sx={{ paddingLeft: '2px' }}>
        <CharacterAvatar
          character={character}
        />
      </ListItemAvatar>

      <ListItemText primary={character.name} />

      <WithFeatureAccess feature={Feature.InvisibleObject} action='change' object={character}>
        {isHovered && (
          <IconButton
            edge="end"
            size="small"
            onClick={handleVisibilityClick}
            sx={{
              position: 'absolute',
              right: 16,
              top: '50%',
              transform: 'translateY(-50%)'
            }}
          >
            {character.hidden ? (
              <VisibilityIcon />
            ) : (
              <VisibilityOffIcon />
            )}
          </IconButton>
        )}
      </WithFeatureAccess>
    </ListItemButton>
  );
};

export default CharactersMenuListItem;
