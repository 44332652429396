import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Slide,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import {
  Add as AddIcon,
  AutoFixHigh as AutoFixHighIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { forwardRef, useEffect, useState } from "react";
import { TransitionProps } from "@mui/material/transitions";

import {
  Character,
  CharacterAlignment,
  CombatAttribute,
} from "../../../battleAssistant/BattleAssistant";
import { useBattleAssistant } from "../BattleAssistantContext";
import { uuidBase62 } from '../../../utils/uuidBase62';
import CharacterAvatarDropzone from './CharacterAvatarDropzone';
import WithFeatureAccess, { Feature } from "../WithFeatureAccess";
import WithoutFeatureAccess from '../WithoutFeatureAccess';

// #region typings
interface FormCombatAttribute {
  currentValue: number;
  id: string;
  maxValue: number;
  name: string;
}

export interface CharacterFormDialogProps {
  character?: Character | null;
  characterImageUrl?: string;
  isOpen: boolean;
  onClose?: () => void;
}
// #endregion

// Transition Component
const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CharacterFormDialog({
  character,
  characterImageUrl,
  isOpen,
  onClose,
}: CharacterFormDialogProps) {
  // #region States
  const {
    characters,
    combatAttributes,
    players,
    setCharacterAiAction,
    setCombatAttributes,
    setCreatedCharacter,
    setDeletedCharacter,
    setIsCharacterFormDialogOpen,
    setNewCharacterImage,
    setUpdatedCharacter,
  } = useBattleAssistant();

  const [alignment, setAlignment] = useState<CharacterAlignment>(
    CharacterAlignment.Friendly
  );
  const [avatarImage, setAvatarImage] = useState<File | null>(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [formCombatAttributes, setFormCombatAttributes] = useState<
    FormCombatAttribute[]
  >([]);
  const [name, setName] = useState<string>("");
  const [nameError, setNameError] = useState<boolean>(false);
  const [ownerPlayers, setOwnerPlayers] = useState<string[]>([]);
  // #endregion

  // #region Lifecycle Methods
  useEffect(() => {
    resetForm();

    if (character) {
      setAlignment(character.alignment);
      setFormCombatAttributes(
        mapCharacterToFormAttributes(character, combatAttributes || [])
      );
      setName(character.name);
      setOwnerPlayers(character.playersIds || []);
    }
  }, [character, combatAttributes]);
  // #endregion

  // #region Utility Functions
  const mapCharacterToFormAttributes = (
    character: Character,
    combatAttributes: CombatAttribute[]
  ): FormCombatAttribute[] => {
    return combatAttributes.map((attr) => {
      const { currentValue, maxValue } = character.combatAttributes?.find(
        (ca) => ca.id === attr.id
      ) || { currentValue: 0, maxValue: 0 };

      return { ...attr, currentValue, maxValue };
    });
  };
  // #endregion

  // #region Handlers
  const addCombatAttribute = () => {
    setFormCombatAttributes([
      ...formCombatAttributes,
      { name: "", currentValue: 0, id: uuidBase62(), maxValue: 0 },
    ]);
  };

  const handleAiAction = () => {
    handleClose();

    if (character) {
      setCharacterAiAction(character);
    }
  };

  const handleAlignmentChange = (value: CharacterAlignment) => {
    setAlignment(value);
  };

  const handleAttributeNameChange = (
    e: React.ChangeEvent<{ value: unknown }>,
    index: number
  ) => {
    const newAttributes = [...formCombatAttributes];
    newAttributes[index].name = e.target.value as string;
    setFormCombatAttributes(newAttributes);
  };

  const handleAvatarChange = (image: File) => {
    setAvatarImage(image);
  }

  const handleCharacterOwnershipChange = (
    event: SelectChangeEvent<typeof ownerPlayers>
  ) => {
    const {
      target: { value },
    } = event;
    setOwnerPlayers(typeof value === "string" ? value.split(",") : value);
  };

  const handleClose = () => {
    onClose && onClose();
    setIsCharacterFormDialogOpen(false);
  };

  const handleCurrentValueChange = (
    e: React.ChangeEvent<{ value: unknown }>,
    index: number
  ) => {
    const newAttributes = [...formCombatAttributes];
    newAttributes[index].currentValue = Number(e.target.value);
    setFormCombatAttributes(newAttributes);
  };

  const handleDelete = () => {
    if (character) {
      setDeletedCharacter(character);
      handleClose();
    }
  };

  const handleMaxValueChange = (
    e: React.ChangeEvent<{ value: unknown }>,
    index: number
  ) => {
    const newAttributes = [...formCombatAttributes];
    newAttributes[index].maxValue = Number(e.target.value);
    setFormCombatAttributes(newAttributes);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleSubmit = () => {
    if (!name.trim()) {
      setNameError(true);
      return;
    }

    const combatAttributes = formCombatAttributes.map(({ id, name }) => ({
      id,
      name,
    }));
    const characterCombatAttributes = formCombatAttributes.map(
      ({ currentValue, id, maxValue }) => ({
        currentValue,
        id,
        maxValue,
      })
    );
    const characterAttributes = {
      alignment,
      combatAttributes: characterCombatAttributes,
      name,
    };
    setCombatAttributes(combatAttributes);
    if (avatarImage) { setNewCharacterImage(avatarImage); }

    if (character) {
      setUpdatedCharacter({
        ...character,
        ...characterAttributes,
        playersIds: ownerPlayers,
      });
    } else {
      const id = uuidBase62();
      setCreatedCharacter({
        ...characterAttributes,
        id,
        playersIds: ownerPlayers,
        position: Object.keys(characters).length,
      });
    }

    handleClose();
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const resetForm = () => {
    setAlignment(CharacterAlignment.Friendly);
    setName("");
    setNameError(false);
    setOwnerPlayers([]);

    setFormCombatAttributes(
      formCombatAttributes.map((attr) => ({
        currentValue: 0,
        id: attr.id,
        maxValue: 0,
        name: attr.name,
      }))
    );
  };

  const removeCombatAttribute = (index: number) => {
    const newAttributes = [...formCombatAttributes];
    newAttributes.splice(index, 1);
    setFormCombatAttributes(newAttributes);
  };
  // #endregion

  return (
    <Dialog
      fullWidth={true}
      onClose={handleClose}
      open={isOpen}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <WithFeatureAccess feature={Feature.AI} action='change'>
            <Tabs value={currentTab} onChange={handleTabChange}>
              <Tab label="Character Details" />
              <Tab label="AI Behavior" />
            </Tabs>
          </WithFeatureAccess>

          <WithoutFeatureAccess feature={Feature.AI} action='change'>
            <Typography variant="h6">
              Character Details
            </Typography>
          </WithoutFeatureAccess>

          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            flexGrow={2}
            sx={{
              "& > *": { mt: 2 },
            }}
          >
            <FormControl fullWidth margin="normal">
              <TextField
                fullWidth
                label="Name"
                value={name}
                onChange={handleNameChange}
                error={nameError}
                helperText={nameError ? "Required" : ""}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel id="alignment-label">Alignment</InputLabel>

              <Select
                label="Alignment"
                labelId="alignment-label"
                onChange={(e) => handleAlignmentChange(e.target.value as any)}
                value={alignment}
              >
                <MenuItem value={CharacterAlignment.Aggressive}>
                  Agressive
                </MenuItem>
                <MenuItem value={CharacterAlignment.Friendly}>
                  Friendly
                </MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box
            sx={{
              mt: 2,
              ml: 2,
            }}
          >
            <CharacterAvatarDropzone
              defaultImageUrl={characterImageUrl}
              onChange={handleAvatarChange}
              style={{
                width: 136,
                height: 136,
                backgroundColor: 'transparent',
              }}
            />
          </Box>
        </Box>

        <WithFeatureAccess
          feature={Feature.CharacterOwnership}
          action="change"
        >
          <FormControl fullWidth margin="normal">
            <InputLabel id="characterOwnership">Players owners</InputLabel>
            <Select
              labelId="characterOwnership"
              multiple
              value={ownerPlayers}
              onChange={handleCharacterOwnershipChange}
              input={<OutlinedInput label="Players owners" />}
              renderValue={(uids) =>
                uids
                  .map(
                    (uid) => players.find((p) => p.uid === uid)?.nickname
                  )
                  .join(", ")
              }
            >
              {players
                .filter((p) => !p.isGameMaster)
                .map(({ nickname, uid }) => (
                  <MenuItem key={uid} value={uid || ""}>
                    <Checkbox
                      checked={ownerPlayers.indexOf(uid || "") > -1}
                    />
                    {nickname}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </WithFeatureAccess>

        <FormControl fullWidth margin="normal">
          <Typography variant="h6">Combat Attributes</Typography>

          <Box sx={{ mt: 2 }}>
            {formCombatAttributes.map((attr, index) => (
              <Box
                key={index}
                display="flex"
                alignItems="center"
                gap={2}
                mb={2}
              >
                <TextField
                  label="Attribute Name"
                  onChange={(e) => handleAttributeNameChange(e, index)}
                  size="small"
                  value={attr.name}
                />
                <TextField
                  label="Current Value"
                  onChange={(e) => handleCurrentValueChange(e, index)}
                  size="small"
                  type="number"
                  value={attr.currentValue}
                />
                <TextField
                  label="Total Value"
                  onChange={(e) => handleMaxValueChange(e, index)}
                  size="small"
                  type="number"
                  value={attr.maxValue}
                />
                <IconButton onClick={() => removeCombatAttribute(index)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}

            <Button startIcon={<AddIcon />} onClick={addCombatAttribute}>
              Add Attribute
            </Button>
          </Box>
        </FormControl>
      </DialogContent>

      <DialogActions>
        {character && (
          <>
            <Button
              onClick={handleDelete}
              color="error"
              style={{ marginRight: "auto" }}
            >
              Remove
            </Button>

            <WithFeatureAccess feature={Feature.AI} action="change">
              <Button
                color="secondary"
                onClick={handleAiAction}
                startIcon={<AutoFixHighIcon />}
              >
                Act
              </Button>
            </WithFeatureAccess>
          </>
        )}

        <Button onClick={handleSubmit}>{character ? "Update" : "Add"}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default CharacterFormDialog;
