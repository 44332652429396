import React, { ReactNode } from 'react';

import { useBattleAssistant } from './BattleAssistantContext';
import { Character, CharacterAlignment, Player } from '../../battleAssistant/BattleAssistant';
import { isDevelopment } from '../../config/config';

//#region typing
type FeatureAction = 'create' | 'change' | 'view';

interface WithFeatureAccessProps {
  action: FeatureAction;
  children: ReactNode;
  feature: Feature;
  object?: any;
}
//#endregion

export enum Feature {
  AI = 'ai',
  Character = 'character',
  CharacterOwnership = 'characterOwnership',
  InvisibleObject = 'invisibleObject',
  Map = 'map',
  Toolbar = 'toolbar',
}

export const canAccessFeature = (localPlayer: Player | null, feature: Feature, action: string, object?: any): boolean => {
  if (localPlayer == null) { return false; }

  if (feature === Feature.AI) {
    return isDevelopment;
  } else if (feature === Feature.Character && object) {
    const character: Character = object;

    // podem acessar qualquer feature desde que o personagem seja seu
    if ((character.playersIds || []).includes(localPlayer.uid || '')) {
      return true;
    }

    // podem ver personagens que sejam seus aliados
    if (action === 'view' && character.alignment === CharacterAlignment.Friendly) {
      return true;
    }
  }

  if (localPlayer.isGameMaster) { return true; }

  return false;
};

const WithFeatureAccess: React.FC<WithFeatureAccessProps> = ({ action, children, feature, object }) => {
  const {
    localPlayer,
  } = useBattleAssistant();

  if (!canAccessFeature(localPlayer, feature, action, object)) { return null; }

  return <>{children}</>;
};

export default WithFeatureAccess;
