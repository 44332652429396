import { Box, Collapse, IconButton, Paper, useTheme } from '@mui/material';
import { mdiAccountGroup, mdiDramaMasks } from '@mdi/js';
import { useEffect, useState } from 'react';
import Icon from '@mdi/react';
import MapIcon from '@mui/icons-material/Map';

import CharactersMenu from './CharactersMenu';
import MapListDialog from '../dialogs/MapListDialog';
import PlayersMenu from './PlayersMenu';
import SelectedCharacterMenu from './SelectedCharacterMenu';

// #region typings
type MenuOption = 'characters' | 'players';
// #endregion

const DEFAULT_MENU: MenuOption = 'characters';

function MainMenu() {
  const [lastSelectedMenu, setLastSelectedMenu] = useState<MenuOption>(DEFAULT_MENU);
  const [mapListIsOpen, setMapListIsOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState<MenuOption | null>(DEFAULT_MENU);
  const theme = useTheme()

  const activeIconColor = theme.palette.primary.dark;
  const iconColor = theme.palette.text.disabled;

  //#region handlers
  const handleMapListClose = () => {
    setMapListIsOpen(false);
  }

  const handleMapListOpen = () => {
    setMapListIsOpen(true);
  }

  const handleSelectedMenuClick = (option: MenuOption) => {
    if (option === selectedMenu) {
      setSelectedMenu(null);
      return;
    }

    setSelectedMenu(option);
  };
  //#endregion

  useEffect(() => {
    if (!selectedMenu) { return; }

    setLastSelectedMenu(selectedMenu)
  }, [selectedMenu])

  return (
    <>
      <Paper
        elevation={3}
        sx={{
          backgroundColor: 'transparent',
          borderRadius: '20px',
          left: '20px',
          overflow: 'hidden',
          position: 'absolute',
          top: '10px',
        }}
      >
        <Box
          sx={{
            backdropFilter: 'blur(10px)',
            backgroundColor: theme.palette.background.paperBlur,
            bottom: 0,
            left: 0,
            position: 'absolute',
            right: 0,
            top: 0,
          }}
        />

        <IconButton
          aria-label="Maps"
          onClick={() => handleMapListOpen()}
          style={{ color: iconColor }}
        >
          <MapIcon />
        </IconButton>
      </Paper>

      <Paper
        elevation={3}
        sx={{
          backgroundColor: 'transparent',
          borderRadius: '20px',
          left: '70px',
          overflow: 'hidden',
          position: 'absolute',
          top: '10px',
        }}
      >
        <Box
          sx={{
            backdropFilter: 'blur(10px)',
            backgroundColor: theme.palette.background.paperBlur,
            bottom: 0,
            left: 0,
            position: 'absolute',
            right: 0,
            top: 0,
          }}
        />

        <IconButton
          aria-label="characters"
          onClick={() => handleSelectedMenuClick('characters')}
          style={{ color: selectedMenu === 'characters' ? activeIconColor : iconColor }}
        >
          <Icon path={mdiDramaMasks} size={1.2} />
        </IconButton>

        <IconButton
          aria-label="players"
          onClick={() => handleSelectedMenuClick('players')}
          style={{ color: selectedMenu === 'players' ? activeIconColor : iconColor }}
        >
          <Icon path={mdiAccountGroup} size={1.2} />
        </IconButton>
      </Paper>

      <Box
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          left: '20px',
          position: 'absolute',
          top: '65px',
        }}
      >
        <Collapse in={selectedMenu != null} unmountOnExit>
          <Paper
            elevation={3}
            sx={{
              backgroundColor: 'transparent',
              marginRight: '20px',
              overflow: 'hidden',
            }}
          >
            <Box sx={{ position: 'relative' }}>
              <Box
                sx={{
                  backdropFilter: 'blur(10px)',
                  backgroundColor: theme.palette.background.paperBlur,
                  bottom: 0,
                  left: 0,
                  position: 'absolute',
                  right: 0,
                  top: 0,
                }}
              />

              {lastSelectedMenu === 'characters' && (
                <CharactersMenu />
              )}

              {lastSelectedMenu === 'players' && (
                <PlayersMenu />
              )}
            </Box>
          </Paper>
        </Collapse>

        <SelectedCharacterMenu />
      </Box>

      <MapListDialog
        isOpen={mapListIsOpen}
        onClose={handleMapListClose}
      />
    </>
  );
}

export default MainMenu;
