import { GridType } from '../battleAssistant/Canvas';
import { RealtimeDatabaseRepositoryAdapter, Unsubscribe } from './databaseAdapters/RealtimeDatabaseRepositoryAdapter.interface';
import { uuidBase62 } from '../utils/uuidBase62';
import BattleAssistant, { BattleAssistantJSON, Character, CharacterAlignment } from '../battleAssistant/BattleAssistant';
import stringToHash from '../utils/stringToHash';

// #region typings
export type BattleAssistantData = BattleAssistantJSON & {
  hash: string;
  updatedBy: string;
}

export interface MapSummaryCharacter {
  alignment: CharacterAlignment;
  avatarImagePath?: string;
  id: string;
  name: string;
}

export interface MapSummary {
  backgroundImagePath: BattleAssistantData['backgroundImagePath'];
  backgroundThumbPath?: BattleAssistantData['backgroundThumbPath'];
  characters: MapSummaryCharacter[];
  id: string;
  name: string;
}

interface MapRepositoryDependencies {
  adapter: RealtimeDatabaseRepositoryAdapter;
  campaignId: string;
}
//#endregion

class MapRepository implements MapRepositoryDependencies {
  adapter: RealtimeDatabaseRepositoryAdapter;
  campaignId: string;

  constructor({
    adapter,
    campaignId,
  }: MapRepositoryDependencies) {
    this.adapter = adapter;
    this.campaignId = campaignId;
  }

  buildHash(battleAssistant: BattleAssistant): Promise<string> {
    return stringToHash(JSON.stringify(battleAssistant.toJSON()));
  }

  async create(name: string): Promise<string> {
    const id = uuidBase62();

    await this.adapter.set(
      this.buildPath(id),
      {
        grid: { type: GridType.Square },
        mapId: id,
        name,
      }
    );

    return id;
  }

  async list(): Promise<MapSummary[]> {
    const obj = await this.adapter.get(this.buildBasePath());
    if (!obj) { return []; }

    return Object.entries(obj).map(([id, value]) => {
      const data: BattleAssistantData = value || {};

      return {
        backgroundImagePath: data.backgroundImagePath,
        backgroundThumbPath: data.backgroundThumbPath,
        characters: Object.values(data.characters || {}).map(character => ({
          alignment: character.alignment,
          avatarImagePath: character.avatarImagePath,
          id: character.id,
          name: character.name,
        } as MapSummaryCharacter)),
        id,
        name: data.name,
      };
    });
  }

  async load(id: string): Promise<BattleAssistantData> {
    const value = await this.adapter.get(this.buildPath(id)) as any;
    return value || undefined;
  }

  onChange(id: string, handler: (data: BattleAssistantData) => unknown): Unsubscribe {
    return this.adapter.onValueChange(this.buildPath(id), handler);
  }

  onCharacterChange(mapId: string, handler: (data: Character) => unknown): Unsubscribe {
    return this.adapter.onChildChange(
      [...this.buildPath(mapId), 'characters'],
      (_childId, data) => handler(data),
    );
  }

  async remove(id: string): Promise<void> {
    await this.adapter.remove(this.buildPath(id));
  }

  async save(id: string, battleAssistant: BattleAssistant): Promise<void> {
    if (!battleAssistant.localPlayer) {
      throw new Error('Missing battleAssistant.localPlayer')
    }

    const hash = await this.buildHash(battleAssistant);

    const data: BattleAssistantData = {
      ...battleAssistant.toJSON(),
      hash,
      updatedBy: battleAssistant.localPlayer.sessionId,
    }

    await this.adapter.set(this.buildPath(id), data);
  }

  async update(id: string, params: Partial<BattleAssistantJSON>): Promise<void> {
    await this.adapter.update(this.buildPath(id), params);
  }

  private buildBasePath() {
    return ['campaigns', this.campaignId, 'maps'];
  }

  private buildPath(mapId: string) {
    return [...this.buildBasePath(), mapId];
  }
}

export default MapRepository;
