import { auth } from '../config/firebaseConfig';
import { auth as firebaseuiAuth } from 'firebaseui';
import { EmailAuthProvider } from 'firebase/auth';
import { useEffect } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

let ui: firebaseuiAuth.AuthUI | null = null;

function Login() {
  useEffect(() => {
    // Configuração do FirebaseUI.
    const uiConfig = {
      signInSuccessUrl: '/',
      signInOptions: [
        EmailAuthProvider.PROVIDER_ID,
      ],
    };

    // Verifica se uma instância já existe. Se não, cria uma nova.
    if (!ui) {
      ui = new firebaseuiAuth.AuthUI(auth);
    }

    // Inicializa o widget FirebaseUI usando o config.
    ui.start('#firebaseui-auth-container', uiConfig);

    // Limpa a UI após a desmontagem do componente
    return () => {
      ui?.reset();
    };
  }, []);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
      <div>
        <Typography variant="h3" sx={{ mb: 2, textAlign: 'center' }}>Zenrole</Typography>
        <div id="firebaseui-auth-container" style={{ width: '360px' }}></div>
      </div>
    </Box>
  );
}

export default Login;
