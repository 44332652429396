import React, { ReactNode } from 'react';

import { useBattleAssistant } from './BattleAssistantContext';
import { Feature, canAccessFeature } from './WithFeatureAccess';

//#region typing
type FeatureAction = 'create' | 'change' | 'view';

interface WithoutFeatureAccessProps {
  action: FeatureAction;
  children: ReactNode;
  feature: Feature;
  object?: any;
}
//#endregion

const WithoutFeatureAccess: React.FC<WithoutFeatureAccessProps> = ({ action, children, feature, object }) => {
  const {
    localPlayer,
  } = useBattleAssistant();

  if (canAccessFeature(localPlayer, feature, action, object)) { return null; }

  return <>{children}</>;
};

export default WithoutFeatureAccess;
