import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectDatabaseEmulator, getDatabase } from 'firebase/database';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyDjoFp89MotFjwRnK6r5Um7HAaEdHB2g4M",
  authDomain: "zenrole-1411e.firebaseapp.com",
  projectId: "zenrole-1411e",
  storageBucket: "zenrole-1411e.appspot.com",
  messagingSenderId: "106264130044",
  appId: "1:106264130044:web:ecace86bb50718b51f4279",
  measurementId: "G-T154BSGPC4"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getDatabase();
export const firestore = getFirestore();
export const storage = getStorage();

if (process.env.REACT_APP_AUTH_EMULATOR_URL) {
  connectAuthEmulator(auth, process.env.REACT_APP_AUTH_EMULATOR_URL);
}

if (process.env.REACT_APP_DATABASE_EMULATOR_HOST && process.env.REACT_APP_DATABASE_EMULATOR_PORT) {
  connectDatabaseEmulator(
    db,
    process.env.REACT_APP_DATABASE_EMULATOR_HOST,
    Number(process.env.REACT_APP_DATABASE_EMULATOR_PORT),
  );
}

if (process.env.REACT_APP_FIRESTORE_EMULATOR_HOST && process.env.REACT_APP_FIRESTORE_EMULATOR_PORT) {
  connectFirestoreEmulator(
    firestore,
    process.env.REACT_APP_FIRESTORE_EMULATOR_HOST,
    Number(process.env.REACT_APP_FIRESTORE_EMULATOR_PORT),
  );
}

if (process.env.REACT_APP_STORAGE_EMULATOR_HOST && process.env.REACT_APP_STORAGE_EMULATOR_PORT) {
  connectStorageEmulator(
    storage,
    process.env.REACT_APP_STORAGE_EMULATOR_HOST,
    Number(process.env.REACT_APP_STORAGE_EMULATOR_PORT),
  );
}
