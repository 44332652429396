import {
  Box,
  Divider,
  Fade,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import React, { useEffect, useState } from 'react';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import SettingsIcon  from '@mui/icons-material/Settings';

import { Character, CombatAttribute } from '../../../battleAssistant/BattleAssistant';
import { useBattleAssistant } from '../BattleAssistantContext';
import TransparentInput from '../../TransparentInput';
import WithFeatureAccess, { Feature, canAccessFeature } from '../WithFeatureAccess';

// #region
interface FormCombatAttribute {
  currentValue: string;
  id: string;
  maxValue: string;
  name: string;
}
// #endregion

function SelectedCharacterMenu() {
  const theme = useTheme();
  const {
    combatAttributes,
    localPlayer,
    rotateCharacter,
    selectedCharacter,
    setIsCharacterFormDialogOpen,
    setRotateCharacter,
    setUpdatedCharacter,
  } = useBattleAssistant();

  const [characterName, setCharacterName] = useState<string>('');
  const [formCombatAttributes, setFormCombatAttributes] = useState<FormCombatAttribute[]>([]);

  // #region handlers
  const handleChangeCharacter = () => {
    if (!selectedCharacter) { return; }

    setUpdatedCharacter(selectedCharacter)
  };

  const handleCombatAttributeBlur = (
    event: React.ChangeEvent<{ value: unknown }>,
    index: number,
    attrName: 'currentValue' | 'maxValue',
  ) => {
    if (!selectedCharacter || !combatAttributes) { return; }

    if (!selectedCharacter.combatAttributes) {
      selectedCharacter.combatAttributes = combatAttributes.map((combatAttribute)  => ({
        ...combatAttribute,
        currentValue: 0,
        maxValue: 0,
      }))
    }

    const val = event.target.value || '0';
    const attr = selectedCharacter.combatAttributes[index];

    if (attr[attrName] !== Number(val)) {
      attr[attrName] = Number(event.target.value);
      handleChangeCharacter();
    }
  };

  const handleCombatAttributeChange = (
    event: React.ChangeEvent<{ value: string }>,
    index: number,
    attrName: 'currentValue' | 'maxValue',
  ) => {
    const val = event.target.value;

    if (isDecimalNumber(val) || val === '') {
      const newAttributes = [...formCombatAttributes];
      newAttributes[index][attrName] = event.target.value;
      setFormCombatAttributes(newAttributes);
    }
  };

  const toggleRotateCharacter = () => {
    setRotateCharacter(rotateCharacter ? null : selectedCharacter)
  }
  // #endregion

  // #region Lifecycle functions
  useEffect(() => {
    if (!selectedCharacter) { return; }

    setCharacterName(selectedCharacter.name);
    setFormCombatAttributes(
      mapCharacterToFormAttributes(selectedCharacter, combatAttributes || [])
    );
  }, [selectedCharacter, combatAttributes]);
  // #endregion

  // #region Utility functions
  const isDecimalNumber = (value: string | undefined): boolean => {
    return /^\d*$/.test(value || '')
  }

  const mapCharacterToFormAttributes = (character: Character, combatAttributes: CombatAttribute[]): FormCombatAttribute[] => {
    return combatAttributes.map((attr) => {
      const {
        currentValue,
        maxValue,
      } = character.combatAttributes?.find(ca => ca.id === attr.id) || { currentValue: 0, maxValue: 0 };

      return {
        ...attr,
        currentValue: String(currentValue),
        maxValue: String(maxValue),
      };
    });
  }
  // #endregion

  return (
    <Fade in={selectedCharacter != null} unmountOnExit>
      <Paper
        elevation={3}
        sx={{
          position: 'relative',
          backgroundColor: 'transparent',
        }}
      >
        {/* blurred background */}
        <Box
          sx={{
            backdropFilter: 'blur(10px)',
            backgroundColor: theme.palette.background.paperBlur,
            bottom: 0,
            left: 0,
            position: 'absolute',
            right: 0,
            top: 0,
          }}
        />
        {/* end blurred background */}

        {/* menu */}
        <Box
          sx={{
            backgroundColor: 'transparent',
            display: 'flex',
            flexDirection: 'column',
            width: '240px',
            position: 'relative',
          }}
        >
          {/* header */}
          <Box
            sx={{ display: 'flex', alignItems: 'center', pb: 1, pl: 2, pr: 2, pt: 1 }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{ flexGrow: 1, margin: 0 }}
            >
              {characterName}
            </Typography>

            <WithFeatureAccess feature={Feature.Character} action='change' object={selectedCharacter}>
              <IconButton
                aria-label="Character details"
                onClick={() => setIsCharacterFormDialogOpen(true)}
                size="small"
              >
                <SettingsIcon />
              </IconButton>
            </WithFeatureAccess>
          </Box>
          {/* end header */}

          <WithFeatureAccess feature={Feature.Character} action='change' object={selectedCharacter}>
            <Divider />

            <List sx={{ display: 'flex', p: 0 }}>
              <ListItemButton sx={{ width: '50%' }}>
                <ListItemIcon sx={{ minWidth: 34 }}>
                  <AspectRatioIcon />
                </ListItemIcon>

                <ListItemText primary="Resize" />
              </ListItemButton>

              <ListItemButton
                onClick={toggleRotateCharacter}
                selected={rotateCharacter?.id === selectedCharacter?.id}
                sx={{ width: '50%' }}
              >
                <ListItemIcon sx={{ minWidth: 34 }}>
                  <RotateRightIcon />
                </ListItemIcon>

                <ListItemText primary="Rotate" />
              </ListItemButton>
            </List>
          </WithFeatureAccess>

          {/* combat attributes */}
          {selectedCharacter && combatAttributes && (
            <WithFeatureAccess
              feature={Feature.Character}
              action='view'
              object={selectedCharacter}
            >
              <List>
                {formCombatAttributes.map((attr, index) => {
                  return (
                    <div key={`combat-attribute-${index}`}>
                      <ListSubheader style={{ backgroundColor: 'transparent', lineHeight: 1 }}>
                        {attr.name}
                      </ListSubheader>

                      <ListItem
                        style={{
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'space-between',
                          padding: '0 16px 10px'
                        }}
                      >
                        <TransparentInput
                          id={`combat-attribute-${attr?.name}-current`}
                          onBlur={(e => handleCombatAttributeBlur(e, index, 'currentValue'))}
                          onChange={e => handleCombatAttributeChange(e, index, 'currentValue')}
                          style={{ textAlign: 'right', width: '100%', maxWidth: '45%', flex: 1 }}
                          value={attr.currentValue}
                          disabled={!canAccessFeature(localPlayer, Feature.Character, 'manage', selectedCharacter)}
                        />

                        <Typography variant='h6' style={{ padding: '0 10px', whiteSpace: 'nowrap' }}>/</Typography>

                        <TransparentInput
                          id={`combat-attribute-${attr?.name}-max`}
                          onBlur={(e => handleCombatAttributeBlur(e, index, 'maxValue'))}
                          onChange={e => handleCombatAttributeChange(e, index, 'maxValue')}
                          style={{ width: '100%', maxWidth: '45%', flex: 1 }}
                          value={attr.maxValue}
                          disabled={!canAccessFeature(localPlayer, Feature.Character, 'manage', selectedCharacter)}
                        />
                      </ListItem>
                    </div>
                  );
                })}
              </List>
            </WithFeatureAccess>
          )}
          {/* end combat attributes */}
        </Box>
        {/* end menu */}
      </Paper>
    </Fade>
  );
}

export default SelectedCharacterMenu;
