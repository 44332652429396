import { buildFunctionUrl } from '../config/config';

async function loadChatMessages({
  sessionId,
  token,
}: {
  sessionId: string;
  token: string;
}): Promise<{ content: string, type: 'ai' | 'human' }[]> {
  const url = buildFunctionUrl('load_chat_messages') + `?session_id=${sessionId}`;
  const headers = new Headers({
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json'
  });

  const response = await fetch(url, {
    method: 'GET',
    headers: headers,
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(`Failed to send message on chat: ${response.statusText} ${errorMessage}`);
  }

  return response.json();
}

export default loadChatMessages;
