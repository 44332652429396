import { buildFunctionUrl } from '../config/config';

async function sendMessageOnChat({
  prompt,
  sessionId,
  token,
}: {
  prompt: string;
  sessionId: string;
  token: string;
}): Promise<string> {
  const url = buildFunctionUrl('chat');
  const headers = new Headers({
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json'
  });

  const response = await fetch(url, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      prompt,
      session_id: sessionId,
    }),
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(`Failed to send message on chat: ${response.statusText} ${errorMessage}`);
  }

  const data = await response.json();
  return data.answer;
}

export default sendMessageOnChat;
