import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { auth } from './config/firebaseConfig';

interface AuthContextType {
  isAuthenticated: boolean;
  uid: string | null;
  email: string | null;
  token: string | null;
}

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  uid: null,
  email: null,
  token: null,
});

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [email, setEmail] = useState<string | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [token, setToken] = useState<string | null>(null);
  const [uid, setUid] = useState<string | null>(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async user => {
      setIsAuthenticated(!!user);
      setEmail(user?.email || null);
      setUid(user?.uid || null);

      if (user) {
        const idToken = await user.getIdToken();
        setToken(idToken);
      } else {
        setToken(null);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const value = {
    isAuthenticated,
    uid,
    email,
    token,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
