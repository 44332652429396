import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { TransitionProps } from "@mui/material/transitions";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import React, { forwardRef, useEffect, useMemo, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';

import { MapSummary } from "../../../repositories/MapRepository";
import { sortBy, uniqBy } from "../../../utils/arrayFunctions";
import { useBattleAssistant } from "../BattleAssistantContext";
import MapListItem from "./MapListItem";
import RemoveConfirmationDialog from '../../dialogs/RemoveConfirmationDialog';

export interface MapListDialogProps {
  isOpen: boolean;
  onClose?: () => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function MapListDialog({ isOpen, onClose }: MapListDialogProps) {
  const [currentMap, setCurrentMap] = useState<MapSummary | null>(null);
  const [imageUrls, setImageUrls] = useState<Record<string, string>>({});
  const [isLoading, setIsLoading] = useState(true);
  const [mapName, setMapName] = useState('');
  const [maps, setMaps] = useState<MapSummary[]>([]);
  const [newMapDialogIsOpen, setNewMapDialogIsOpen] = useState(false);
  const [newMapIsLoading, setNewMapIsLoading] = useState(false);
  const [newMapName, setNewMapName] = useState('');
  const [removeDialogIsOpen, setRemoveDialogIsOpen] = useState(false);
  const [renameDialogIsOpen, setRenameDialogIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const {
    campaignId,
    mapId,
    mapFileRepository,
    mapRepository,
  } = useBattleAssistant();

  const filteredMaps = useMemo(() => {
    return maps.filter(map => map.name.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [maps, searchTerm]);

  // #region handlers
  const handleCardClick = ({ id }: MapSummary) => {
    if (id !== mapId) {
      navigate(`/campaigns/${campaignId}/maps/${id}`);
    }

    handleClose();
  };

  const handleClose = () => {
    setIsLoading(true);
    onClose && onClose();
  };

  const handleCloseFormDialog = () => {
    setCurrentMap(null);
    setMapName('')
    setNewMapDialogIsOpen(false);
    setNewMapName('');
    setRenameDialogIsOpen(false);
  };

  const handleMapNameChange = (value: string) => {
    setMapName(value);
  }

  const handleNewMapClick = () => {
    setNewMapDialogIsOpen(true);
  };

  const handleNewMapSubmit = () => {
    mapRepository.create(newMapName).then((newId) => {
      setNewMapIsLoading(false);
      setNewMapDialogIsOpen(true);
      navigate(`/campaigns/${campaignId}/maps/${newId}`);
      handleCloseFormDialog();
      handleClose();
    });

    setNewMapIsLoading(true);
  };

  const handleRemoveConfirm = () => {
    handleRemoveDialogClose();
    if (!currentMap) { return; }

    mapRepository.remove(currentMap.id);

    // Atualiza maps para forçar uma renderização na nome atualizado
    const mapIndex = maps.findIndex(m => m.id === currentMap.id);
    setMaps([
      ...maps.slice(0, mapIndex),
      ...maps.slice(mapIndex + 1),
    ]);

    setCurrentMap(null);

    // Quando o mapa removido for o mapa atual redireciona para seleção de mapas
    if (currentMap.id === mapId) {
      navigate(`/campaigns/${campaignId}`);
      // TODO: redirecionar todos os jogadores que estiverem no mapa removido para seleção de mapas
    }
  }

  const handleRemoveDialogClose = () => {
    setCurrentMap(null);
    setRemoveDialogIsOpen(false);
  };

  const handleRenameMapSubmit = () => {
    if (!currentMap || mapName == null) { return; }

    handleCloseFormDialog();
    currentMap.name = mapName;
    mapRepository.update(currentMap.id, { name: mapName });

    // Atualiza maps para forçar uma renderização na nome atualizado
    const mapIndex = maps.findIndex(m => m.id === currentMap.id);
    setMaps([
      ...maps.slice(0, mapIndex),
      { ...currentMap },
      ...maps.slice(mapIndex + 1),
    ]);
  }

  const handleNewMapNameChange = (value: string) => {
    setNewMapName(value);
  };

  const handleRemoveMapClick = (map: MapSummary) => {
    setCurrentMap(map);
    setRemoveDialogIsOpen(true);
  };

  const handleRenameMapClick = (map: MapSummary) => {
    setCurrentMap(map);
    setMapName(map.name || '');
    setRenameDialogIsOpen(true);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };
  // #endregion

  //#region Lifecycles
  useEffect(() => {
    if (!isOpen || !isLoading) {
      return;
    }

    const fetchMaps = async () => {
      const maps = sortBy(await mapRepository.list(), "name");
      setMaps(maps);
      setIsLoading(false);

      const characters = uniqBy(
        maps.flatMap(({ characters }) => characters),
        (c) => c.id
      );

      // Obtém todas as urls das imagens
      const imageUrls: Array<[string, string] | undefined> = await Promise.all([
        ...maps.map(async ({ backgroundThumbPath, id }) => {
          if (!backgroundThumbPath) { return; }

          const url = await mapFileRepository.getUrlByPath(backgroundThumbPath);
          if (!url) { return; }

          return [id, url] as [string, string];
        }),

        ...characters.map(async ({ avatarImagePath, id }) => {
          if (!avatarImagePath) {
            return;
          }
          const url = await mapFileRepository.getUrlByPath(avatarImagePath);
          if (!url) {
            return;
          }
          return [id, url] as [string, string];
        }),
      ]);

      setImageUrls(
        imageUrls.reduce((obj, tuple) => {
          if (tuple) {
            obj[tuple[0]] = tuple[1];
          }
          return obj;
        }, {} as Record<string, string>)
      );
    };

    fetchMaps();
  }, [isLoading, isOpen, mapFileRepository, mapRepository]);
  //#endregion

  return (
    <>
      <Dialog
        fullScreen
        disableEscapeKeyDown={!onClose}
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={onClose ? Transition : undefined}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            {onClose && (
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            )}

            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Maps
            </Typography>

            <Button
              color="inherit"
              startIcon={<AddIcon />}
              onClick={handleNewMapClick}
            >
              New map
            </Button>
          </Toolbar>
        </AppBar>

        <Box sx={{ p: 2, pb: 0 }}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Search for maps..."
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ marginBottom: 2 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: (theme) => theme.palette.grey[500] }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {searchTerm && (
                    <IconButton
                      edge="end"
                      onClick={() => setSearchTerm('')}
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Grid container spacing={2} sx={{ p: 4, pt: 2 }}>
          {isLoading ? (
            <>Loading</>
          ) : (
            <>
              {filteredMaps.map((map) => (
                <MapListItem
                  key={map.id}
                  imageUrls={imageUrls}
                  map={map}
                  onCardClick={handleCardClick}
                  onRemoveMapClick={handleRemoveMapClick}
                  onRenameMapClick={handleRenameMapClick}
                />
              ))}
            </>
          )}
        </Grid>
      </Dialog>

      {/* New map form dialog */}
      <Dialog open={newMapDialogIsOpen}>
        <DialogTitle>Create New Map</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseFormDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <DialogContentText>
            Enter a name to create a new map for your campaign. You can
            customize it further later.
          </DialogContentText>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleNewMapSubmit();
            }}
          >
            <TextField
              autoFocus
              margin="dense"
              id="Map name"
              label="Map name"
              fullWidth
              variant="standard"
              value={newMapName}
              onChange={(event) => handleNewMapNameChange(event.target.value)}
            />
          </form>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseFormDialog}>Cancel</Button>

          <LoadingButton
            disabled={newMapName.trim().length === 0}
            loading={newMapIsLoading}
            onClick={handleNewMapSubmit}
            variant="contained"
          >
            Create
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {/* Rename map dialog */}
      <Dialog fullWidth maxWidth="xs" open={renameDialogIsOpen}>
        <DialogTitle>Rename</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseFormDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent sx={{ pt: 0 }}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleRenameMapSubmit();
            }}
          >
            <TextField
              autoFocus
              margin="dense"
              id="Map name"
              label="Map name"
              fullWidth
              variant="standard"
              value={mapName}
              onChange={(event) => handleMapNameChange(event.target.value)}
            />
          </form>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseFormDialog}>Cancel</Button>

          <LoadingButton
            disabled={mapName.trim().length === 0}
            onClick={handleRenameMapSubmit}
            variant="contained"
          >
            Change
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {currentMap && (
        <RemoveConfirmationDialog
          isOpen={removeDialogIsOpen}
          onClose={handleRemoveDialogClose}
          onConfirm={handleRemoveConfirm}
          resourceName={currentMap.name}
        />
      )}
    </>
  );
}

export default MapListDialog;
