// #region typings
export type CandidateSignalHandler = (signal: RTCIceCandidate) => Promise<unknown>
export type InitSignalHandler = (signal: RTCSessionDescriptionInit) => Promise<unknown>
export type PeerData = Record<string, boolean | number | string>;
export type SdpSignal = 'answer' | 'offer';
export type Unsubscribe = () => void;
// #endregion

abstract class SignalingService {
  sessionId: string;

  constructor({
    sessionId,
  }: {
    sessionId: string;
  }) {
    this.sessionId = sessionId;
  }

  abstract listPeers(): Promise<Array<[string, PeerData]>>;
  abstract onAnswerSignal(originPeerId: string, destinationPeerId: string, handler: InitSignalHandler): Unsubscribe;
  abstract onIceCandidateSignal(originPeerId: string, destinationPeerId: string, handler: CandidateSignalHandler): Unsubscribe;
  abstract onOfferSignal(originPeerId: string, destinationPeerId: string, handler: InitSignalHandler): Unsubscribe;
  abstract onPeerConnect(handler: (id: string, data: PeerData) => void): Promise<Unsubscribe>;
  abstract onPeerDataUpdate(peerId: string, handler: (data: PeerData) => void): Unsubscribe;
  abstract onPeerDisconnect(handler: (id: string) => void): Unsubscribe;
  abstract removePeer(peerId: string): Promise<void>;
  abstract sendConnection(originPeerId: string, data?: Record<string, any>): Promise<void>;
  abstract sendIceCandidateSignal(fromPeerId: string, toPeerId: string, signal: RTCIceCandidate): Promise<void>;
  abstract sendSdpSignal(fromPeerId: string, toPeerId: string, signalType: SdpSignal, signal: RTCSessionDescriptionInit): Promise<void>;
  abstract updatePeerData(id: string, data: PeerData): Promise<void>;
}

export default SignalingService;
