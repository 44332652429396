import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { CharacterAvatar } from "../CharacterAvatar";
import { MapSummary } from "../../../repositories/MapRepository";
import theme from "../../../theme";

interface MapListItemProps {
  imageUrls: Record<string, string>;
  map: MapSummary;
  onCardClick: (map: MapSummary) => void;
  onRemoveMapClick: (map: MapSummary) => void;
  onRenameMapClick: (map: MapSummary) => void;
}

const MapListItem: React.FC<MapListItemProps> = ({
  map,
  onCardClick,
  imageUrls,
  onRenameMapClick,
  onRemoveMapClick,
}) => {
  const [anchorMenuEl, setAnchorMenuEl] = useState<null | HTMLElement>(null);

  const handleRemoveMapClick = () => {
    setAnchorMenuEl(null);
    onRemoveMapClick(map);
  }

  const handleRenameMapClick = () => {
    setAnchorMenuEl(null);
    onRenameMapClick(map);
  }

  const handleSettingsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorMenuEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleSettingsMenuClose = () => {
    setAnchorMenuEl(null);
  };

  return (
    <>
      <Card sx={{ mb: 2, mr: 2, width: 256, position: "relative" }}>
        <CardActionArea onClick={() => onCardClick(map)}>
          <CardHeader
            title={map.name}
            sx={{
              minHeight: 22,
            }}
            titleTypographyProps={{
              sx: {
                fontSize: theme.typography.body1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: 202,
              },
            }}
          />

          {map.backgroundThumbPath ? (
            <CardMedia
              component="img"
              height="144"
              image={imageUrls[map.id]}
              alt={map.name}
            />
          ) : (
            <CardContent
              component="div"
              sx={{
                alignItems: 'center',
                backgroundColor: theme.palette.grey['700'],
                borderWidth: 0,
                display: 'flex',
                height: 144,
                justifyContent: 'center',
                p: 0,
              }}
            >
              <ImageNotSupportedIcon fontSize="large" />
            </CardContent>
          )}

          <CardActions sx={{ minHeight: 26 }}>
            {map.characters.map((character) => (
              <CharacterAvatar
                key={character.id}
                character={character}
                sx={{
                  borderWidth: 1,
                  fontSize: "0.75rem",
                  height: 24,
                  width: 24,
                }}
              />
            ))}
          </CardActions>
        </CardActionArea>

        <IconButton
          aria-label="settings"
          size="small"
          onClick={handleSettingsClick}
          sx={{
            position: "absolute",
            top: theme.spacing(1.5),
            right: theme.spacing(1),
          }}
        >
          <MoreVertIcon fontSize="small" />
        </IconButton>
      </Card>

      {/* Map settings menu */}
      <Menu
        id="basic-menu"
        anchorEl={anchorMenuEl}
        open={Boolean(anchorMenuEl)}
        onClose={handleSettingsMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => handleRenameMapClick()}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>

          <ListItemText>Rename</ListItemText>
        </MenuItem>

        <MenuItem onClick={() => handleRemoveMapClick()}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>

          <ListItemText>Remove</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default MapListItem;
