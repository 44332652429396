import './App.css';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import { BattleAssistantProvider } from './components/battleAssistant/BattleAssistantContext';
import BattleAssistant from './components/battleAssistant/BattleAssistant';
import Login from './components/login';
import UserRoute from './components/hocs/UserRoute';
import MapSelection from './components/battleAssistant/MapSelection';

const CAMPAIGN_ID = 'TpDENjdGfWP1c8g2ZDX6Q8IEGUo1-8SEHfXSuca8RdbJ3E77e';

function HomeRedirect() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/campaigns/${CAMPAIGN_ID}`);
  }, [navigate]);

  return null;
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeRedirect />}></Route>

        <Route
          path="/campaigns/:campaignId"
          element={
            <UserRoute>
              <BattleAssistantProvider>
                <MapSelection />
              </BattleAssistantProvider>
            </UserRoute>
          }
        />

        <Route
          path="/campaigns/:campaignId/maps/:mapId"
          element={
            <UserRoute>
              <BattleAssistantProvider>
                <BattleAssistant />
              </BattleAssistantProvider>
            </UserRoute>
          }
        />

        <Route path="/login" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;
