import { buildFunctionUrl } from '../config/config';

async function fetchIceServers(token: string): Promise<RTCIceServer[]> {
  const url = buildFunctionUrl('ice_servers');
  const headers = new Headers({
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json'
  });

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: headers
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch ICE servers: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Failed to fetch ICE servers: ${error}`);
    throw error;
  }
}

export default fetchIceServers;
