import {
  Box,
  Divider,
  IconButton,
  List,
  ListSubheader,
  Typography,
} from '@mui/material';
import { DropResult } from 'smooth-dnd';
import { useEffect, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import {
  Character,
} from '../../../battleAssistant/BattleAssistant';
import { useBattleAssistant } from '../BattleAssistantContext';
import CharactersMenuListItem from './CharactersMenuListItem';
import Container from '../../draggable/Container';
import Draggable from '../../draggable/Draggable';

function CharactersMenu() {
  const {
    characters,
    localPlayer,
    selectedCharacter,
    setIsCharacterFormDialogOpen,
    setSelectedCharacter,
    setUpdatedCharacter,
  } = useBattleAssistant();
  const [visibleCharacters, setVisibleCharacters] = useState<Character[] | null>(null);
  const [invisibleCharacters, setInvisibleCharacters] = useState<Character[] | null>(null);

  const handleOpenDialog = (character?: Character) => {
    setSelectedCharacter(character || null);
    setIsCharacterFormDialogOpen(true);
  };

  const dragDropHandler = (isVisibleCharacter: boolean, { removedIndex, addedIndex }: DropResult) => {
    if (addedIndex == null || removedIndex == null || addedIndex === removedIndex) { return; }

    const characters = isVisibleCharacter ? visibleCharacters : invisibleCharacters;
    const minIndex = Math.min(addedIndex, removedIndex);
    const maxIndex = Math.max(addedIndex, removedIndex);

    (characters || []).forEach((character, index) => {
      if (index < minIndex || index > maxIndex) { return; }

      character.position = removedIndex === index
        ? addedIndex // movedCharacter
        : addedIndex > removedIndex ? index - 1 : index + 1;
      if (!isVisibleCharacter) { character.position += visibleCharacters?.length || 0 }
      setUpdatedCharacter({ ...character })
    });
  };

  useEffect(() => {
    const charactersList = Object.values(characters)
      .sort((a, b) => (a.position || 0) - (b.position || 0));
    const visibleCharacters = charactersList.filter(c => !c.hidden);
    const invisibleCharacters = charactersList.filter(c => c.hidden);
    setVisibleCharacters(visibleCharacters.length ? visibleCharacters : null)
    setInvisibleCharacters(invisibleCharacters.length ? invisibleCharacters : null)
  }, [characters])

  return (
    <Box
      sx={{
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'column',
        width: '240px',
        position: 'relative',
      }}
    >
      <Box
        sx={{ display: 'flex', alignItems: 'center', pb: 1, pl: 2, pr: 2, pt: 1 }}
      >
        <Typography variant="h6" gutterBottom sx={{ flexGrow: 1, margin: 0 }}>
          Characters
        </Typography>

        <IconButton
          aria-label="Add character"
          onClick={() => handleOpenDialog()}
          size="small"
        >
          <AddCircleIcon />
        </IconButton>
      </Box>

      <Divider />

      {Object.values(characters).length === 0 ? (
        <Box p={2} flexGrow={1} overflow="auto">
          <Typography>No characters yet</Typography>
        </Box>
      ) : (
        <Box p={0} flexGrow={1} overflow="auto">
          {visibleCharacters && (
            <List>
              <Container
                dragHandleSelector=".drag-handle"
                lockAxis="y"
                onDrop={event => dragDropHandler(true, event)}
              >
                {visibleCharacters && visibleCharacters.map((character) => (
                  <Draggable key={character.id}>
                    <CharactersMenuListItem
                      character={character}
                      isSelected={character.id === selectedCharacter?.id}
                      onCharacterSelect={setSelectedCharacter}
                    />
                  </Draggable>
                ))}
              </Container>
            </List>
          )}

          {/* <WithFeatureAccess feature={Feature.InvisibleObject} action='view' object={selectedCharacter}> */}
          {localPlayer?.isGameMaster && invisibleCharacters && (
            <List
              subheader={
                <ListSubheader sx={{ backgroundColor: 'transparent' }}>
                  Invisible characters
                </ListSubheader>
              }
            >
              <Container
                dragHandleSelector=".drag-handle"
                lockAxis="y"
                onDrop={event => dragDropHandler(false, event)}
              >
                {invisibleCharacters.map((character) => (
                  <Draggable key={character.id}>
                    <CharactersMenuListItem
                      key={character.id}
                      character={character}
                      isSelected={character.id === selectedCharacter?.id}
                      onCharacterSelect={setSelectedCharacter}
                    />
                  </Draggable>
                ))}
              </Container>
            </List>
          )}
          {/* </WithFeatureAccess> */}
        </Box>
      )}
    </Box>
  );
}

export default CharactersMenu;
